import {gql} from 'apollo-angular'
import {Injectable} from '@angular/core'
import * as Apollo from 'apollo-angular'

export type Maybe<T> = T | undefined
export type InputMaybe<T> = T | undefined
export type Exact<T extends Record<string, unknown>> = {[K in keyof T]: T[K]}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>}
export type MakeEmpty<T extends Record<string, unknown>, K extends keyof T> = {[_ in K]?: never}
export type Incremental<T> = T | {[P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string}
  String: {input: string; output: string}
  Boolean: {input: boolean; output: boolean}
  Int: {input: number; output: number}
  Float: {input: number; output: number}
  /** A date and time, represented as an ISO-8601 string */
  DateTime: {input: any; output: any}
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: {input: any; output: any}
}

export type AcceptedJobDetail = {
  __typename?: 'AcceptedJobDetail'
  additionalInformation?: Maybe<Scalars['String']['output']>
  billingAddress: AcceptedJobDetailAddress
  billingClient: AssignedJobDetailClient
  client: AssignedJobDetailClient
  clientExpectation?: Maybe<ClientExpectation>
  damage?: Maybe<ProDamage>
  damageAgentExpectation?: Maybe<AgentExpectation>
  damageId?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  executionAddress?: Maybe<AcceptedJobDetailAddress>
  executionDate: AssignedJobDetailExecutionDate
  fileKeys?: Maybe<Scalars['String']['output'][]>
  files?: Maybe<File[]>
  jobId: Scalars['String']['output']
  /** Offer requirements destined to the pro */
  offerRequirements: OfferRequirements
  offering?: Maybe<JobOffering>
  submittedAt: Scalars['DateTime']['output']
  title?: Maybe<Scalars['String']['output']>
}

export type AcceptedJobDetailAddress = {
  __typename?: 'AcceptedJobDetailAddress'
  floor?: Maybe<Scalars['String']['output']>
  locality: Scalars['String']['output']
  ownership?: Maybe<Ownership>
  postalCode: Scalars['Int']['output']
  streetAndHouseNumber: Scalars['String']['output']
}

export type AcceptedOffer = {
  __typename?: 'AcceptedOffer'
  clientServiceFeeEnabled: Scalars['Boolean']['output']
  contactPerson?: Maybe<PartialContactPerson>
  cost?: Maybe<AcceptedOfferCost>
  costUpperLimit?: Maybe<AcceptedOfferCost>
  createdAt: Scalars['DateTime']['output']
  damageCoverage?: Maybe<DamageCoverage>
  files: File[]
  hourlyCost?: Maybe<HourlyCost>
  id: Scalars['String']['output']
  jobId: Scalars['String']['output']
  messageToClient?: Maybe<Scalars['String']['output']>
  modifiedAt: Scalars['DateTime']['output']
  proId: Scalars['String']['output']
  proServiceFeeEnabled: Scalars['Boolean']['output']
  publishedAt: Scalars['DateTime']['output']
  status: OfferStatus
  title?: Maybe<Scalars['String']['output']>
  type: OfferType
}

export type AcceptedOfferCost = {
  __typename?: 'AcceptedOfferCost'
  /** Fee paid by the client (CHF), VAT excluded */
  clientFee: Scalars['Float']['output']
  clientServiceFeeEnabled: Scalars['Boolean']['output']
  clientServiceFeeVatRate: Scalars['Float']['output']
  /** Total of the offer, from the client's perspective, including cost, VAT and fee (CHF) */
  clientTotal: Scalars['Float']['output']
  /** VAT on the client bill (CHF) for the cost of the work and fee */
  clientVat: Scalars['Float']['output']
  /** Cost of the work, excluding VAT and fee */
  cost: Scalars['Float']['output']
  proServiceFeeEnabled: Scalars['Boolean']['output']
  proServiceFeeVatRate: Scalars['Float']['output']
  /** VAT on the cost of the work */
  vat: Scalars['Float']['output']
  vatRate: Scalars['Float']['output']
}

export type AccountingMutations = {
  __typename?: 'AccountingMutations'
  _empty?: Maybe<Scalars['String']['output']>
  createManualEntry: ManualAccountingEntry
  deleteManualEntry: DeleteManualAccountingEntryResult
  /** Exports the accounting report for a given time range */
  exportAccountingReport: AccountingReportFile
}

export type AccountingMutationsCreateManualEntryArgs = {
  accounts: AccountsInput
  comment: Scalars['String']['input']
  creatorEmail: Scalars['String']['input']
}

export type AccountingMutationsDeleteManualEntryArgs = {
  id: Scalars['ID']['input']
}

export type AccountingMutationsExportAccountingReportArgs = {
  from?: InputMaybe<Scalars['DateTime']['input']>
  isDetailed?: InputMaybe<Scalars['Boolean']['input']>
  snapshotDate: Scalars['DateTime']['input']
}

export type AccountingReport = {
  __typename?: 'AccountingReport'
  creditors: Scalars['Int']['output']
  debtorLosses: Scalars['Int']['output']
  debtors: Scalars['Int']['output']
  delCredere: Scalars['Int']['output']
  discount: Scalars['Int']['output']
  earnings: Scalars['Int']['output']
  goodwill: Scalars['Int']['output']
}

export type AccountingReportFile = {
  __typename?: 'AccountingReportFile'
  url: Scalars['String']['output']
}

export type AccountsInput = {
  creditors: Scalars['Int']['input']
  debtorLosses: Scalars['Int']['input']
  debtors: Scalars['Int']['input']
  delCredere: Scalars['Int']['input']
  discount: Scalars['Int']['input']
  earnings: Scalars['Int']['input']
  goodwill: Scalars['Int']['input']
}

export enum ActionsNeeded {
  AdditionalOfferRequested = 'AdditionalOfferRequested',
  AllOffersDeclinedByClient = 'AllOffersDeclinedByClient',
  AllProsDeclined = 'AllProsDeclined',
  NewInvoices = 'NewInvoices',
  NoOfferPublished = 'NoOfferPublished',
  NoProAccepted = 'NoProAccepted',
  NoProAssigned = 'NoProAssigned',
  NoReactionFromClient = 'NoReactionFromClient',
  NoReactionFromPro = 'NoReactionFromPro',
  ProStillWaiting = 'ProStillWaiting',
  ToCancel = 'ToCancel',
  TriggeredReminders = 'TriggeredReminders',
}

export type AddCommentResult = {
  __typename?: 'AddCommentResult'
  event: AuditEvent
}

export type AdditionalOfferRequest = {
  __typename?: 'AdditionalOfferRequest'
  requestedAt: Scalars['DateTime']['output']
  requestedBy: AdditionalOfferRequestedBy
  status: AdditionalOfferRequestStatus
}

export enum AdditionalOfferRequestStatus {
  Completed = 'COMPLETED',
  Pending = 'PENDING',
}

export enum AdditionalOfferRequestedBy {
  Client = 'CLIENT',
}

export type AdditionalOffers = {
  __typename?: 'AdditionalOffers'
  canRequestAdditionalOffers: Scalars['Boolean']['output']
  requests: AdditionalOfferRequest[]
}

export type Address = {
  floor?: Maybe<Scalars['String']['output']>
  locality: Scalars['String']['output']
  postalCode: Scalars['Int']['output']
  streetAndHouseNumber: Scalars['String']['output']
}

export type AddressInput = {
  floor?: InputMaybe<Scalars['String']['input']>
  locality: Scalars['String']['input']
  postalCode: Scalars['Int']['input']
  streetAndHouseNumber: Scalars['String']['input']
}

export type AddressWithOwnership = Address & {
  __typename?: 'AddressWithOwnership'
  floor?: Maybe<Scalars['String']['output']>
  locality: Scalars['String']['output']
  ownership?: Maybe<Ownership>
  postalCode: Scalars['Int']['output']
  streetAndHouseNumber: Scalars['String']['output']
}

export type AddressWithOwnershipInput = {
  floor?: InputMaybe<Scalars['String']['input']>
  locality: Scalars['String']['input']
  ownership?: InputMaybe<Ownership>
  postalCode: Scalars['Int']['input']
  streetAndHouseNumber: Scalars['String']['input']
}

export type AdminCancelJobResult = AdminJob | CancelJobError

export type AdminChosenPro = {
  __typename?: 'AdminChosenPro'
  id: Scalars['ID']['output']
  invoice?: Maybe<AdminInvoice>
  jobId: Scalars['ID']['output']
}

export type AdminClientContact = {
  __typename?: 'AdminClientContact'
  clientId?: Maybe<Scalars['ID']['output']>
  contact: ContactPerson
}

export type AdminContact = AdminClientContact | AdminProContact

export type AdminContactSearchResult = {
  __typename?: 'AdminContactSearchResult'
  items: AdminContact[]
  total: Scalars['Int']['output']
}

export enum AdminContactSearchType {
  Phone = 'PHONE',
}

/** AdminInvoice should at some point have the same fields as Invoice, maybe see the Payout interface for inspiration. */
export type AdminInvoice = HasPayout & {
  __typename?: 'AdminInvoice'
  bexioInvoice?: Maybe<BexioInvoice>
  bexioInvoiceId?: Maybe<Scalars['Int']['output']>
  clientPaymentReminder?: Maybe<File>
  clientPaymentReminderFileKey?: Maybe<Scalars['String']['output']>
  externalFeesCollectedAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  payback?: Maybe<Payback>
  paymentSystem: PaymentSystem
  payout?: Maybe<AdminPayout>
  payoutId?: Maybe<Scalars['ID']['output']>
}

export type AdminJob = {
  __typename?: 'AdminJob'
  /** Actions that are possible on 'job' with its current state */
  actions: AdminJobAction[]
  auditEvents: AuditEvent[]
  bookmark?: Maybe<JobBookmark>
  /**
   * The pro that has been chosen for this job. This field mirrors the pro field on the job type.
   * In the future we should have separate job types for every domain, maybe with a/multiple common interface/s.
   */
  chosenPro?: Maybe<AdminChosenPro>
  id: Scalars['String']['output']
  /** @deprecated See chosenPro field, when adding admin specific fields that could also be nested somewhere on Job, try replicating the structure on the current type */
  job: Job
  /** Includes any additional jobs for this client. */
  otherJobs: Job[]
  recruitingAgents: Agent[]
  reminders: AdminReminder[]
  stakeholderAgency?: Maybe<Agency>
}

export type AdminJobAuditEventsArgs = {
  excludeEventTypes?: InputMaybe<InputMaybe<AuditEventType>[]>
}

export type AdminJobOtherJobsArgs = {
  filter?: InputMaybe<OtherJobsFilter>
}

export enum AdminJobAction {
  AssignPros = 'ASSIGN_PROS',
  CancelJob = 'CANCEL_JOB',
  CompleteJob = 'COMPLETE_JOB',
  MarkAsNotReacted = 'MARK_AS_NOT_REACTED',
  ReopenJob = 'REOPEN_JOB',
  ReviseInvoice = 'REVISE_INVOICE',
}

export type AdminJobCommentAuditData = {
  __typename?: 'AdminJobCommentAuditData'
  admin: AuditUser
  comment: Scalars['String']['output']
  job: AuditJob
}

export type AdminJobCountByDay = {
  __typename?: 'AdminJobCountByDay'
  count: Scalars['Int']['output']
  damageJobCount: Scalars['Int']['output']
  day: Scalars['DateTime']['output']
}

export type AdminJobCounts = {
  __typename?: 'AdminJobCounts'
  damageJobsThisYear: Scalars['Int']['output']
  damageJobsToday: Scalars['Int']['output']
  previousXDays: AdminJobCountByDay[]
  thisYear: Scalars['Int']['output']
  today: Scalars['Int']['output']
}

export type AdminJobMetrics = {
  __typename?: 'AdminJobMetrics'
  additionalOfferRequested: Scalars['Int']['output']
  allOffersDeclinedByClient: Scalars['Int']['output']
  allProsDeclined: Scalars['Int']['output']
  newInvoices: Scalars['Int']['output']
  noOfferPublished: Scalars['Int']['output']
  noProAccepted: Scalars['Int']['output']
  noProAssigned: Scalars['Int']['output']
  noReactionFromClient: Scalars['Int']['output']
  noReactionFromPro: Scalars['Int']['output']
  proStillWaiting: Scalars['Int']['output']
  toCancel: Scalars['Int']['output']
  triggeredReminders: Scalars['Int']['output']
}

export type AdminJobUpdateInput = {
  address: AddressInput
  client: ClientInput
  id: Scalars['String']['input']
}

export type AdminJobUpdateInputError = Error & {
  __typename?: 'AdminJobUpdateInputError'
  error: UpdateJobError
  message: Scalars['String']['output']
}

export type AdminJobUpdateResult = AdminJobUpdateInputError | Job

export type AdminPayout = Payout & {
  __typename?: 'AdminPayout'
  actions: PayoutActions[]
  amount: Scalars['Int']['output']
  bexioBill?: Maybe<BexioBill>
  bexioBillId?: Maybe<Scalars['String']['output']>
  dueDate: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  status: PayoutStatus
}

export type AdminPro = {
  __typename?: 'AdminPro'
  auditEvents: AuditEvent[]
  jobsInvolved: ProJobInvolvment[]
  pro: Pro
  profile: ProProfile
  stats: AdminProStats
}

export type AdminProAuditEventsArgs = {
  excludeEventTypes?: InputMaybe<InputMaybe<AuditEventType>[]>
}

export type AdminProCommentAuditData = {
  __typename?: 'AdminProCommentAuditData'
  comment: Scalars['String']['output']
}

export type AdminProContact = {
  __typename?: 'AdminProContact'
  contact: ContactPerson
  proId: Scalars['ID']['output']
}

export type AdminProStats = {
  __typename?: 'AdminProStats'
  numberOfJobsAccepted: Scalars['Int']['output']
  numberOfJobsAssigned: Scalars['Int']['output']
  numberOfJobsDeclined: Scalars['Int']['output']
  numberOfJobsWon: Scalars['Int']['output']
  proId: Scalars['String']['output']
}

export enum AdminProUpdateError {
  AccountClosureBlocked = 'ACCOUNT_CLOSURE_BLOCKED',
  AccountReopeningBlocked = 'ACCOUNT_REOPENING_BLOCKED',
}

export type AdminProUpdateInputError = Error & {
  __typename?: 'AdminProUpdateInputError'
  error: AdminProUpdateError
  message: Scalars['String']['output']
}

export type AdminProUpdateRequest = {
  proId: Scalars['ID']['input']
  updateAction: ProUpdateAction
}

export type AdminProUpdateResult = AdminProUpdateInputError | Pro

export type AdminReminder = {
  __typename?: 'AdminReminder'
  id: Scalars['String']['output']
  jobId: Scalars['ID']['output']
  note: Scalars['String']['output']
  scheduledAt: Scalars['DateTime']['output']
  triggered?: Maybe<Scalars['Boolean']['output']>
}

export type AdminReminderDeletedAuditData = {
  __typename?: 'AdminReminderDeletedAuditData'
  reminder: AdminReminder
}

export type AdminReminderScheduledAuditData = {
  __typename?: 'AdminReminderScheduledAuditData'
  reminder: AdminReminder
}

export type AdminReminderTriggeredAuditData = {
  __typename?: 'AdminReminderTriggeredAuditData'
  reminder: AdminReminder
}

export type AdminUserFilter = {
  searchText?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<UserStatus[]>
  type?: InputMaybe<UserTypeFilter>
}

export type AdminUserSearchResult = {
  __typename?: 'AdminUserSearchResult'
  data: User[]
  total: Scalars['Int']['output']
}

export type AdminUserType = {
  __typename?: 'AdminUserType'
  type: AdminUserTypeDiscriminator
}

export enum AdminUserTypeDiscriminator {
  Admin = 'ADMIN',
}

export type Agency = {
  __typename?: 'Agency'
  agents?: Maybe<Maybe<Agent>[]>
  id: Scalars['ID']['output']
  jobRequestCode?: Maybe<Scalars['String']['output']>
  logo?: Maybe<ImageAsset>
  market: AgencyMarket
  name: Scalars['String']['output']
  slug: Scalars['String']['output']
}

export type AgencyLogoArgs = {
  format?: InputMaybe<ImageFormat>
}

export type AgencyByInviteCode = {
  __typename?: 'AgencyByInviteCode'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type AgencyByJobRequestCode = {
  __typename?: 'AgencyByJobRequestCode'
  id: Scalars['ID']['output']
  jobRequestCode?: Maybe<Scalars['String']['output']>
  logo?: Maybe<ImageAsset>
  name: Scalars['String']['output']
  slug: Scalars['String']['output']
}

export type AgencyByJobRequestCodeLogoArgs = {
  format?: InputMaybe<ImageFormat>
}

export type AgencyBySlug = {
  __typename?: 'AgencyBySlug'
  id: Scalars['ID']['output']
  jobRequestCode?: Maybe<Scalars['String']['output']>
  logo?: Maybe<ImageAsset>
  name: Scalars['String']['output']
  slug: Scalars['String']['output']
}

export type AgencyBySlugLogoArgs = {
  format?: InputMaybe<ImageFormat>
}

export enum AgencyMarket {
  M = 'M',
  O = 'O',
  W = 'W',
}

export type Agent = {
  __typename?: 'Agent'
  address?: Maybe<AgentAddress>
  agency?: Maybe<Agency>
  agencyId?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  invitationCode: Scalars['String']['output']
  jobRequestCode?: Maybe<Scalars['String']['output']>
  language?: Maybe<Scalars['String']['output']>
  lastName: Scalars['String']['output']
  mobiliarId?: Maybe<Scalars['String']['output']>
  mobiliarRole?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['String']['output']>
  salutation: Salutation
}

export type AgentAddress = {
  __typename?: 'AgentAddress'
  locality?: Maybe<Scalars['String']['output']>
  postalCode?: Maybe<Scalars['Int']['output']>
  streetAndHouseNumber?: Maybe<Scalars['String']['output']>
}

export type AgentByInvitationCode = {
  __typename?: 'AgentByInvitationCode'
  agency?: Maybe<AgencyByInviteCode>
  agencyId?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  invitationCode: Scalars['String']['output']
  lastName: Scalars['String']['output']
  phoneNumber?: Maybe<Scalars['String']['output']>
}

export type AgentByJobRequestCode = {
  __typename?: 'AgentByJobRequestCode'
  agency?: Maybe<AgencyByJobRequestCode>
  agencyId?: Maybe<Scalars['String']['output']>
  email?: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  jobRequestCode?: Maybe<Scalars['String']['output']>
  lastName: Scalars['String']['output']
  phoneNumber?: Maybe<Scalars['String']['output']>
}

export type AgentExpectation = {
  __typename?: 'AgentExpectation'
  /** When offerValidationType is AUTO_DAMAGE_COVERED, coverage is the amount that Mobiliar will pay automatically. */
  coverage?: Maybe<Scalars['Int']['output']>
  /**
   * When offerValidationType is AUTO_CLIENT_DEDUCTIBLE, a deductible can be specified for the client to pay and
   * Mobi will pay the rest. If deductible is not specified, it defaults to 200.
   */
  deductible?: Maybe<Scalars['Int']['output']>
  /** Directives from the insurance agent to pro */
  directives?: Maybe<Scalars['String']['output']>
  offerValidationType: AgentExpectationOfferValidationType
}

export type AgentExpectationInput = {
  /** When offerValidationType is AUTO_DAMAGE_COVERED, coverage is the amount that Mobiliar will pay automatically. */
  coverage?: InputMaybe<Scalars['Int']['input']>
  /**
   * When offerValidationType is AUTO_CLIENT_DEDUCTIBLE, a deductible can be specified for the client to pay and
   * Mobi will pay the rest. If deductible is not specified, it defaults to 200.
   */
  deductible?: InputMaybe<Scalars['Int']['input']>
  /** Directives from the insurance agent to pro */
  directives?: InputMaybe<Scalars['String']['input']>
  offerValidationType: AgentExpectationOfferValidationType
}

export enum AgentExpectationOfferValidationType {
  AutoClientDeductible = 'AUTO_CLIENT_DEDUCTIBLE',
  AutoDamageCovered = 'AUTO_DAMAGE_COVERED',
  Manual = 'MANUAL',
}

export type AgentJobsFilter = {
  jobStatuses: JobStatus[]
  offerStatuses: OfferStatus[]
}

export type AgentJobsResult = {
  __typename?: 'AgentJobsResult'
  agentId: Scalars['ID']['output']
  data: Job[]
  filterLevel: FilterLevel
  total: Scalars['Int']['output']
}

export type AgentJobsResultDataArgs = {
  filter?: InputMaybe<AgentJobsFilter>
}

export type AgentJobsResultTotalArgs = {
  filter?: InputMaybe<AgentJobsFilter>
}

export type AgentPro = {
  __typename?: 'AgentPro'
  assignedJobs: AgentProAssignedJobPage
  companyName: Scalars['String']['output']
  id: Scalars['ID']['output']
}

export type AgentProAssignedJobsArgs = {
  filter: AgentProJobsFilter
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type AgentProAssignedJob = {
  __typename?: 'AgentProAssignedJob'
  jobDetail: AgentProAssignedJobDetail
  proId: Scalars['ID']['output']
  status: AssignmentStatus
}

export type AgentProAssignedJobDetail = {
  __typename?: 'AgentProAssignedJobDetail'
  damage?: Maybe<Damage>
  damageId?: Maybe<Scalars['String']['output']>
  executionAddress?: Maybe<PartialAddress>
  id: Scalars['ID']['output']
  offering?: Maybe<JobOffering>
  submittedAt: Scalars['DateTime']['output']
  title?: Maybe<Scalars['String']['output']>
}

export type AgentProAssignedJobPage = {
  __typename?: 'AgentProAssignedJobPage'
  data: AgentProAssignedJob[]
  total: Scalars['Int']['output']
}

export type AgentProInvitesResult = {
  __typename?: 'AgentProInvitesResult'
  agents: Agent[]
  invites: Invite[]
  total: Scalars['Int']['output']
}

export type AgentProJobsFilter = {
  assignmentStatuses: AssignmentStatus[]
  /** Only return jobs that have a related damage or only jobs that aren't. undefined will return both (default). */
  isDamageJob?: InputMaybe<Scalars['Boolean']['input']>
  /**
   * Based on the job count metric used in the Agency dashboard.
   *
   * Essentially a count of all assignments that have passed a certain state in the past.
   *
   * Sending undefined will return all jobs, which is equivalent to the ASSIGNED metric.
   */
  metric?: InputMaybe<AgentProMetric>
  searchText?: InputMaybe<Scalars['String']['input']>
  year?: InputMaybe<Scalars['Int']['input']>
}

export enum AgentProMetric {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Won = 'WON',
}

export type AgentUserType = {
  __typename?: 'AgentUserType'
  agentId: Scalars['ID']['output']
  type: AgentUserTypeDiscriminator
}

export enum AgentUserTypeDiscriminator {
  Agent = 'AGENT',
}

export type AnalyticParams = {
  __typename?: 'AnalyticParams'
  name: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type AnotherProChosenAuditData = {
  __typename?: 'AnotherProChosenAuditData'
  assignment: AuditAssignment
  job: AuditJob
  pro: AuditPro
}

export type Answer = {
  __typename?: 'Answer'
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  rank: Scalars['Int']['output']
  requireAdditionalInformation: Scalars['Boolean']['output']
  status: AnswerStatus
}

export type AnswerFilter = {
  status?: InputMaybe<AnswerStatus>
}

export type AnswerOrder = {
  rank?: InputMaybe<Ordering>
}

export enum AnswerStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

export enum ArchiveOfferError {
  AnotherProChosen = 'ANOTHER_PRO_CHOSEN',
  JobCanceled = 'JOB_CANCELED',
  OfferStatus = 'OFFER_STATUS',
}

export type ArchiveOfferInputError = Error & {
  __typename?: 'ArchiveOfferInputError'
  error?: Maybe<ArchiveOfferError>
  message: Scalars['String']['output']
}

export type ArchiveOfferResult = ArchiveOfferInputError | Offer

export type ArticleAuthor = {
  __typename?: 'ArticleAuthor'
  name: Scalars['String']['output']
  portrait?: Maybe<ArticleAuthorPortrait>
}

export type ArticleAuthorPortrait = {
  __typename?: 'ArticleAuthorPortrait'
  title?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
}

export type ArticleSearchItem = {
  __typename?: 'ArticleSearchItem'
  author: ArticleAuthor[]
  bannerImage?: Maybe<SearchAsset>
  preview?: Maybe<Scalars['String']['output']>
  readingTime?: Maybe<Scalars['Int']['output']>
  slug: Scalars['String']['output']
  title: Scalars['String']['output']
  topic?: Maybe<ArticleSearchItemTopic>
}

export type ArticleSearchItemTopic = {
  __typename?: 'ArticleSearchItemTopic'
  breadcrumbTitle?: Maybe<Scalars['String']['output']>
  slug: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type ArticleSearchResult = {
  __typename?: 'ArticleSearchResult'
  items: ArticleSearchItem[]
  total: Scalars['Int']['output']
}

export type AssignProsRequest = {
  jobId: Scalars['String']['input']
  proLocationIds: Scalars['String']['input'][]
}

export type AssignedAcceptedJob = {
  __typename?: 'AssignedAcceptedJob'
  allowedActions: AssignmentAction[]
  assignedAt: Scalars['DateTime']['output']
  auditEvents: ProAuditEvent[]
  driveTimeToClientInMinutes?: Maybe<Scalars['Int']['output']>
  execution?: Maybe<ScheduledExecution>
  invoice?: Maybe<Invoice>
  jobDetail: AcceptedJobDetail
  offers?: Maybe<Maybe<Offer>[]>
  proId: Scalars['String']['output']
  siteVisit?: Maybe<SiteVisit>
  status: AssignmentStatus
}

export type AssignedAcceptedJobAuditEventsArgs = {
  order?: InputMaybe<Ordering>
}

export type AssignedAcceptedJobInvoiceArgs = {
  perspective?: InputMaybe<InvoicePerspective>
}

export type AssignedJob = AssignedAcceptedJob | AssignedNewJob | GetAssignedJobError

export type AssignedJobDetailClient = {
  __typename?: 'AssignedJobDetailClient'
  company?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  phoneNumber: Scalars['String']['output']
  salutation: Salutation
}

export type AssignedJobDetailExecutionDate = {
  __typename?: 'AssignedJobDetailExecutionDate'
  expectedExecution: ExpectedExecution
  latestPossibleDate?: Maybe<Scalars['DateTime']['output']>
}

export type AssignedJobInput = {
  jobId: Scalars['String']['input']
  proId: Scalars['String']['input']
}

export type AssignedJobSearchResultItem = AssignedAcceptedJob | AssignedNewJob

export type AssignedJobsSearchCriteria = {
  searchText?: InputMaybe<Scalars['String']['input']>
  statuses: AssignmentStatus[]
}

export type AssignedJobsSearchResult = {
  __typename?: 'AssignedJobsSearchResult'
  data: AssignedJobSearchResultItem[]
  proId: Scalars['ID']['output']
  total: Scalars['Int']['output']
}

export type AssignedJobsSearchResultDataArgs = {
  criteria: AssignedJobsSearchCriteria
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type AssignedJobsSearchResultTotalArgs = {
  criteria: AssignedJobsSearchCriteria
}

export type AssignedNewJob = {
  __typename?: 'AssignedNewJob'
  allowedActions: AssignmentAction[]
  assignedAt: Scalars['DateTime']['output']
  driveTimeToClientInMinutes?: Maybe<Scalars['Int']['output']>
  jobDetail: NewJobDetail
  proId: Scalars['String']['output']
  status: AssignmentStatus
}

export type AssignedPro = {
  __typename?: 'AssignedPro'
  address: BaseAddress
  areRemindersMuted: Scalars['Boolean']['output']
  assignment: Assignment
  companyName: Scalars['String']['output']
  companyPhoneNumber?: Maybe<Scalars['String']['output']>
  /** Company representative */
  contactPerson: ContactPerson
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  proLocationId: Scalars['String']['output']
  publishedOffer?: Maybe<Offer>
  slug: Scalars['String']['output']
  website?: Maybe<Scalars['String']['output']>
}

export type AssignedProPublishedOfferArgs = {
  includeInValidation?: InputMaybe<Scalars['Boolean']['input']>
}

export type AssignedProMetrics = {
  __typename?: 'AssignedProMetrics'
  numAssignedPros: Scalars['Int']['output']
  numProsThatDeclined: Scalars['Int']['output']
}

export type Assignment = {
  __typename?: 'Assignment'
  allOccurredStatuses: AssignmentStatus[]
  assignedAt: Scalars['DateTime']['output']
  declineReason?: Maybe<AssignmentDeclineReason>
  declineReasonText?: Maybe<Scalars['String']['output']>
  driveTimeToClientInMinutes?: Maybe<Scalars['Int']['output']>
  execution?: Maybe<ScheduledExecution>
  jobId: Scalars['ID']['output']
  muteReminders: Scalars['Boolean']['output']
  offerAcceptedDate?: Maybe<Scalars['DateTime']['output']>
  overwriteGlobalReminderMute: Scalars['Boolean']['output']
  possibleActions?: Maybe<Maybe<AssignmentAction>[]>
  proId: Scalars['ID']['output']
  siteVisit?: Maybe<SiteVisit>
  status: AssignmentStatus
}

export enum AssignmentAction {
  AcceptJob = 'ACCEPT_JOB',
  AcceptOffer = 'ACCEPT_OFFER',
  ArchiveOffer = 'ARCHIVE_OFFER',
  CancelJob = 'CANCEL_JOB',
  CompleteJob = 'COMPLETE_JOB',
  DeclineJob = 'DECLINE_JOB',
  DeclineJobAfterAccepting = 'DECLINE_JOB_AFTER_ACCEPTING',
  DismissNewCanceled = 'DISMISS_NEW_CANCELED',
  DismissNotReacted = 'DISMISS_NOT_REACTED',
  Invoice = 'INVOICE',
  MarkAsNotReacted = 'MARK_AS_NOT_REACTED',
  PublishOffer = 'PUBLISH_OFFER',
  RollbackAnotherProChosenToAccepted = 'ROLLBACK_ANOTHER_PRO_CHOSEN_TO_ACCEPTED',
  RollbackAnotherProChosenToFirstContactEstablished = 'ROLLBACK_ANOTHER_PRO_CHOSEN_TO_FIRST_CONTACT_ESTABLISHED',
  RollbackAnotherProChosenToFirstContactUnresponsive = 'ROLLBACK_ANOTHER_PRO_CHOSEN_TO_FIRST_CONTACT_UNRESPONSIVE',
  RollbackAnotherProChosenToNew = 'ROLLBACK_ANOTHER_PRO_CHOSEN_TO_NEW',
  RollbackAnotherProChosenToOfferPublished = 'ROLLBACK_ANOTHER_PRO_CHOSEN_TO_OFFER_PUBLISHED',
  RollbackAnotherProChosenToSiteVisitNotNeeded = 'ROLLBACK_ANOTHER_PRO_CHOSEN_TO_SITE_VISIT_NOT_NEEDED',
  RollbackAnotherProChosenToSiteVisitScheduled = 'ROLLBACK_ANOTHER_PRO_CHOSEN_TO_SITE_VISIT_SCHEDULED',
  RollbackAnotherProChosenToSiteVisitWaiting = 'ROLLBACK_ANOTHER_PRO_CHOSEN_TO_SITE_VISIT_WAITING',
  RollbackOfferAcceptedToOfferPublished = 'ROLLBACK_OFFER_ACCEPTED_TO_OFFER_PUBLISHED',
  RollbackProFromAcceptedToDeclined = 'ROLLBACK_PRO_FROM_ACCEPTED_TO_DECLINED',
  RollbackProToNew = 'ROLLBACK_PRO_TO_NEW',
  ScheduleExecution = 'SCHEDULE_EXECUTION',
  ScheduleSiteVisit = 'SCHEDULE_SITE_VISIT',
  SelectAnotherPro = 'SELECT_ANOTHER_PRO',
  SendNewAssignmentReminder = 'SEND_NEW_ASSIGNMENT_REMINDER',
  SetFirstContactEstablished = 'SET_FIRST_CONTACT_ESTABLISHED',
  SetFirstContactUnresponsive = 'SET_FIRST_CONTACT_UNRESPONSIVE',
  SetOfferAcceptedOffline = 'SET_OFFER_ACCEPTED_OFFLINE',
  SetSiteVisitNotNeeded = 'SET_SITE_VISIT_NOT_NEEDED',
  WaitForMoreInfoFromClient = 'WAIT_FOR_MORE_INFO_FROM_CLIENT',
}

export enum AssignmentDeclineReason {
  AccountClosure = 'ACCOUNT_CLOSURE',
  DeadlineTooSoon = 'DEADLINE_TOO_SOON',
  NotOfferingService = 'NOT_OFFERING_SERVICE',
  NoResources = 'NO_RESOURCES',
  Other = 'OTHER',
}

export enum AssignmentStatus {
  Accepted = 'ACCEPTED',
  AnotherProChosen = 'ANOTHER_PRO_CHOSEN',
  Declined = 'DECLINED',
  ExecutionScheduled = 'EXECUTION_SCHEDULED',
  FirstContactEstablished = 'FIRST_CONTACT_ESTABLISHED',
  FirstContactUnresponsive = 'FIRST_CONTACT_UNRESPONSIVE',
  Invoiced = 'INVOICED',
  JobCanceled = 'JOB_CANCELED',
  JobCompleted = 'JOB_COMPLETED',
  New = 'NEW',
  NewCanceled = 'NEW_CANCELED',
  NewCanceledDismissible = 'NEW_CANCELED_DISMISSIBLE',
  OfferAccepted = 'OFFER_ACCEPTED',
  OfferAcceptedOffline = 'OFFER_ACCEPTED_OFFLINE',
  OfferPublished = 'OFFER_PUBLISHED',
  ProNotReacted = 'PRO_NOT_REACTED',
  ProNotReactedDismissible = 'PRO_NOT_REACTED_DISMISSIBLE',
  SiteVisitNotNeeded = 'SITE_VISIT_NOT_NEEDED',
  SiteVisitScheduled = 'SITE_VISIT_SCHEDULED',
  SiteVisitWaiting = 'SITE_VISIT_WAITING',
}

export type AssociatedGooglePlace = {
  __typename?: 'AssociatedGooglePlace'
  id: Scalars['String']['output']
  rating?: Maybe<Scalars['Float']['output']>
  userRatingCount?: Maybe<Scalars['Int']['output']>
}

export type AssociatedGooglePlaceInput = {
  id: Scalars['String']['input']
  rating?: InputMaybe<Scalars['Float']['input']>
  userRatingCount?: InputMaybe<Scalars['Int']['input']>
}

export type AuditAgent = {
  __typename?: 'AuditAgent'
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
}

export type AuditAssignment = {
  __typename?: 'AuditAssignment'
  id: Scalars['String']['output']
}

export type AuditContext = {
  __typename?: 'AuditContext'
  language?: Maybe<Scalars['String']['output']>
  user?: Maybe<AuditUser>
}

export type AuditDamage = {
  __typename?: 'AuditDamage'
  caseNumber: Scalars['String']['output']
  id: Scalars['String']['output']
  occurredAt: Scalars['DateTime']['output']
}

export type AuditData =
  | AdminJobCommentAuditData
  | AdminProCommentAuditData
  | AdminReminderDeletedAuditData
  | AdminReminderScheduledAuditData
  | AdminReminderTriggeredAuditData
  | AnotherProChosenAuditData
  | DamageCaseOpenedAuditData
  | DefaultEventData
  | EmailSentAuditData
  | ExecutionDateScheduledAuditData
  | FirstContactEstablishedAuditData
  | FirstContactUnresponsiveAuditData
  | InvoiceAbandonedAuditData
  | InvoiceApprovedAuditData
  | InvoiceCancelledAuditData
  | InvoiceCededAuditData
  | InvoiceCreatedAuditData
  | InvoiceMarkedAsExternalAuditData
  | InvoicePaidAuditData
  | InvoicePartiallyPaidAuditData
  | InvoiceSentAuditData
  | InvoiceSentToClientAuditData
  | InvoiceUpdateReceivedAuditData
  | InvoiceUpdatedAuditData
  | JobAcceptedAuditData
  | JobAssignedToProAuditData
  | JobCancelledAuditData
  | JobDeclinedAuditData
  | JobMetricMutedAuditData
  | JobMetricUnmutedAuditData
  | JobRequestedAuditData
  | MessageSentToAdminFromProAuditData
  | NoReactionFromProAuditData
  | NoReactionFromProDismissedAuditData
  | OfferAcceptedAuditData
  | OfferAcceptedOfflineAuditData
  | OfferArchivedAuditData
  | OfferCanceledAuditData
  | OfferDeclinedAuditData
  | OfferInValidationAuditData
  | OfferPublishedAuditData
  | PaymentReminderMutedAuditData
  | PaymentRemindersUpdatedAuditData
  | PayoutApprovedAuditData
  | PayoutCreatedAuditData
  | PayoutDeletedAuditData
  | PayoutPaidAuditData
  | PayoutPartiallyPaidAuditData
  | PayoutUnapprovedAuditData
  | PayoutUpdateReceivedAuditData
  | ProProfileUpdatedData
  | ProStatusChangedData
  | ProSubscriptionCancelledAuditData
  | ProSubscriptionChangedAuditData
  | ProSubscriptionChangesScheduledAuditData
  | ProSubscriptionCreatedAuditData
  | ProSubscriptionScheduledChangesRemovedAuditData
  | RecruitingAgentAssignedAuditData
  | RollbackDeclinedAuditData
  | RollbackNoReactionAuditData
  | RollbackOfferAcceptedToPublishedAuditData
  | RollbackProFromAcceptedToDeclinedAuditData
  | SiteVisitNotNeededAuditData
  | SiteVisitScheduledAuditData
  | SmsSentAuditData
  | WaitingForClientAuditData

export type AuditEmail = AuditTemplateEmail | AuditTextEmail

export type AuditEvent = {
  __typename?: 'AuditEvent'
  context?: Maybe<AuditContext>
  createdAt: Scalars['DateTime']['output']
  data?: Maybe<AuditData>
  id: Scalars['String']['output']
  type: AuditEventType
}

export enum AuditEventType {
  AdditionalOfferRequested = 'ADDITIONAL_OFFER_REQUESTED',
  AdminJobComment = 'ADMIN_JOB_COMMENT',
  AdminProComment = 'ADMIN_PRO_COMMENT',
  AdminReminderDeleted = 'ADMIN_REMINDER_DELETED',
  AdminReminderScheduled = 'ADMIN_REMINDER_SCHEDULED',
  AdminReminderTriggered = 'ADMIN_REMINDER_TRIGGERED',
  AnotherProChosen = 'ANOTHER_PRO_CHOSEN',
  DamageCaseOpened = 'DAMAGE_CASE_OPENED',
  DefaultEvent = 'DEFAULT_EVENT',
  EmailSent = 'EMAIL_SENT',
  ExecutionDateScheduled = 'EXECUTION_DATE_SCHEDULED',
  FirstContactEstablished = 'FIRST_CONTACT_ESTABLISHED',
  FirstContactUnresponsive = 'FIRST_CONTACT_UNRESPONSIVE',
  InvoiceAbandoned = 'INVOICE_ABANDONED',
  InvoiceApproved = 'INVOICE_APPROVED',
  InvoiceCancelled = 'INVOICE_CANCELLED',
  InvoiceCeded = 'INVOICE_CEDED',
  InvoiceCreated = 'INVOICE_CREATED',
  InvoiceMarkedAsExternal = 'INVOICE_MARKED_AS_EXTERNAL',
  InvoicePaid = 'INVOICE_PAID',
  InvoicePartiallyPaid = 'INVOICE_PARTIALLY_PAID',
  InvoiceSent = 'INVOICE_SENT',
  InvoiceSentToClient = 'INVOICE_SENT_TO_CLIENT',
  InvoiceUpdated = 'INVOICE_UPDATED',
  /** Invoice update received from payment service */
  InvoiceUpdateReceived = 'INVOICE_UPDATE_RECEIVED',
  InvoiceVoucherIssue = 'INVOICE_VOUCHER_ISSUE',
  JobCanceled = 'JOB_CANCELED',
  JobCompleted = 'JOB_COMPLETED',
  JobMarkedAsToCancel = 'JOB_MARKED_AS_TO_CANCEL',
  JobMetricMuted = 'JOB_METRIC_MUTED',
  JobMetricUnmmuteTriggered = 'JOB_METRIC_UNMMUTE_TRIGGERED',
  JobMetricUnmuted = 'JOB_METRIC_UNMUTED',
  JobReopened = 'JOB_REOPENED',
  JobRequested = 'JOB_REQUESTED',
  JobUpdated = 'JOB_UPDATED',
  MissingIbanDetected = 'MISSING_IBAN_DETECTED',
  OfferAccepted = 'OFFER_ACCEPTED',
  OfferAcceptedOffline = 'OFFER_ACCEPTED_OFFLINE',
  OfferArchived = 'OFFER_ARCHIVED',
  OfferCanceled = 'OFFER_CANCELED',
  OfferDeclined = 'OFFER_DECLINED',
  OfferInValidation = 'OFFER_IN_VALIDATION',
  OfferPublished = 'OFFER_PUBLISHED',
  PaymentRemindersUpdated = 'PAYMENT_REMINDERS_UPDATED',
  PaymentReminderMuted = 'PAYMENT_REMINDER_MUTED',
  PayoutApproved = 'PAYOUT_APPROVED',
  PayoutCreated = 'PAYOUT_CREATED',
  PayoutDeleted = 'PAYOUT_DELETED',
  PayoutPaid = 'PAYOUT_PAID',
  PayoutPartiallyPaid = 'PAYOUT_PARTIALLY_PAID',
  PayoutUnapproved = 'PAYOUT_UNAPPROVED',
  /** Payout update received from payment service */
  PayoutUpdateReceived = 'PAYOUT_UPDATE_RECEIVED',
  ProAcceptedJob = 'PRO_ACCEPTED_JOB',
  ProAccountClosureFinished = 'PRO_ACCOUNT_CLOSURE_FINISHED',
  ProAccountClosureRequested = 'PRO_ACCOUNT_CLOSURE_REQUESTED',
  ProAccountReopeningFinished = 'PRO_ACCOUNT_REOPENING_FINISHED',
  ProAccountReopeningRequested = 'PRO_ACCOUNT_REOPENING_REQUESTED',
  ProAssigned = 'PRO_ASSIGNED',
  ProCreatedEvent = 'PRO_CREATED_EVENT',
  ProDeclinedJob = 'PRO_DECLINED_JOB',
  ProFilesUpdatedNotificationTriggered = 'PRO_FILES_UPDATED_NOTIFICATION_TRIGGERED',
  ProMerchantSubmittedEvent = 'PRO_MERCHANT_SUBMITTED_EVENT',
  ProMessageToAdmin = 'PRO_MESSAGE_TO_ADMIN',
  ProNotReacted = 'PRO_NOT_REACTED',
  ProNotReactedDismissed = 'PRO_NOT_REACTED_DISMISSED',
  ProProfileUpdated = 'PRO_PROFILE_UPDATED',
  ProStatusChanged = 'PRO_STATUS_CHANGED',
  ProSubscriptionCancelled = 'PRO_SUBSCRIPTION_CANCELLED',
  ProSubscriptionChanged = 'PRO_SUBSCRIPTION_CHANGED',
  ProSubscriptionChangesScheduled = 'PRO_SUBSCRIPTION_CHANGES_SCHEDULED',
  ProSubscriptionCreated = 'PRO_SUBSCRIPTION_CREATED',
  ProSubscriptionScheduledChangesRemoved = 'PRO_SUBSCRIPTION_SCHEDULED_CHANGES_REMOVED',
  RecruitingAgentAssigned = 'RECRUITING_AGENT_ASSIGNED',
  RollbackDeclined = 'ROLLBACK_DECLINED',
  RollbackNotReacted = 'ROLLBACK_NOT_REACTED',
  RollbackOfferAcceptedToOfferPublished = 'ROLLBACK_OFFER_ACCEPTED_TO_OFFER_PUBLISHED',
  RollbackProFromAcceptedToDeclined = 'ROLLBACK_PRO_FROM_ACCEPTED_TO_DECLINED',
  SiteVisitNotNeeded = 'SITE_VISIT_NOT_NEEDED',
  SiteVisitScheduled = 'SITE_VISIT_SCHEDULED',
  SiteVisitWaitingForClient = 'SITE_VISIT_WAITING_FOR_CLIENT',
  SmsSent = 'SMS_SENT',
}

export type AuditInvoice = {
  __typename?: 'AuditInvoice'
  discount?: Maybe<Scalars['Float']['output']>
  dueDays: Scalars['Int']['output']
  id: Scalars['String']['output']
  invoiceAmount: Scalars['Float']['output']
  message?: Maybe<Scalars['String']['output']>
  /** In rappen */
  totalReceivedPayments?: Maybe<Scalars['Float']['output']>
}

export type AuditJob = {
  __typename?: 'AuditJob'
  id: Scalars['String']['output']
}

export type AuditOffer = {
  __typename?: 'AuditOffer'
  cost?: Maybe<Scalars['Float']['output']>
  costUpperLimit?: Maybe<Scalars['Float']['output']>
  hourlyCost?: Maybe<HourlyCost>
  id: Scalars['String']['output']
  messageToClient?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  type: OfferType
  vat?: Maybe<Scalars['Float']['output']>
}

export type AuditPayout = {
  __typename?: 'AuditPayout'
  amount: Scalars['Float']['output']
  dueDate: Scalars['DateTime']['output']
  externalId?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
}

export type AuditPro = {
  __typename?: 'AuditPro'
  companyName: Scalars['String']['output']
  id: Scalars['String']['output']
}

export type AuditProSubscription = {
  __typename?: 'AuditProSubscription'
  currentTermEndDate?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['String']['output']
  planName: Scalars['String']['output']
}

export type AuditProSubscriptionScheduledChanges = {
  __typename?: 'AuditProSubscriptionScheduledChanges'
  planId: Scalars['ID']['output']
  planName: Scalars['String']['output']
}

export type AuditScheduledDate = {
  __typename?: 'AuditScheduledDate'
  date: Scalars['DateTime']['output']
  fromTime: Scalars['String']['output']
  toTime?: Maybe<Scalars['String']['output']>
}

export type AuditSiteVisit = {
  __typename?: 'AuditSiteVisit'
  date: Scalars['DateTime']['output']
  fromTime: Scalars['String']['output']
  toTime?: Maybe<Scalars['String']['output']>
}

export type AuditSms = {
  __typename?: 'AuditSms'
  destination: Scalars['String']['output']
  message: Scalars['String']['output']
}

export type AuditTemplateEmail = {
  __typename?: 'AuditTemplateEmail'
  data: Scalars['JSON']['output']
  destination: Scalars['String']['output']
  from: Scalars['String']['output']
  templateId: Scalars['String']['output']
  templateName: Scalars['String']['output']
}

export type AuditTextEmail = {
  __typename?: 'AuditTextEmail'
  destination: Scalars['String']['output']
  from: Scalars['String']['output']
  message: Scalars['String']['output']
  subject: Scalars['String']['output']
}

export type AuditUser = {
  __typename?: 'AuditUser'
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
}

export type BaseAddress = Address & {
  __typename?: 'BaseAddress'
  floor?: Maybe<Scalars['String']['output']>
  locality: Scalars['String']['output']
  postalCode: Scalars['Int']['output']
  streetAndHouseNumber: Scalars['String']['output']
}

export type BexioBill = {
  __typename?: 'BexioBill'
  dueDate: Scalars['DateTime']['output']
  id: Scalars['String']['output']
  overdue: Scalars['Boolean']['output']
  status: BexioBillStatus
}

export enum BexioBillStatus {
  Booked = 'BOOKED',
  Created = 'CREATED',
  Downloaded = 'DOWNLOADED',
  Draft = 'DRAFT',
  Failed = 'FAILED',
  Paid = 'PAID',
  PartiallyCreated = 'PARTIALLY_CREATED',
  PartiallyDownloaded = 'PARTIALLY_DOWNLOADED',
  PartiallyFailed = 'PARTIALLY_FAILED',
  PartiallyPaid = 'PARTIALLY_PAID',
  PartiallySent = 'PARTIALLY_SENT',
  Sent = 'SENT',
}

export type BexioInvoice = {
  __typename?: 'BexioInvoice'
  dueDate: Scalars['DateTime']['output']
  id: Scalars['Int']['output']
  overdue: Scalars['Boolean']['output']
  status: BexioInvoiceStatus
}

export enum BexioInvoiceStatus {
  Canceled = 'CANCELED',
  Draft = 'DRAFT',
  Paid = 'PAID',
  Partial = 'PARTIAL',
  Pending = 'PENDING',
  Unknown = 'UNKNOWN',
  Unpaid = 'UNPAID',
}

export type BoilerDecalingStaticJobQuestions = {
  __typename?: 'BoilerDecalingStaticJobQuestions'
  boilerSizeInLiters: Scalars['Int']['output']
}

export type BoilerDecalingStaticJobQuestionsInput = {
  boilerSizeInLiters: Scalars['Int']['input']
}

export enum BusinessType {
  Company = 'COMPANY',
  Individual = 'INDIVIDUAL',
  NonProfit = 'NON_PROFIT',
  Partnership = 'PARTNERSHIP',
}

export type CalculateInvoiceAmountInput = {
  /** Id of the job this invoice relates to */
  jobId: Scalars['ID']['input']
  perspective: InvoicePerspective
  positions: CalculateInvoiceAmountPositionInput[]
  /** Id of the pro this invoice relates to */
  proId: Scalars['ID']['input']
}

export type CalculateInvoiceAmountPositionInput = {
  amount: Scalars['Int']['input']
  discount?: InputMaybe<Scalars['Int']['input']>
  kind: InvoicePositionKind
  vatRate: Scalars['Float']['input']
}

export type CalculateInvoiceAmountResult = {
  __typename?: 'CalculateInvoiceAmountResult'
  clientServiceFeeEnabled: Scalars['Boolean']['output']
  clientTotalIncVatRounded: Scalars['Float']['output']
  goodwillPro?: Maybe<Scalars['Float']['output']>
  isDamage: Scalars['Boolean']['output']
  proFee: Scalars['Float']['output']
  proFeeCap?: Maybe<Scalars['Float']['output']>
  proFeeRate: Scalars['Float']['output']
  proNet: Scalars['Float']['output']
  proPlanName: Scalars['String']['output']
  proServiceFeeEnabled: Scalars['Boolean']['output']
  proTotal: Scalars['Float']['output']
  proTotalIncVatRounded: Scalars['Float']['output']
  proVat: Scalars['Float']['output']
}

export type CancelJobError = Error & {
  __typename?: 'CancelJobError'
  error: CancelJobUserError
  message: Scalars['String']['output']
}

export type CancelJobResult = CancelJobError | Job

export enum CancelJobUserError {
  IllegalState = 'ILLEGAL_STATE',
  OpenInvoices = 'OPEN_INVOICES',
}

export enum Capacity {
  Admin = 'ADMIN',
  Agent = 'AGENT',
  Client = 'CLIENT',
  Pro = 'PRO',
}

export enum CaptchaError {
  InvalidToken = 'INVALID_TOKEN',
}

export type CatDoorInstallationStaticJobQuestions = {
  __typename?: 'CatDoorInstallationStaticJobQuestions'
  assemblyHeight: CatDoorInstallation_AssemblyHeight
  catDoor: CatDoorInstallation_CatDoor
  frame: CatDoorInstallation_Frame
  glassStrength?: Maybe<CatDoorInstallation_GlassStrength>
  height: Scalars['Float']['output']
  surface: CatDoorInstallation_Surface
  target: CatDoorInstallation_Target
  width: Scalars['Float']['output']
}

export type CatDoorInstallationStaticJobQuestionsInput = {
  assemblyHeight: CatDoorInstallation_AssemblyHeight
  catDoor: CatDoorInstallation_CatDoor
  frame: CatDoorInstallation_Frame
  glassStrength?: InputMaybe<CatDoorInstallation_GlassStrength>
  height: Scalars['Float']['input']
  surface: CatDoorInstallation_Surface
  target: CatDoorInstallation_Target
  width: Scalars['Float']['input']
}

export enum CatDoorInstallation_AssemblyHeight {
  Above_1M = 'ABOVE_1_M',
  Under_1M = 'UNDER_1_M',
}

export enum CatDoorInstallation_CatDoor {
  Chip = 'CHIP',
  Standard = 'STANDARD',
  UseOwn = 'USE_OWN',
}

export enum CatDoorInstallation_Frame {
  Other = 'OTHER',
  PlasticPlastic = 'PLASTIC_PLASTIC',
  WoodMetal = 'WOOD_METAL',
  WoodWood = 'WOOD_WOOD',
}

export enum CatDoorInstallation_GlassStrength {
  Double = 'DOUBLE',
  Single = 'SINGLE',
  Triple = 'TRIPLE',
}

export enum CatDoorInstallation_Surface {
  Glass = 'GLASS',
  Other = 'OTHER',
  Stone = 'STONE',
  Wood = 'WOOD',
}

export enum CatDoorInstallation_Target {
  Door = 'DOOR',
  Other = 'OTHER',
  Window = 'WINDOW',
}

export type Category = {
  __typename?: 'Category'
  icon?: Maybe<Icon>
  id: Scalars['ID']['output']
  label: Scalars['String']['output']
  rank: Scalars['Int']['output']
  status?: Maybe<CategoryStatus>
}

export type CategoryFilters = {
  status?: InputMaybe<CategoryStatus>
}

export type CategoryOrder = {
  rank?: InputMaybe<Ordering>
}

export enum CategoryStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

export type CedeInvoiceError = Error & {
  __typename?: 'CedeInvoiceError'
  error: CedeInvoiceUserError
  message: Scalars['String']['output']
}

export type CedeInvoiceResult = CedeInvoiceError | Invoice

export enum CedeInvoiceUserError {
  InvalidPaymentSystem = 'INVALID_PAYMENT_SYSTEM',
  InvoiceNotDue = 'INVOICE_NOT_DUE',
  SubscriptionUpgradeNeeded = 'SUBSCRIPTION_UPGRADE_NEEDED',
}

export type ChangeProRoleError = Error & {
  __typename?: 'ChangeProRoleError'
  message: Scalars['String']['output']
}

export type ChangeProRoleResult = ChangeProRoleError | User

export type CheckoutSubscriptionPlanResult = PlanChangeScheduled | PlanCheckoutFeatureComplianceError | PlanCheckoutLink

export type ChosenPro = {
  __typename?: 'ChosenPro'
  acceptedOffer?: Maybe<AcceptedOffer>
  address: BaseAddress
  companyName: Scalars['String']['output']
  companyPhoneNumber?: Maybe<Scalars['String']['output']>
  /** Company representative */
  contactPerson: ContactPerson
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  invoice?: Maybe<Invoice>
  jobId: Scalars['String']['output']
  slug: Scalars['String']['output']
  website?: Maybe<Scalars['String']['output']>
}

export type ChosenProInvoiceArgs = {
  perspective: InvoicePerspective
}

export type Client = {
  __typename?: 'Client'
  bringAFriendInviteId: Scalars['ID']['output']
  /** Only available for admins */
  bringAFriendInvitedBy?: Maybe<Client>
  bringAFriendInvitedByClientId?: Maybe<Scalars['ID']['output']>
  company?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  /** Only accessible for the client & admins */
  friends: FriendsResult
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  phoneNumber: Scalars['String']['output']
  salutation: Salutation
}

export type ClientFriendsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export enum ClientAction {
  Cancel = 'CANCEL',
}

export type ClientExpectation = {
  __typename?: 'ClientExpectation'
  consulting: Scalars['Boolean']['output']
  estimation: Scalars['Boolean']['output']
  execution: Scalars['Boolean']['output']
  offer: Scalars['Boolean']['output']
}

export type ClientExpectationInput = {
  consulting: Scalars['Boolean']['input']
  estimation: Scalars['Boolean']['input']
  execution: Scalars['Boolean']['input']
  offer: Scalars['Boolean']['input']
}

export type ClientInformation = {
  __typename?: 'ClientInformation'
  company?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  phoneNumber: Scalars['String']['output']
  salutation: Salutation
}

export type ClientInput = {
  company?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  phoneNumber: Scalars['String']['input']
  salutation: Salutation
}

export type ClientJob = {
  __typename?: 'ClientJob'
  /** Actions possible on the client */
  actions: ClientAction[]
  assignmentStatuses: AssignmentStatus[]
  clientExpectation?: Maybe<ClientExpectation>
  id: Scalars['String']['output']
  offering?: Maybe<JobOffering>
  offers: Offer[]
  status: JobStatus
  title?: Maybe<Scalars['String']['output']>
}

export type ClientJobSearchResult = {
  __typename?: 'ClientJobSearchResult'
  clientId: Scalars['ID']['output']
  data: ClientJob[]
  filter?: Maybe<ClientSearchFilter>
  total: Scalars['Int']['output']
}

export type ClientSearchFilter = {
  __typename?: 'ClientSearchFilter'
  status?: Maybe<JobStatus[]>
}

export type ClientSearchFilterInput = {
  status?: InputMaybe<JobStatus[]>
}

export type ClientUserType = {
  __typename?: 'ClientUserType'
  clientId: Scalars['ID']['output']
  type: ClientUserTypeDiscriminator
}

export enum ClientUserTypeDiscriminator {
  Client = 'CLIENT',
}

export enum CompetenceStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

export type CompleteJobError = Error & {
  __typename?: 'CompleteJobError'
  error: CompleteJobUserError
  message: Scalars['String']['output']
}

export enum CompleteJobNotificationPolicy {
  EmailWithoutGoogleReview = 'EMAIL_WITHOUT_GOOGLE_REVIEW',
  EmailWithGoogleReview = 'EMAIL_WITH_GOOGLE_REVIEW',
  NoEmail = 'NO_EMAIL',
}

export type CompleteJobOptions = {
  /** When set to true, all currently open invoices will be abandoned before the job is completed */
  abandonOpenInvoices?: InputMaybe<Scalars['Boolean']['input']>
  notificationPolicy?: InputMaybe<CompleteJobNotificationPolicy>
}

export type CompleteJobResult = AdminJob | CompleteJobError

export enum CompleteJobUserError {
  /** Only thrown if abandonOpenInvoices is not set to true, otherwise open invoices are cancelled */
  OpenInvoices = 'OPEN_INVOICES',
}

export type ContactPerson = {
  __typename?: 'ContactPerson'
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  phoneNumber: Scalars['String']['output']
  salutation: Salutation
}

export type ContactPersonInput = {
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  phoneNumber: Scalars['String']['input']
  salutation: Salutation
}

export enum CreateAgentUserError {
  EmailAlreadyExists = 'EMAIL_ALREADY_EXISTS',
}

export type CreateAgentUserInput = {
  agentId: Scalars['String']['input']
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  language: Scalars['String']['input']
  lastName: Scalars['String']['input']
  phoneNumber: Scalars['String']['input']
}

export type CreateAgentUserInputError = Error & {
  __typename?: 'CreateAgentUserInputError'
  error: CreateAgentUserError
  message: Scalars['String']['output']
}

export type CreateAgentUserResult = CreateAgentUserInputError | User

export enum CreateProUserError {
  EmailAlreadyExists = 'EMAIL_ALREADY_EXISTS',
  NotificationManagementUnavailable = 'NOTIFICATION_MANAGEMENT_UNAVAILABLE',
  UserQuotaExceeded = 'USER_QUOTA_EXCEEDED',
}

export type CreateProUserInput = {
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  language: Scalars['String']['input']
  lastName: Scalars['String']['input']
  phoneNumber: Scalars['String']['input']
  proId: Scalars['String']['input']
  role: ProRole
  salutation: Salutation
  settings: ProUserSettingsInput
}

export type CreateProUserInputError = Error & {
  __typename?: 'CreateProUserInputError'
  error: CreateProUserError
  /** When undefined, there is no maximum */
  maxUserQuota?: Maybe<Scalars['Int']['output']>
  message: Scalars['String']['output']
}

export type CreateProUserResult = CreateProUserInputError | User

export type Damage = {
  __typename?: 'Damage'
  agency?: Maybe<AgencyByJobRequestCode>
  agencyId?: Maybe<Scalars['ID']['output']>
  agent?: Maybe<AgentByJobRequestCode>
  agentId?: Maybe<Scalars['ID']['output']>
  caseNumber: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  occurredAt: Scalars['DateTime']['output']
}

export type DamageCaseOpenedAuditData = {
  __typename?: 'DamageCaseOpenedAuditData'
  damage: AuditDamage
  job: AuditJob
}

export type DamageCoverage = {
  __typename?: 'DamageCoverage'
  agentNotes: Scalars['String']['output'][]
  /** Deductible amount, paid by the damage insurance, in rappen */
  amount: Scalars['Int']['output']
}

export type DamageCoverageInput = {
  agentNotes: Scalars['String']['input'][]
  /** In Rappen */
  amount: Scalars['Int']['input']
}

export type DamageInput = {
  agentExpectation: AgentExpectationInput
  agentJobRequestCode: Scalars['String']['input']
  caseNumber: Scalars['String']['input']
  occurredAt: Scalars['DateTime']['input']
}

export type DefaultEventData = {
  __typename?: 'DefaultEventData'
  title: Scalars['String']['output']
}

export type DeleteAdminReminderResult = {
  __typename?: 'DeleteAdminReminderResult'
  success: Scalars['Boolean']['output']
}

export type DeleteManualAccountingEntryResult = {
  __typename?: 'DeleteManualAccountingEntryResult'
  id: Scalars['ID']['output']
}

export enum DeleteProUserError {
  SameUser = 'SAME_USER',
}

export type DeleteProUserResult = {
  __typename?: 'DeleteProUserResult'
  error?: Maybe<DeleteProUserError>
  success: Scalars['Boolean']['output']
}

export type DestinationDistance = Distance | DistanceWithDuration

export type DisableUserInputError = Error & {
  __typename?: 'DisableUserInputError'
  message: Scalars['String']['output']
}

export type DisableUserResult = DisableUserInputError | User

export type DismissNewCanceledJobInputError = {
  __typename?: 'DismissNewCanceledJobInputError'
  _empty?: Maybe<Scalars['String']['output']>
}

export type DismissNewCanceledJobResult = AssignedNewJob | DismissNewCanceledJobInputError

export type DismissNotReactedJobInputError = {
  __typename?: 'DismissNotReactedJobInputError'
  _empty?: Maybe<Scalars['String']['output']>
}

export type DismissNotReactedJobResult = AssignedNewJob | DismissNotReactedJobInputError

export type Distance = {
  __typename?: 'Distance'
  distanceText: Scalars['String']['output']
  distanceValue: Scalars['Int']['output']
  mapsLink: Scalars['String']['output']
}

export type DistanceWithDuration = {
  __typename?: 'DistanceWithDuration'
  distanceText: Scalars['String']['output']
  distanceValue: Scalars['Int']['output']
  durationText: Scalars['String']['output']
  durationValue: Scalars['Int']['output']
  mapsLink: Scalars['String']['output']
}

export type EmailSentAuditData = {
  __typename?: 'EmailSentAuditData'
  email: AuditEmail
}

export type EmergencyContact = {
  __typename?: 'EmergencyContact'
  email?: Maybe<Scalars['String']['output']>
  phoneNumber: Scalars['String']['output']
}

export type EmergencyContactInput = {
  email?: InputMaybe<Scalars['String']['input']>
  phoneNumber: Scalars['String']['input']
}

export type EmergencyJob = {
  __typename?: 'EmergencyJob'
  description?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  emergencyType: Scalars['String']['output']
  location: Scalars['String']['output']
  name: Scalars['String']['output']
  phoneNumber: Scalars['String']['output']
  streetAddress: Scalars['String']['output']
}

export enum EmergencyJobRequestError {
  MandatoryFieldValidationError = 'MANDATORY_FIELD_VALIDATION_ERROR',
}

export type EmergencyJobRequestInput = {
  description?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  emergencyType: Scalars['String']['input']
  location: Scalars['String']['input']
  name: Scalars['String']['input']
  phoneNumber: Scalars['String']['input']
  streetAddress: Scalars['String']['input']
}

export type EmergencyJobRequestResult = EmergencyJob | RequestEmergencyJobUserInputError

export enum EnableUserError {
  EmailAlreadyExists = 'EMAIL_ALREADY_EXISTS',
  UserQuotaExceeded = 'USER_QUOTA_EXCEEDED',
}

export type EnableUserInputError = Error & {
  __typename?: 'EnableUserInputError'
  error: EnableUserError
  maxUserQuota?: Maybe<Scalars['Int']['output']>
  message: Scalars['String']['output']
}

export type EnableUserResult = EnableUserInputError | User

export type Error = {
  message: Scalars['String']['output']
}

export type ExecutionDate = {
  __typename?: 'ExecutionDate'
  expectedExecution: ExpectedExecution
  latestPossibleDate?: Maybe<Scalars['DateTime']['output']>
}

export type ExecutionDateInput = {
  expectedExecution: ExpectedExecution
  latestPossibleDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type ExecutionDateScheduledAuditData = {
  __typename?: 'ExecutionDateScheduledAuditData'
  dates: AuditScheduledDate[]
  job: AuditJob
  pro: AuditPro
}

export enum ExpectedExecution {
  Asap = 'ASAP',
  MoreThan_3Months = 'MORE_THAN_3_MONTHS',
  MoreThan_12Months = 'MORE_THAN_12_MONTHS',
  Next_3Months = 'NEXT_3_MONTHS',
  Next_30Days = 'NEXT_30_DAYS',
  Within_6Months = 'WITHIN_6_MONTHS',
  Within_12Months = 'WITHIN_12_MONTHS',
}

export type File = {
  __typename?: 'File'
  contentType: Scalars['String']['output']
  /**
   * Dimensions of an image
   * **Only defined** when the file is an image, and the upload API also sets the dimensions
   */
  dimensions?: Maybe<ImageDimensions>
  fileKey: Scalars['String']['output']
  name?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
}

export type FileInput = {
  key: Scalars['String']['input']
  name: Scalars['String']['input']
}

export type FileMessageContent = {
  __typename?: 'FileMessageContent'
  companionText?: Maybe<Scalars['String']['output']>
  file: File
}

export type FileMessageContentInput = {
  companionText?: InputMaybe<Scalars['String']['input']>
  contentType: Scalars['String']['input']
  /** Dimensions of the image, if the file is an image */
  dimensions?: InputMaybe<ImageDimensionsInput>
  fileKey: Scalars['String']['input']
  fileName: Scalars['String']['input']
}

export type FileTooBigError = {
  __typename?: 'FileTooBigError'
  maxContentLength: Scalars['Int']['output']
}

export type FileUploadError = Error & {
  __typename?: 'FileUploadError'
  cause: FileUploadErrorCause
  message: Scalars['String']['output']
}

export type FileUploadErrorCause = FileTooBigError | UnsupportedFileTypeError

export type FileUploadLink = {
  __typename?: 'FileUploadLink'
  contentLength: Scalars['Int']['output']
  contentType: Scalars['String']['output']
  /** This link can be used to access the file **only after** it has been uploaded with 'uploadLink' */
  downloadLink: Scalars['String']['output']
  fileKey: Scalars['String']['output']
  /** This link can be used to upload the file. */
  uploadLink: Scalars['String']['output']
}

export type FileUploadRequest = {
  contentLength: Scalars['Int']['input']
  contentType: Scalars['String']['input']
  fileName: Scalars['String']['input']
}

export type FileUploadResponse = FileUploadError | FileUploadLink

export enum FilterLevel {
  Agency = 'AGENCY',
  Agent = 'AGENT',
}

export type FirstContactEstablishedAuditData = {
  __typename?: 'FirstContactEstablishedAuditData'
  job: AuditJob
  pro: AuditPro
}

export type FirstContactUnresponsiveAuditData = {
  __typename?: 'FirstContactUnresponsiveAuditData'
  job: AuditJob
  pro: AuditPro
}

export type Friend = {
  __typename?: 'Friend'
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  rewardVoucher?: Maybe<Voucher>
  status: FriendStatus
}

export enum FriendStatus {
  RequestSubmitted = 'REQUEST_SUBMITTED',
  VoucherIssued = 'VOUCHER_ISSUED',
  VoucherUsed = 'VOUCHER_USED',
}

export type FriendsResult = {
  __typename?: 'FriendsResult'
  data: Friend[]
  total: Scalars['Int']['output']
}

export type GalleryImageInput = {
  description?: InputMaybe<LocalizedStringInput>
  imageFileKey: Scalars['String']['input']
  title?: InputMaybe<LocalizedStringInput>
}

export type GeoPoint = {
  lat: Scalars['Float']['input']
  lng: Scalars['Float']['input']
}

export type GeoResult = PostalCode | Region

export type GetAssignedJobError = {
  __typename?: 'GetAssignedJobError'
  error?: Maybe<GetAssignedJobErrorType>
}

export enum GetAssignedJobErrorType {
  AssignedJobNotFound = 'ASSIGNED_JOB_NOT_FOUND',
}

export enum GlobalUserActions {
  /** Can assign a role when creating a new user */
  AssignRoleToNewUser = 'ASSIGN_ROLE_TO_NEW_USER',
}

export type HasPayout = {
  payout?: Maybe<Payout>
}

export type HedgeTrimmingStaticJobQuestions = {
  __typename?: 'HedgeTrimmingStaticJobQuestions'
  hedgeHeight: Scalars['Int']['output']
  hedgeLength: Scalars['Int']['output']
}

export type HedgeTrimmingStaticJobQuestionsInput = {
  hedgeHeight: Scalars['Int']['input']
  hedgeLength: Scalars['Int']['input']
}

export type HourlyCost = {
  __typename?: 'HourlyCost'
  hourlyRate: Scalars['Float']['output']
  maxHours?: Maybe<Scalars['Float']['output']>
  minHours?: Maybe<Scalars['Float']['output']>
}

export type HourlyOfferCost = {
  hourlyRate?: InputMaybe<Scalars['Float']['input']>
  maxHours?: InputMaybe<Scalars['Float']['input']>
  minHours?: InputMaybe<Scalars['Float']['input']>
}

export type Icon = {
  __typename?: 'Icon'
  collection: Scalars['String']['output']
  name: Scalars['String']['output']
}

export type Image = {
  __typename?: 'Image'
  contentType: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type ImageAsset = {
  __typename?: 'ImageAsset'
  title?: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
}

export type ImageDimensions = {
  __typename?: 'ImageDimensions'
  height: Scalars['Int']['output']
  width: Scalars['Int']['output']
}

export type ImageDimensionsInput = {
  height: Scalars['Int']['input']
  width: Scalars['Int']['input']
}

export enum ImageFormat {
  Jpg = 'JPG',
  Webp = 'WEBP',
}

export type ImportTriggerResult = {
  __typename?: 'ImportTriggerResult'
  _empty?: Maybe<Scalars['String']['output']>
}

export enum ImportType {
  Agency = 'AGENCY',
  Competence = 'COMPETENCE',
  Intentions = 'INTENTIONS',
  Offering = 'OFFERING',
  Pages = 'PAGES',
  Questions = 'QUESTIONS',
  Voucher = 'VOUCHER',
}

export type Industry = {
  __typename?: 'Industry'
  id: Scalars['String']['output']
  label: Scalars['String']['output']
  offerings: Offering[]
  rank: Scalars['Int']['output']
  skills?: Maybe<Skill[]>
}

export type IndustrySkillsArgs = {
  status?: InputMaybe<CompetenceStatus>
}

export type IndustryOrder = {
  alphabetical?: InputMaybe<Ordering>
}

export type IndustrySearchItem = {
  __typename?: 'IndustrySearchItem'
  id: Scalars['ID']['output']
  label: Scalars['String']['output']
}

export type InstallLampsStaticJobQuestions = {
  __typename?: 'InstallLampsStaticJobQuestions'
  /** How many lamps to install with each type of proximity */
  connectionProximity: InstallLamps_ConnectionProximity
  disposalRequired: Scalars['Boolean']['output']
  /** How many lamps already have electrical connections */
  electricalConnections: Scalars['Int']['output']
  heaviestLampWeight: InstallLamps_LampWeight
  highestRoomCeiling: InstallLamps_RoomCeiling
  /** How many lamps of each type to install */
  lampTypes: InstallLamps_TypeOfLamps
  numberOfLamps: Scalars['Int']['output']
  /** How many lamps to install on each wall/ceiling material */
  wallMaterial: InstallLamps_WallMaterial
}

export type InstallLampsStaticJobQuestionsInput = {
  /** How many lamps to install with each type of proximity */
  connectionProximity: InstallLamps_ConnectionProximityInput
  disposalRequired: Scalars['Boolean']['input']
  /** How many lamps already have electrical connections */
  electricalConnections: Scalars['Int']['input']
  heaviestLampWeight: InstallLamps_LampWeight
  highestRoomCeiling: InstallLamps_RoomCeiling
  /** How many lamps of each type to install */
  lampTypes: InstallLamps_TypeOfLampsInput
  numberOfLamps: Scalars['Int']['input']
  /** How many lamps to install on each wall/ceiling material */
  wallMaterial: InstallLamps_WallMaterialInput
}

export type InstallLamps_ConnectionProximity = {
  __typename?: 'InstallLamps_ConnectionProximity'
  directlyAtConnection?: Maybe<Scalars['Int']['output']>
  moreThanOneMeter?: Maybe<Scalars['Int']['output']>
  upToOneMeter?: Maybe<Scalars['Int']['output']>
}

export type InstallLamps_ConnectionProximityInput = {
  directlyAtConnection?: InputMaybe<Scalars['Int']['input']>
  moreThanOneMeter?: InputMaybe<Scalars['Int']['input']>
  upToOneMeter?: InputMaybe<Scalars['Int']['input']>
}

export enum InstallLamps_LampWeight {
  MoreThan_10Kg = 'MORE_THAN_10_KG',
  Under_10Kg = 'UNDER_10_KG',
}

export enum InstallLamps_RoomCeiling {
  Above_2_4M = 'ABOVE_2_4_M',
  Under_2_4M = 'UNDER_2_4_M',
}

export type InstallLamps_TypeOfLamps = {
  __typename?: 'InstallLamps_TypeOfLamps'
  ceiling?: Maybe<Scalars['Int']['output']>
  chandelier?: Maybe<Scalars['Int']['output']>
  hanging?: Maybe<Scalars['Int']['output']>
  other?: Maybe<Scalars['Int']['output']>
  pendant?: Maybe<Scalars['Int']['output']>
  wall?: Maybe<Scalars['Int']['output']>
}

export type InstallLamps_TypeOfLampsInput = {
  ceiling?: InputMaybe<Scalars['Int']['input']>
  chandelier?: InputMaybe<Scalars['Int']['input']>
  hanging?: InputMaybe<Scalars['Int']['input']>
  other?: InputMaybe<Scalars['Int']['input']>
  pendant?: InputMaybe<Scalars['Int']['input']>
  wall?: InputMaybe<Scalars['Int']['input']>
}

export type InstallLamps_WallMaterial = {
  __typename?: 'InstallLamps_WallMaterial'
  brick?: Maybe<Scalars['Int']['output']>
  concrete?: Maybe<Scalars['Int']['output']>
  metal?: Maybe<Scalars['Int']['output']>
  plaster?: Maybe<Scalars['Int']['output']>
  unknown?: Maybe<Scalars['Int']['output']>
  wood?: Maybe<Scalars['Int']['output']>
}

export type InstallLamps_WallMaterialInput = {
  brick?: InputMaybe<Scalars['Int']['input']>
  concrete?: InputMaybe<Scalars['Int']['input']>
  metal?: InputMaybe<Scalars['Int']['input']>
  plaster?: InputMaybe<Scalars['Int']['input']>
  unknown?: InputMaybe<Scalars['Int']['input']>
  wood?: InputMaybe<Scalars['Int']['input']>
}

export type Intention = {
  __typename?: 'Intention'
  icon: Icon
  label: Scalars['String']['output']
  offeringId: Scalars['ID']['output']
  /** Questions that are linked to a given Intention. */
  questions?: Maybe<Question[]>
  rank: Scalars['Int']['output']
  slug: Scalars['String']['output']
}

export type IntentionQuestionsArgs = {
  filterBy?: InputMaybe<QuestionFilter>
  orderBy?: InputMaybe<QuestionOrder>
}

export type Invite = {
  __typename?: 'Invite'
  agent?: Maybe<Agent>
  agentId?: Maybe<Scalars['ID']['output']>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  invitedEmail: Scalars['String']['output']
  jobId?: Maybe<Scalars['ID']['output']>
  status: InviteStatus
  type: InviteType
  usedAt?: Maybe<Scalars['DateTime']['output']>
}

export enum InviteStatus {
  Created = 'CREATED',
  Used = 'USED',
}

export enum InviteType {
  Pro = 'PRO',
}

export type Invoice = {
  __typename?: 'Invoice'
  /** Actions possible on the invoice */
  actions: InvoiceAction[]
  clientPaymentInvoiceFileKeys: Scalars['String']['output'][]
  clientPaymentInvoices: File[]
  clientPaymentReminder?: Maybe<File>
  clientPaymentReminderFileKey?: Maybe<Scalars['String']['output']>
  clientServiceFeeEnabled: Scalars['Boolean']['output']
  clientServiceFeeVatRate: Scalars['Float']['output']
  /** Amount a client pays. Discount applied, VAT included. Only available for admins and clients */
  clientTotal: Scalars['Float']['output']
  createdAt: Scalars['DateTime']['output']
  dueDays: Scalars['Int']['output']
  fileKeys: Scalars['String']['output'][]
  files: File[]
  id: Scalars['String']['output']
  jobId: Scalars['String']['output']
  messageToClient?: Maybe<Scalars['String']['output']>
  paidAt?: Maybe<Scalars['DateTime']['output']>
  paymentReminders: PaymentReminders
  perspective: InvoicePerspective
  positions: InvoicePosition[]
  proFeeCap?: Maybe<Scalars['Float']['output']>
  proFeeRate: Scalars['Float']['output']
  proId: Scalars['String']['output']
  /** Amount a pro will gain for this Invoice. Discount applied, VAT included, and fee substracted */
  proNet: Scalars['Float']['output']
  proPaymentReceiptFileKeys: Scalars['String']['output'][]
  proPaymentReceipts: File[]
  proPlanName: Scalars['String']['output']
  proServiceFeeEnabled: Scalars['Boolean']['output']
  proServiceFeeVatRate: Scalars['Float']['output']
  /** Pro total costs, discount applied and VAT included, pro fee excluded */
  proTotal: Scalars['Float']['output']
  sentAt?: Maybe<Scalars['DateTime']['output']>
  status: InvoiceStatus
  title: Scalars['String']['output']
}

export type InvoiceAbandonedAuditData = {
  __typename?: 'InvoiceAbandonedAuditData'
  invoice: AuditInvoice
}

export enum InvoiceAction {
  Approve = 'APPROVE',
  /** An invoice cession means that buildigo will payout the invoice to the pro, even if the client has not paid yet */
  Cede = 'CEDE',
  MarkAsExternal = 'MARK_AS_EXTERNAL',
  MarkAsPaid = 'MARK_AS_PAID',
  MarkAsPartiallyPaid = 'MARK_AS_PARTIALLY_PAID',
  MuteClientReminders = 'MUTE_CLIENT_REMINDERS',
  Revise = 'REVISE',
}

export type InvoiceApprovedAuditData = {
  __typename?: 'InvoiceApprovedAuditData'
  externalInvoiceId?: Maybe<Scalars['String']['output']>
}

export type InvoiceCancelledAuditData = {
  __typename?: 'InvoiceCancelledAuditData'
  invoice: AuditInvoice
}

export type InvoiceCededAuditData = {
  __typename?: 'InvoiceCededAuditData'
  invoice: AuditInvoice
}

export type InvoiceCreatedAuditData = {
  __typename?: 'InvoiceCreatedAuditData'
  assignment: AuditAssignment
  invoice: AuditInvoice
  job: AuditJob
  pro: AuditPro
}

export enum InvoiceEditor {
  Admin = 'ADMIN',
  Pro = 'PRO',
}

export type InvoiceFileDeleteRequest = {
  fileKey: Scalars['String']['input']
  jobId: Scalars['String']['input']
}

export type InvoiceFileDeleteResult = {
  __typename?: 'InvoiceFileDeleteResult'
  errorMessage?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type InvoiceInput = {
  dueDays: Scalars['Int']['input']
  editor: InvoiceEditor
  fileKeys: Scalars['String']['input'][]
  message?: InputMaybe<Scalars['String']['input']>
  positions: InvoicePositionInput[]
  title: Scalars['String']['input']
}

export type InvoiceMarkedAsExternalAuditData = {
  __typename?: 'InvoiceMarkedAsExternalAuditData'
  invoice: AuditInvoice
}

export type InvoiceOrder = {
  creationDate?: InputMaybe<Ordering>
  sentDate?: InputMaybe<Ordering>
}

export type InvoicePaidAuditData = {
  __typename?: 'InvoicePaidAuditData'
  invoice: AuditInvoice
  job: AuditJob
  transactions?: Maybe<InvoiceTransactionsAuditData>
}

export type InvoicePartiallyPaidAuditData = {
  __typename?: 'InvoicePartiallyPaidAuditData'
  invoice: AuditInvoice
  job: AuditJob
}

export enum InvoicePerspective {
  Admin = 'ADMIN',
  Client = 'CLIENT',
  Pro = 'PRO',
}

export type InvoicePosition = {
  __typename?: 'InvoicePosition'
  amount: Scalars['Float']['output']
  discount?: Maybe<Scalars['Float']['output']>
  id: Scalars['String']['output']
  invoiceId: Scalars['String']['output']
  kind: InvoicePositionKind
  signum: Signum
  title: Scalars['String']['output']
  vatRate: Scalars['Float']['output']
}

export type InvoicePositionInput = {
  amount: Scalars['Float']['input']
  discount?: InputMaybe<Scalars['Float']['input']>
  kind: InvoicePositionKind
  title: Scalars['String']['input']
  vatRate: Scalars['Float']['input']
}

export enum InvoicePositionKind {
  GoodwillClient = 'GOODWILL_CLIENT',
  GoodwillPro = 'GOODWILL_PRO',
  Regular = 'REGULAR',
  Reminder = 'REMINDER',
  Voucher = 'VOUCHER',
}

export type InvoiceSearchCriteria = {
  clientSearchText?: InputMaybe<Scalars['String']['input']>
  jobStatus?: InputMaybe<JobStatus>
  languages: Language[]
  /** Filters the invoices based on if the most recent reminder sent to the client was the one specified in the query. */
  mostRecentlyDoneReminder?: InputMaybe<PaymentReminderName>
  proSearchText?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<InvoiceStatus>
}

export type InvoiceSearchResult = {
  __typename?: 'InvoiceSearchResult'
  data: InvoiceSearchResultItem[]
  total: Scalars['Int']['output']
}

export type InvoiceSearchResultItem = {
  __typename?: 'InvoiceSearchResultItem'
  /** Audit Events related to the invoice's job */
  auditEvents: AuditEvent[]
  invoice: Invoice
  job: Job
  pro: Pro
}

export type InvoiceSearchResultItemAuditEventsArgs = {
  excludeEventTypes?: InputMaybe<AuditEventType[]>
  includeEventTypes?: InputMaybe<AuditEventType[]>
}

export type InvoiceSentAuditData = {
  __typename?: 'InvoiceSentAuditData'
  externalInvoiceId?: Maybe<Scalars['String']['output']>
}

export type InvoiceSentToClientAuditData = {
  __typename?: 'InvoiceSentToClientAuditData'
  invoice: AuditInvoice
  job: AuditJob
}

export enum InvoiceStatus {
  Abandoned = 'ABANDONED',
  Cancelled = 'CANCELLED',
  Ceded = 'CEDED',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  Sent = 'SENT',
  Unpaid = 'UNPAID',
}

export type InvoiceTransactionAuditData = {
  __typename?: 'InvoiceTransactionAuditData'
  /** Amount in chf */
  amount: Scalars['Float']['output']
  psp: Scalars['String']['output']
}

export type InvoiceTransactionsAuditData = {
  __typename?: 'InvoiceTransactionsAuditData'
  id: Scalars['String']['output']
  transactions: InvoiceTransactionAuditData[]
}

export type InvoiceUpdateReceivedAuditData = {
  __typename?: 'InvoiceUpdateReceivedAuditData'
  paymentSystem: Scalars['String']['output']
  update: Scalars['JSON']['output']
}

export type InvoiceUpdatedAuditData = {
  __typename?: 'InvoiceUpdatedAuditData'
  invoice: AuditInvoice
  job: AuditJob
  update: Scalars['JSON']['output']
}

export type Job = {
  __typename?: 'Job'
  additionalInformation?: Maybe<Scalars['String']['output']>
  additionalOffers: AdditionalOffers
  /** Returns metrics on all the assigned pros for the job */
  allAssignedProMetrics: AssignedProMetrics
  /**
   * Return all pros assigned to the job request
   *
   * **Role**: Global admin role required to access this field
   */
  allAssignedPros?: Maybe<AssignedPro[]>
  /**
   * Return all assigned pros that have accepted the job.
   * This means all pros that were assigned the jobs and have shown interest in the job.
   */
  allAssignedProsThatAccepted?: Maybe<AssignedPro[]>
  analyticParams?: Maybe<AnalyticParams[]>
  /**
   * Return a specific assigned pro that has accepted the job.
   * This means all pros that were assigned the jobs and have shown interest in the job.
   */
  assignedProThatAccepted?: Maybe<AssignedPro>
  billingAddress: BaseAddress
  /** Client billing information for the job */
  billingClient: ClientInformation
  cancelReason?: Maybe<JobCancellationReason>
  cancelReasonText?: Maybe<Scalars['String']['output']>
  cancellationDate?: Maybe<Scalars['DateTime']['output']>
  /**
   * Return the pro that was chosen for the job.
   * A pro is chosen once his offer was accepted for the job.
   */
  chosenPro?: Maybe<ChosenPro>
  /** Client information for the job */
  client: ClientInformation
  /** Actions possible on the client */
  clientActions: ClientAction[]
  clientExpectation?: Maybe<ClientExpectation>
  /** id of the client requesting the job */
  clientId?: Maybe<Scalars['String']['output']>
  clientRemindersMuted: Scalars['Boolean']['output']
  completionDate?: Maybe<Scalars['DateTime']['output']>
  /** Damage linked to the job. */
  damage?: Maybe<Damage>
  damageAgentExpectation?: Maybe<AgentExpectation>
  damageId?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  executionAddress?: Maybe<AddressWithOwnership>
  executionDate: ExecutionDate
  fileKeys?: Maybe<Scalars['String']['output'][]>
  files?: Maybe<File[]>
  firstComeFirstServedEnabled: Scalars['Boolean']['output']
  firstComeFirstServedSeatCount: Scalars['Int']['output']
  id: Scalars['String']['output']
  mutedMetrics: Scalars['String']['output'][]
  offering?: Maybe<JobOffering>
  referrer?: Maybe<Scalars['String']['output']>
  status: JobStatus
  submittedAt: Scalars['DateTime']['output']
  title?: Maybe<Scalars['String']['output']>
  /**
   * Voucher applied to the job
   * This is set only when a valid voucher was applied
   */
  voucher?: Maybe<Voucher>
  /** Voucher code as entered by the client, unchecked */
  voucherCode?: Maybe<Scalars['String']['output']>
  voucherId?: Maybe<Scalars['String']['output']>
}

export type JobAssignedProThatAcceptedArgs = {
  proId: Scalars['ID']['input']
}

export type JobAcceptedAuditData = {
  __typename?: 'JobAcceptedAuditData'
  assignment: AuditAssignment
  job: AuditJob
  pro: AuditPro
}

export type JobAnalyticParamsInput = {
  name: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type JobAssignedToProAuditData = {
  __typename?: 'JobAssignedToProAuditData'
  assignment: AuditAssignment
  job: AuditJob
  pro: AuditPro
}

export type JobBookmark = {
  __typename?: 'JobBookmark'
  adminJob?: Maybe<AdminJob>
  id: Scalars['ID']['output']
  job?: Maybe<Job>
}

export type JobByIdError = Error & {
  __typename?: 'JobByIdError'
  error: JobByIdUserError
  message: Scalars['String']['output']
}

export type JobByIdResult = Job | JobByIdError

export enum JobByIdUserError {
  AuthRequired = 'AUTH_REQUIRED',
}

export enum JobCancellationReason {
  FoundAnotherProInternet = 'FOUND_ANOTHER_PRO_INTERNET',
  FoundAnotherProPlatform = 'FOUND_ANOTHER_PRO_PLATFORM',
  FoundAnotherProWordOfMouth = 'FOUND_ANOTHER_PRO_WORD_OF_MOUTH',
  IncompatibleDeadline = 'INCOMPATIBLE_DEADLINE',
  Other = 'OTHER',
  ProblemResolved = 'PROBLEM_RESOLVED',
  TooExpensive = 'TOO_EXPENSIVE',
}

export type JobCancelledAuditData = {
  __typename?: 'JobCancelledAuditData'
  reason?: Maybe<JobCancellationReason>
  reasonText?: Maybe<Scalars['String']['output']>
}

export type JobDeclinedAuditData = {
  __typename?: 'JobDeclinedAuditData'
  assignment: AuditAssignment
  job: AuditJob
  pro: AuditPro
  reason?: Maybe<AssignmentDeclineReason>
  reasonText?: Maybe<Scalars['String']['output']>
}

export type JobFileDeleteRequest = {
  fileKey: Scalars['String']['input']
}

export type JobFileDeleteResult = {
  __typename?: 'JobFileDeleteResult'
  errorMessage?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type JobMetricMutedAuditData = {
  __typename?: 'JobMetricMutedAuditData'
  message?: Maybe<Scalars['String']['output']>
  metric: Scalars['String']['output']
  until?: Maybe<Scalars['DateTime']['output']>
}

export enum JobMetricUnmuteReason {
  Manual = 'MANUAL',
  Scheduled = 'SCHEDULED',
}

export type JobMetricUnmutedAuditData = {
  __typename?: 'JobMetricUnmutedAuditData'
  message?: Maybe<Scalars['String']['output']>
  metric: Scalars['String']['output']
  reason: JobMetricUnmuteReason
}

export type JobOffering = {
  __typename?: 'JobOffering'
  executionAddressEnabled: Scalars['Boolean']['output']
  firstComeFirstServedEnabled: Scalars['Boolean']['output']
  firstComeFirstServedSeatCount: Scalars['Int']['output']
  icon?: Maybe<Icon>
  id: Scalars['ID']['output']
  intention?: Maybe<JobOfferingIntention>
  intentionSlug?: Maybe<Scalars['String']['output']>
  jobId: Scalars['ID']['output']
  label: Scalars['String']['output']
  /** minimum price communicated to the client in Rappen */
  minimumPrice?: Maybe<Scalars['Float']['output']>
  priceRange?: Maybe<PriceRange>
  /** questions for the job regardless of intention */
  questions?: Maybe<JobOfferingQuestion[]>
  siteVisitEnabled: Scalars['Boolean']['output']
  staticQuestions?: Maybe<StaticJobQuestions>
}

export type JobOfferingAnswer = {
  __typename?: 'JobOfferingAnswer'
  id: Scalars['ID']['output']
  label?: Maybe<Scalars['String']['output']>
  rank: Scalars['Int']['output']
}

export type JobOfferingInput = {
  id: Scalars['String']['input']
  intentionSlug?: InputMaybe<Scalars['String']['input']>
  /** Array of dynamic questions and answers */
  questions: JobOfferingQuestionInput[]
  /**
   * Static questions answers, use when question form is not generated dynamically
   * Only provide one field from "StaticQuestions"
   */
  staticQuestions?: InputMaybe<StaticJobQuestionsInput>
}

export type JobOfferingIntention = {
  __typename?: 'JobOfferingIntention'
  icon: Icon
  label: Scalars['String']['output']
  slug: Scalars['String']['output']
}

export type JobOfferingQuestion = {
  __typename?: 'JobOfferingQuestion'
  additionalInformation?: Maybe<Scalars['String']['output']>
  answerValue?: Maybe<Scalars['String']['output']>
  answers?: Maybe<JobOfferingAnswer[]>
  clientFacingLabel: Scalars['String']['output']
  id: Scalars['ID']['output']
  proFacingLabel: Scalars['String']['output']
  rank: Scalars['Int']['output']
  tooltip?: Maybe<Scalars['String']['output']>
  type: QuestionType
}

export type JobOfferingQuestionInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>
  answerIds?: InputMaybe<Scalars['String']['input'][]>
  answerValue?: InputMaybe<Scalars['String']['input']>
  id: Scalars['String']['input']
}

export enum JobReopenErrorType {
  AssignmentNotCancelled = 'ASSIGNMENT_NOT_CANCELLED',
  JobNotCancelled = 'JOB_NOT_CANCELLED',
}

export type JobReopenResult = {
  __typename?: 'JobReopenResult'
  error?: Maybe<JobReopenErrorType>
  success: Scalars['Boolean']['output']
}

export type JobRequestInput = {
  analyticParams?: InputMaybe<JobAnalyticParamsInput[]>
  /** Billing address for the job request. If empty, executionAddress will be used as the billing address */
  billingAddress?: InputMaybe<AddressInput>
  billingClient?: InputMaybe<ClientInput>
  client: ClientInput
  clientExpectation?: InputMaybe<ClientExpectationInput>
  description?: InputMaybe<Scalars['String']['input']>
  executionAddress?: InputMaybe<AddressWithOwnershipInput>
  executionDate: ExecutionDateInput
  /** Keys to the files attached to the job request */
  fileKeys?: InputMaybe<Scalars['String']['input'][]>
  offering?: InputMaybe<JobOfferingInput>
  referrer?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  voucherCode?: InputMaybe<Scalars['String']['input']>
}

export type JobRequestResult = Job | RequestJobUserInputError

export type JobRequestedAuditData = {
  __typename?: 'JobRequestedAuditData'
  damage?: Maybe<AuditDamage>
  job: AuditJob
}

export type JobSearchCriteria = {
  actionsNeeded?: InputMaybe<ActionsNeeded>
  languages: Language[]
  location?: InputMaybe<JobSearchLocation>
  onlyBookmarks?: InputMaybe<Scalars['Boolean']['input']>
  searchText?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<JobStatus>
  types?: InputMaybe<JobType[]>
  year?: InputMaybe<Scalars['Int']['input']>
}

export type JobSearchLocation = {
  meterRadius?: InputMaybe<Scalars['Int']['input']>
  postalCode: Scalars['Int']['input']
}

export type JobSearchResult = {
  __typename?: 'JobSearchResult'
  data: AdminJob[]
  total: Scalars['Int']['output']
}

export enum JobStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  ProChosen = 'PRO_CHOSEN',
  Requested = 'REQUESTED',
}

export enum JobType {
  Damage = 'DAMAGE',
  Request = 'REQUEST',
}

export enum Language {
  English = 'ENGLISH',
  French = 'FRENCH',
  German = 'GERMAN',
  Italian = 'ITALIAN',
}

export type LawnMaintenanceStaticJobQuestions = {
  __typename?: 'LawnMaintenanceStaticJobQuestions'
  shouldBeMowed: Scalars['Boolean']['output']
  sizeOfArea: Scalars['Int']['output']
}

export type LawnMaintenanceStaticJobQuestionsInput = {
  shouldBeMowed: Scalars['Boolean']['input']
  sizeOfArea: Scalars['Int']['input']
}

export type LawnMowningStaticJobQuestions = {
  __typename?: 'LawnMowningStaticJobQuestions'
  sizeOfArea: Scalars['Int']['output']
}

export type LawnMowningStaticJobQuestionsInput = {
  sizeOfArea: Scalars['Int']['input']
}

export type LayingParquetStaticJobQuestions = {
  __typename?: 'LayingParquetStaticJobQuestions'
  sizeOfFloor: Scalars['Int']['output']
  sizeOfFloorToBeDisposed?: Maybe<Scalars['Int']['output']>
  skirtingBoardLength?: Maybe<Scalars['Int']['output']>
  type: LayingParquet_ParquetType
}

export type LayingParquetStaticJobQuestionsInput = {
  sizeOfFloor: Scalars['Int']['input']
  sizeOfFloorToBeDisposed?: InputMaybe<Scalars['Int']['input']>
  skirtingBoardLength?: InputMaybe<Scalars['Int']['input']>
  type: LayingParquet_ParquetType
}

export enum LayingParquet_ParquetType {
  ParquetEconomy = 'PARQUET_ECONOMY',
  ParquetPremium = 'PARQUET_PREMIUM',
  ParquetSynthetic = 'PARQUET_SYNTHETIC',
}

export type LeadCreationRequest = {
  email: Scalars['String']['input']
  firstAndLastName: Scalars['String']['input']
  message: Scalars['String']['input']
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  referrer: Scalars['String']['input']
  topic?: InputMaybe<LeadCreationRequestTopic>
}

export type LeadCreationRequestTopic = {
  sendGridListIds: Scalars['String']['input'][]
  topic: Scalars['String']['input']
}

export type LeadCreationResult = {
  __typename?: 'LeadCreationResult'
  error?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type LocalizedString = {
  __typename?: 'LocalizedString'
  de?: Maybe<Scalars['String']['output']>
  en?: Maybe<Scalars['String']['output']>
  fr?: Maybe<Scalars['String']['output']>
  it?: Maybe<Scalars['String']['output']>
}

export type LocalizedStringInput = {
  de?: InputMaybe<Scalars['String']['input']>
  en?: InputMaybe<Scalars['String']['input']>
  fr?: InputMaybe<Scalars['String']['input']>
  it?: InputMaybe<Scalars['String']['input']>
}

export type LocationSearchResult = {
  __typename?: 'LocationSearchResult'
  address: BaseAddress
  default: Scalars['Boolean']['output']
  email?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  phoneNumber: Scalars['String']['output']
  representativePerson?: Maybe<ProLocationRepresentative>
  status: ProLocationStatus
}

export type ManualAccountingEntry = {
  __typename?: 'ManualAccountingEntry'
  comment: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  creatorEmail: Scalars['String']['output']
  creditors: Scalars['Int']['output']
  debtorLosses: Scalars['Int']['output']
  debtors: Scalars['Int']['output']
  delCredere: Scalars['Int']['output']
  discount: Scalars['Int']['output']
  earnings: Scalars['Int']['output']
  goodwill: Scalars['Int']['output']
  id: Scalars['ID']['output']
}

export type ManualAccountingEntryResult = {
  __typename?: 'ManualAccountingEntryResult'
  data: ManualAccountingEntry[]
  total: Scalars['Int']['output']
}

export type MarkInvoiceAsExternalOptions = {
  clientServiceFeeEnabled?: InputMaybe<Scalars['Boolean']['input']>
  isPaid: Scalars['Boolean']['input']
  proServiceFeeEnabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type MarkInvoicesAsExternalResult = Invoice

export type MerchantUpdateInput = {
  bankAccountHolder?: InputMaybe<Scalars['String']['input']>
  bankAccountIban?: InputMaybe<Scalars['String']['input']>
  businessIdentificationNumber?: InputMaybe<Scalars['String']['input']>
  hasLiabilityInsurance?: InputMaybe<Scalars['Boolean']['input']>
  isSubjectToVAT?: InputMaybe<Scalars['Boolean']['input']>
  liabilityInsurance?: InputMaybe<Scalars['String']['input']>
  liabilityInsuranceExpirationDate?: InputMaybe<Scalars['DateTime']['input']>
}

export type Message = {
  __typename?: 'Message'
  content: MessageContent
  id: Scalars['ID']['output']
  roomId: Scalars['ID']['output']
  sender: RoomMember
  senderId: Scalars['String']['output']
  sentAt: Scalars['DateTime']['output']
}

export type MessageContent = FileMessageContent | SimpleTextMessageContent

export type MessageContentInput = {
  file?: InputMaybe<FileMessageContentInput>
  simple?: InputMaybe<SimpleTextMessageContentInput>
}

export type MessageInput = {
  content: MessageContentInput
  roomId: Scalars['ID']['input']
}

export type MessageSentToAdminFromProAuditData = {
  __typename?: 'MessageSentToAdminFromProAuditData'
  job: AuditJob
  message: Scalars['String']['output']
  pro: AuditPro
}

export enum MessageSortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Mutation = {
  __typename?: 'Mutation'
  _empty?: Maybe<Scalars['String']['output']>
  acceptJob: ProJobAcceptanceResult
  acceptOffer?: Maybe<OfferAcceptanceResult>
  /** **All** accounting mutations are grouped under the 'accounting' subgraph */
  accounting?: Maybe<AccountingMutations>
  addCommentToJob: AddCommentResult
  addCommentToPro: AddCommentResult
  addRoomMember: Room
  adminCancelJob: AdminCancelJobResult
  adminDeclineJobFroPro: AssignedPro
  adminDeleteJobFile: JobFileDeleteResult
  adminUpdateJobBillingContact: AdminJobUpdateResult
  adminUpdateJobContact: AdminJobUpdateResult
  adminUpdatePro: AdminProUpdateResult
  /**
   * Approves an invoice to be sent to client
   * This mutation is only applicable to invoices from the bexio payment system
   */
  approveInvoice: Invoice
  archiveOffer?: Maybe<ArchiveOfferResult>
  assignAgentToJob: AdminJob
  assignJobToPros: AssignedPro[]
  bookmarkJob?: Maybe<JobBookmark>
  cancelJob: CancelJobResult
  /**
   * An invoice cession means that buildigo will payout the invoice to the pro, even if the client has not paid yet.
   * Only possible when:
   * - Invoice is due
   * - Payment type is bexio
   * - Invoice emitter (pro) has a subscription with payment guarantee
   */
  cedeInvoice: CedeInvoiceResult
  /**
   * Checkout a pro subscription plan.
   * Multiple path can occur as a result:
   * - *PlanCheckoutLink*: pro has to complete the checkout mechanism using the provided link. Once complete, the plan will become the active subscription for the pro.
   * - *PlanChangeScheduled*: plan change was scheduled for next end of term.
   * - *PlanCheckoutFeatureComplianceError*: plan cannot be updated as the current feature usage is not compliant with the new plan.
   */
  checkoutSubscriptionPlan: CheckoutSubscriptionPlanResult
  clearProPriority: PrioritiseProResult
  completeJob: CompleteJobResult
  createInvoice: SaveInvoiceResult
  createLead: LeadCreationResult
  createOffer?: Maybe<SubmitOfferResult>
  declineJob: ProJobDeclinatureResult
  declineJobAfterAccepting: ProJobDeclinatureResult
  declineOffer?: Maybe<OfferDeclinatureResult>
  deleteAdminReminder: DeleteAdminReminderResult
  deleteInvoiceFile: InvoiceFileDeleteResult
  deleteJobBookmark: Scalars['Boolean']['output']
  deleteJobFile: JobFileDeleteResult
  deleteOfferFile: OfferFileDeleteResult
  deleteProProfileDocument: ProProfileDocumentDeleteResult
  dismissNewCanceledJob: DismissNotReactedJobResult
  dismissNotReactedJob: DismissNotReactedJobResult
  generateChatMessageFileUploadLink: FileUploadResponse
  generateInvoiceFileUploadLink: FileUploadResponse
  generateJobFilePermanentUploadLink: FileUploadResponse
  generateJobFileUploadLink: FileUploadResponse
  generateOfferFileUploadLink: FileUploadResponse
  generateProProfileDocumentUploadLink: FileUploadResponse
  invitePro: Invite
  markAsNotReacted: AssignedPro
  /**
   * Changes the payment system of an invoice to 'external', removing any payment reminders, payout, receipt generation.
   * Invoice is also marked as paid if opts.isPaid is true
   * Service fee can be enabled/disable using opts.proServiceFeeEnabled and opts.clientServiceFeeEnabled
   */
  markInvoiceAsExternal: MarkInvoicesAsExternalResult
  /** Marks an invoice as fully paid (client total was received) */
  markInvoiceAsPaid: Invoice
  /** Mark a manual phone call payment reminder as done */
  markManualPhoneCallReminderAsDone: Invoice
  muteAllRemindersForPro: AdminPro
  muteClientReminders: AdminJob
  muteMetric: AdminJob
  /** Mutes all future payment reminders for a given invoice */
  mutePaymentReminders: MutePaymentRemindersResult
  prioritisePro: PrioritiseProResult
  publishOffer?: Maybe<PublishOfferResult>
  registerPro: ProRegisterResult
  registerProApplication?: Maybe<ProApplicationResult>
  removeRoomMember: RemoveRoomMemberResult
  reopenJob: JobReopenResult
  requestAdditionalOffer: RequestAdditionalOfferResult
  requestDamageJob: JobRequestResult
  requestEmergencyJob: EmergencyJobRequestResult
  /** Request a new job which creates a Job with default status of requested */
  requestJob: JobRequestResult
  requestJobForAgency: JobRequestResult
  requestJobForPreferredPro: JobRequestResult
  reviseInvoice: ReviseInvoiceResult
  rollbackOfferAcceptedToPublished: AssignedPro[]
  /** @deprecated accepted -> declined is now a valid transition, use declineJobForPro instead */
  rollbackProFromAcceptedToDeclined: AssignedPro
  rollbackProToNew: AssignedPro
  saveAdditionalInformation: AdminJob
  scheduleAdminReminderForJob: AdminReminder
  scheduleExecution: ProJobExecutionSchedulingResult
  scheduleVisit: ProJobVisitSchedulingResult
  sendMessage: Message
  sendMessageToAdmin?: Maybe<SendMessageToAdminResult>
  /** Sets de external fees collection date on an invoice */
  setExternalFeesCollected: AdminInvoice
  /** Sets the number of seats for first come first served jobs. 0 means the feature is disabled. */
  setFirstComeFirstServedSeatCount: AdminJob
  setFirstContactWasEstablished: ProFirstContactEstablishedResult
  setFirstContactWasUnresponsive: ProFirstContactUnresponsiveResult
  setOfferWasAcceptedOffline: OfferWasAcceptedOfflineResult
  setVisitNotNeeded: ProJobVisitNotNeededResult
  toggleFirstComeFirstServed: AdminJob
  toggleMuteRemindersForAssignedPro: AssignedPro
  /** @deprecated Use setFirstComeFirstServedSeatCount instead */
  toggleOverwriteReminderMuteForAssignedPro: AssignedPro
  triggerImportFromRemote: ImportTriggerResult
  unMuteAllRemindersForPro: AdminPro
  unmuteMetric: AdminJob
  updateJobFileKeys: Job
  updateOffer?: Maybe<SubmitOfferResult>
  /**
   * Updates the payment reminders for the given invoice.
   * This will only have an effect if the invoice is eligible for reminders (reminders not muted, not paid yet, etc.)
   * **Warning**: This will override an existing schedule
   * **Warning**: There can only be one reminder per name !
   */
  updatePaymentReminders: Invoice
  updatePro: ProUpdateResult
  updateProChatSettings: UpdateProChatSettingsResult
  /**
   * This mutation will mutate the pro location google place.
   * - When newGooglePlace is provided, it will overwrite the current value
   * - When newGooglePlace is omitted or set to null, the google place will be set to null
   * **Note**: This does not support partial updates. The entire 'googlePlace' field is updated.
   */
  updateProLocationGooglePlace: UpdateProLocationGooglePlaceResult
  updateProMarketingSettings: UpdateProMarketingSettingsResult
  updateProPayoutSettings: UpdateProPayoutSettingsResult
  updateProProfile: ProProfileUpdateResult
  updateProTopPartnerStatus: ProProfile
  /** **All** user mutations are grouped under the 'user' subgraph */
  user?: Maybe<UserMutations>
  validateOfferCost: Offer
  waitForMoreInfoFromClient: ProJobWaitingForClientResult
}

export type MutationAcceptJobArgs = {
  request: ProJobAcceptanceRequest
}

export type MutationAcceptOfferArgs = {
  id: Scalars['ID']['input']
}

export type MutationAddCommentToJobArgs = {
  comment: Scalars['String']['input']
  jobId: Scalars['ID']['input']
}

export type MutationAddCommentToProArgs = {
  comment: Scalars['String']['input']
  proId: Scalars['ID']['input']
}

export type MutationAddRoomMemberArgs = {
  capacity: Capacity
  roomId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
}

export type MutationAdminCancelJobArgs = {
  jobId: Scalars['ID']['input']
  reason: JobCancellationReason
  reasonText?: InputMaybe<Scalars['String']['input']>
}

export type MutationAdminDeclineJobFroProArgs = {
  jobId: Scalars['ID']['input']
  proId: Scalars['ID']['input']
  reason: AssignmentDeclineReason
  reasonText?: InputMaybe<Scalars['String']['input']>
}

export type MutationAdminDeleteJobFileArgs = {
  fileKey: Scalars['String']['input']
  jobId: Scalars['ID']['input']
}

export type MutationAdminUpdateJobBillingContactArgs = {
  adminJobUpdateInput: AdminJobUpdateInput
}

export type MutationAdminUpdateJobContactArgs = {
  adminJobUpdateInput: AdminJobUpdateInput
}

export type MutationAdminUpdateProArgs = {
  adminProUpdateRequest: AdminProUpdateRequest
}

export type MutationApproveInvoiceArgs = {
  invoiceId: Scalars['ID']['input']
  send: Scalars['Boolean']['input']
}

export type MutationArchiveOfferArgs = {
  id: Scalars['ID']['input']
}

export type MutationAssignAgentToJobArgs = {
  agentId: Scalars['ID']['input']
  jobId: Scalars['ID']['input']
}

export type MutationAssignJobToProsArgs = {
  request: AssignProsRequest
}

export type MutationBookmarkJobArgs = {
  jobId: Scalars['ID']['input']
}

export type MutationCancelJobArgs = {
  jobId: Scalars['ID']['input']
  reason: JobCancellationReason
  reasonText?: InputMaybe<Scalars['String']['input']>
}

export type MutationCedeInvoiceArgs = {
  invoiceId: Scalars['ID']['input']
}

export type MutationCheckoutSubscriptionPlanArgs = {
  planPriceId: Scalars['ID']['input']
  proId: Scalars['ID']['input']
  redirections: PlanCheckoutRedirections
}

export type MutationClearProPriorityArgs = {
  agentId: Scalars['ID']['input']
  proId: Scalars['ID']['input']
}

export type MutationCompleteJobArgs = {
  jobId: Scalars['ID']['input']
  opts?: InputMaybe<CompleteJobOptions>
}

export type MutationCreateInvoiceArgs = {
  invoice: InvoiceInput
  jobId: Scalars['ID']['input']
  proId: Scalars['ID']['input']
}

export type MutationCreateLeadArgs = {
  request: LeadCreationRequest
}

export type MutationCreateOfferArgs = {
  jobId: Scalars['ID']['input']
  offer: OfferInput
  proId: Scalars['ID']['input']
}

export type MutationDeclineJobArgs = {
  request: ProJobDeclinatureRequest
}

export type MutationDeclineJobAfterAcceptingArgs = {
  request: ProJobDeclinatureRequest
}

export type MutationDeclineOfferArgs = {
  id: Scalars['ID']['input']
  reason: OfferDeclineReasonInput
  reasonText?: InputMaybe<Scalars['String']['input']>
}

export type MutationDeleteAdminReminderArgs = {
  reminderId: Scalars['ID']['input']
}

export type MutationDeleteInvoiceFileArgs = {
  request: InvoiceFileDeleteRequest
}

export type MutationDeleteJobBookmarkArgs = {
  bookmarkId: Scalars['ID']['input']
}

export type MutationDeleteJobFileArgs = {
  request: JobFileDeleteRequest
}

export type MutationDeleteOfferFileArgs = {
  request: OfferFileDeleteRequest
}

export type MutationDeleteProProfileDocumentArgs = {
  fileKey: Scalars['String']['input']
  proId: Scalars['ID']['input']
}

export type MutationDismissNewCanceledJobArgs = {
  jobId: Scalars['ID']['input']
  proId: Scalars['ID']['input']
}

export type MutationDismissNotReactedJobArgs = {
  jobId: Scalars['ID']['input']
  proId: Scalars['ID']['input']
}

export type MutationGenerateChatMessageFileUploadLinkArgs = {
  file: FileUploadRequest
  roomId: Scalars['ID']['input']
}

export type MutationGenerateInvoiceFileUploadLinkArgs = {
  file: FileUploadRequest
  jobId: Scalars['ID']['input']
  proId: Scalars['ID']['input']
}

export type MutationGenerateJobFilePermanentUploadLinkArgs = {
  file: FileUploadRequest
  jobId: Scalars['ID']['input']
}

export type MutationGenerateJobFileUploadLinkArgs = {
  file: FileUploadRequest
}

export type MutationGenerateOfferFileUploadLinkArgs = {
  file: FileUploadRequest
  offerId: Scalars['ID']['input']
}

export type MutationGenerateProProfileDocumentUploadLinkArgs = {
  file: FileUploadRequest
  proId: Scalars['ID']['input']
}

export type MutationInviteProArgs = {
  agentId: Scalars['ID']['input']
  email: Scalars['String']['input']
  jobId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationMarkAsNotReactedArgs = {
  jobId: Scalars['ID']['input']
  proId: Scalars['ID']['input']
}

export type MutationMarkInvoiceAsExternalArgs = {
  invoiceId: Scalars['ID']['input']
  opts: MarkInvoiceAsExternalOptions
}

export type MutationMarkInvoiceAsPaidArgs = {
  invoiceId: Scalars['ID']['input']
}

export type MutationMarkManualPhoneCallReminderAsDoneArgs = {
  invoiceId: Scalars['ID']['input']
  reminderName: PaymentPhoneCallReminderName
}

export type MutationMuteAllRemindersForProArgs = {
  proId: Scalars['ID']['input']
}

export type MutationMuteClientRemindersArgs = {
  jobId: Scalars['ID']['input']
}

export type MutationMuteMetricArgs = {
  jobId: Scalars['ID']['input']
  metric: Scalars['String']['input']
  opts?: InputMaybe<MuteMetricOpts>
}

export type MutationMutePaymentRemindersArgs = {
  invoiceId: Scalars['ID']['input']
}

export type MutationPrioritiseProArgs = {
  agentId: Scalars['ID']['input']
  proId: Scalars['ID']['input']
}

export type MutationPublishOfferArgs = {
  id: Scalars['ID']['input']
}

export type MutationRegisterProArgs = {
  captchaToken: Scalars['String']['input']
  proRegisterRequest: ProRegisterRequest
}

export type MutationRegisterProApplicationArgs = {
  proApplicationRequest: ProApplicationRequest
}

export type MutationRemoveRoomMemberArgs = {
  roomId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
}

export type MutationReopenJobArgs = {
  jobId: Scalars['ID']['input']
  proId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationRequestAdditionalOfferArgs = {
  jobId: Scalars['ID']['input']
}

export type MutationRequestDamageJobArgs = {
  damage: DamageInput
  jobRequest: JobRequestInput
}

export type MutationRequestEmergencyJobArgs = {
  request: EmergencyJobRequestInput
}

export type MutationRequestJobArgs = {
  jobRequest: JobRequestInput
}

export type MutationRequestJobForAgencyArgs = {
  agencyJobRequestCode: Scalars['String']['input']
  jobRequest: JobRequestInput
}

export type MutationRequestJobForPreferredProArgs = {
  jobRequest: JobRequestInput
  proId: Scalars['ID']['input']
}

export type MutationReviseInvoiceArgs = {
  invoice: InvoiceInput
  invoiceId: Scalars['ID']['input']
  jobId: Scalars['ID']['input']
  proId: Scalars['ID']['input']
}

export type MutationRollbackOfferAcceptedToPublishedArgs = {
  jobId: Scalars['ID']['input']
}

export type MutationRollbackProFromAcceptedToDeclinedArgs = {
  jobId: Scalars['ID']['input']
  proId: Scalars['ID']['input']
  reason: AssignmentDeclineReason
  reasonText?: InputMaybe<Scalars['String']['input']>
}

export type MutationRollbackProToNewArgs = {
  jobId: Scalars['ID']['input']
  proId: Scalars['ID']['input']
}

export type MutationSaveAdditionalInformationArgs = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>
  jobId: Scalars['ID']['input']
}

export type MutationScheduleAdminReminderForJobArgs = {
  jobId: Scalars['ID']['input']
  note: Scalars['String']['input']
  scheduleAt: Scalars['DateTime']['input']
}

export type MutationScheduleExecutionArgs = {
  request: ProJobScheduleExecutionRequest
}

export type MutationScheduleVisitArgs = {
  request: ProJobScheduleVisitRequest
}

export type MutationSendMessageArgs = {
  input: MessageInput
}

export type MutationSendMessageToAdminArgs = {
  request: ProSendMessageToAdminRequest
}

export type MutationSetExternalFeesCollectedArgs = {
  collectedAt?: InputMaybe<Scalars['DateTime']['input']>
  invoiceId: Scalars['ID']['input']
}

export type MutationSetFirstComeFirstServedSeatCountArgs = {
  jobId: Scalars['ID']['input']
  seatCount: Scalars['Int']['input']
}

export type MutationSetFirstContactWasEstablishedArgs = {
  request: ProFirstContactEstablishedRequest
}

export type MutationSetFirstContactWasUnresponsiveArgs = {
  request: ProFirstContactUnresponsiveRequest
}

export type MutationSetOfferWasAcceptedOfflineArgs = {
  request: OfferWasAcceptedOfflineRequest
}

export type MutationSetVisitNotNeededArgs = {
  jobId: Scalars['ID']['input']
  proId: Scalars['ID']['input']
}

export type MutationToggleFirstComeFirstServedArgs = {
  firstComeFirstServedEnabled: Scalars['Boolean']['input']
  jobId: Scalars['ID']['input']
}

export type MutationToggleMuteRemindersForAssignedProArgs = {
  jobId: Scalars['ID']['input']
  mute: Scalars['Boolean']['input']
  proId: Scalars['ID']['input']
}

export type MutationToggleOverwriteReminderMuteForAssignedProArgs = {
  jobId: Scalars['ID']['input']
  proId: Scalars['ID']['input']
  value: Scalars['Boolean']['input']
}

export type MutationTriggerImportFromRemoteArgs = {
  type: ImportType
}

export type MutationUnMuteAllRemindersForProArgs = {
  proId: Scalars['ID']['input']
}

export type MutationUnmuteMetricArgs = {
  jobId: Scalars['ID']['input']
  metric: Scalars['String']['input']
}

export type MutationUpdateJobFileKeysArgs = {
  fileKeys?: InputMaybe<Scalars['String']['input'][]>
  jobId: Scalars['ID']['input']
}

export type MutationUpdateOfferArgs = {
  id: Scalars['ID']['input']
  offer: OfferInput
}

export type MutationUpdatePaymentRemindersArgs = {
  invoiceId: Scalars['ID']['input']
  paymentReminders: PaymentReminderInput[]
}

export type MutationUpdateProArgs = {
  proUpdateRequest: ProUpdateRequest
}

export type MutationUpdateProChatSettingsArgs = {
  input: UpdateProChatSettingsInput
  proId: Scalars['ID']['input']
}

export type MutationUpdateProLocationGooglePlaceArgs = {
  locationId: Scalars['ID']['input']
  newGooglePlace?: InputMaybe<AssociatedGooglePlaceInput>
  proId: Scalars['ID']['input']
}

export type MutationUpdateProMarketingSettingsArgs = {
  input: UpdateProMarketingSettingsInput
  proId: Scalars['ID']['input']
}

export type MutationUpdateProPayoutSettingsArgs = {
  input: UpdateProPayoutSettingsInput
  proId: Scalars['ID']['input']
}

export type MutationUpdateProProfileArgs = {
  proId: Scalars['ID']['input']
  updateProProfileRequest: ProUpdateProfileRequest
}

export type MutationUpdateProTopPartnerStatusArgs = {
  input: UpdateProStatusAsTopPartnerInput
  proId: Scalars['ID']['input']
}

export type MutationValidateOfferCostArgs = {
  damageCoverage: DamageCoverageInput
  offerId: Scalars['ID']['input']
}

export type MutationWaitForMoreInfoFromClientArgs = {
  jobId: Scalars['ID']['input']
  proId: Scalars['ID']['input']
  remindClientInDays?: InputMaybe<Scalars['Int']['input']>
}

export type MuteMetricOpts = {
  /**
   * Message to go along with the mute action
   * Will be propagated to the unmute event if "until" is defined
   */
  message?: InputMaybe<Scalars['String']['input']>
  /**
   * Date until the metric is muted, then it's automatically unmuted
   * If not defined, stays muted until it's manually unmuted
   */
  until?: InputMaybe<Scalars['DateTime']['input']>
}

export type MutePaymentRemindersInputError = Error & {
  __typename?: 'MutePaymentRemindersInputError'
  message: Scalars['String']['output']
}

export type MutePaymentRemindersResult = Invoice | MutePaymentRemindersInputError

export type Network = {
  __typename?: 'Network'
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type NewJobDetail = {
  __typename?: 'NewJobDetail'
  additionalInformation?: Maybe<Scalars['String']['output']>
  clientExpectation?: Maybe<ClientExpectation>
  damage?: Maybe<ProDamage>
  damageAgentExpectation?: Maybe<AgentExpectation>
  damageId?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  executionAddress?: Maybe<NewJobDetailAddress>
  executionDate: AssignedJobDetailExecutionDate
  fileKeys?: Maybe<Scalars['String']['output'][]>
  files?: Maybe<File[]>
  jobId: Scalars['String']['output']
  offering?: Maybe<JobOffering>
  submittedAt: Scalars['DateTime']['output']
  title?: Maybe<Scalars['String']['output']>
}

export type NewJobDetailAddress = {
  __typename?: 'NewJobDetailAddress'
  floor?: Maybe<Scalars['String']['output']>
  locality: Scalars['String']['output']
  ownership?: Maybe<Ownership>
  postalCode: Scalars['Int']['output']
}

export type NoReactionFromProAuditData = {
  __typename?: 'NoReactionFromProAuditData'
  assignment: AuditAssignment
  job: AuditJob
  pro: AuditPro
}

export type NoReactionFromProDismissedAuditData = {
  __typename?: 'NoReactionFromProDismissedAuditData'
  assignment: AuditAssignment
  job: AuditJob
  pro: AuditPro
}

export type Offer = {
  __typename?: 'Offer'
  clientServiceFeeEnabled: Scalars['Boolean']['output']
  contactPerson?: Maybe<PartialContactPerson>
  cost?: Maybe<OfferCost>
  costUpperLimit?: Maybe<OfferCost>
  createdAt: Scalars['DateTime']['output']
  damageCoverage?: Maybe<DamageCoverage>
  declineReason?: Maybe<OfferDeclineReason>
  declineReasonText?: Maybe<Scalars['String']['output']>
  /** Default VAT Rat used in cost calculation */
  defaultVatRate: Scalars['Float']['output']
  files: File[]
  hourlyCost?: Maybe<HourlyCost>
  id: Scalars['String']['output']
  jobId: Scalars['String']['output']
  messageToClient?: Maybe<Scalars['String']['output']>
  modifiedAt: Scalars['DateTime']['output']
  /** With offer related pro fee rate */
  proFeeRate: Scalars['Float']['output']
  proId: Scalars['String']['output']
  /** With offer related pro plan name */
  proPlanName: Scalars['String']['output']
  proServiceFeeEnabled: Scalars['Boolean']['output']
  publishedAt?: Maybe<Scalars['DateTime']['output']>
  status: OfferStatus
  title?: Maybe<Scalars['String']['output']>
  type: OfferType
}

export type OfferAcceptanceInputError = {
  __typename?: 'OfferAcceptanceInputError'
  message: Scalars['String']['output']
}

export type OfferAcceptanceResult = Offer | OfferAcceptanceInputError

export type OfferAcceptedAuditData = {
  __typename?: 'OfferAcceptedAuditData'
  job: AuditJob
  offer: AuditOffer
  pro: AuditPro
}

export type OfferAcceptedOfflineAuditData = {
  __typename?: 'OfferAcceptedOfflineAuditData'
  job: AuditJob
  pro: AuditPro
}

export type OfferArchivedAuditData = {
  __typename?: 'OfferArchivedAuditData'
  job: AuditJob
  pro: AuditPro
}

export type OfferCanceledAuditData = {
  __typename?: 'OfferCanceledAuditData'
  job: AuditJob
  offer: AuditOffer
  pro: AuditPro
}

export type OfferCost = {
  __typename?: 'OfferCost'
  /** Fee paid by the client, VAT excluded */
  clientFee: Scalars['Float']['output']
  clientServiceFeeEnabled: Scalars['Boolean']['output']
  clientServiceFeeVatRate: Scalars['Float']['output']
  /** Total of the offer, from the client's perspective, including cost, VAT and fee */
  clientTotal: Scalars['Float']['output']
  /** VAT on the client bill for the cost of the work and fee */
  clientVat: Scalars['Float']['output']
  /** Cost of the work, excluding VAT and fee */
  cost: Scalars['Float']['output']
  /** Fee billed to the pro, VAT excluded */
  proFee: Scalars['Float']['output']
  /** The pro related to this offer */
  proId: Scalars['String']['output']
  /** Net value the pro will receive: Total, including cost and VAT, minus the fee */
  proNet: Scalars['Float']['output']
  proServiceFeeEnabled: Scalars['Boolean']['output']
  proServiceFeeVatRate: Scalars['Float']['output']
  /** Total of the bill, from the pro's perspective, including cost, including VAT and excluding fee */
  proTotal: Scalars['Float']['output']
  /** VAT on the cost of the work */
  vat: Scalars['Float']['output']
  vatRate: Scalars['Float']['output']
}

export type OfferDeclinatureInputError = {
  __typename?: 'OfferDeclinatureInputError'
  message: Scalars['String']['output']
}

export type OfferDeclinatureResult = Offer | OfferDeclinatureInputError

export enum OfferDeclineReason {
  AnotherOfferAccepted = 'ANOTHER_OFFER_ACCEPTED',
  CompetitorOffer = 'COMPETITOR_OFFER',
  JobPostponed = 'JOB_POSTPONED',
  NotSatisfiedWithPro = 'NOT_SATISFIED_WITH_PRO',
  Other = 'OTHER',
  TooExpensive = 'TOO_EXPENSIVE',
}

export enum OfferDeclineReasonInput {
  CompetitorOffer = 'COMPETITOR_OFFER',
  JobPostponed = 'JOB_POSTPONED',
  NotSatisfiedWithPro = 'NOT_SATISFIED_WITH_PRO',
  Other = 'OTHER',
  TooExpensive = 'TOO_EXPENSIVE',
}

export type OfferDeclinedAuditData = {
  __typename?: 'OfferDeclinedAuditData'
  job: AuditJob
  offer: AuditOffer
  pro: AuditPro
  reason?: Maybe<OfferDeclineReason>
  reasonText?: Maybe<Scalars['String']['output']>
}

export type OfferFileDeleteRequest = {
  fileKey: Scalars['String']['input']
}

export type OfferFileDeleteResult = {
  __typename?: 'OfferFileDeleteResult'
  errorMessage?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type OfferInValidationAuditData = {
  __typename?: 'OfferInValidationAuditData'
  agent?: Maybe<AuditAgent>
  damage?: Maybe<AuditDamage>
  job: AuditJob
  offer: AuditOffer
  pro: AuditPro
}

export type OfferInput = {
  contactPerson?: InputMaybe<PartialContactPersonInput>
  cost?: InputMaybe<Scalars['Float']['input']>
  costUpperLimit?: InputMaybe<Scalars['Float']['input']>
  files?: InputMaybe<FileInput[]>
  /** Required when offer type is Hourly */
  hourlyCost?: InputMaybe<HourlyOfferCost>
  message?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  type: OfferType
  vat?: InputMaybe<Scalars['Float']['input']>
}

export type OfferPublishedAuditData = {
  __typename?: 'OfferPublishedAuditData'
  job: AuditJob
  offer: AuditOffer
  pro: AuditPro
}

export type OfferRequirements = {
  __typename?: 'OfferRequirements'
  /** When enabled, only offers with an exact cost (type=DEFAULT) will be accepted for this job. */
  requireExactCost: Scalars['Boolean']['output']
}

export enum OfferStatus {
  Accepted = 'ACCEPTED',
  Archived = 'ARCHIVED',
  Canceled = 'CANCELED',
  Declined = 'DECLINED',
  Draft = 'DRAFT',
  InValidation = 'IN_VALIDATION',
  JobCanceled = 'JOB_CANCELED',
  Published = 'PUBLISHED',
}

export enum OfferType {
  /** Exact price */
  Default = 'DEFAULT',
  /** Estimation / Price range */
  Estimation = 'ESTIMATION',
  /** Price by the hour */
  Hourly = 'HOURLY',
}

export type OfferWasAcceptedOfflineInputError = {
  __typename?: 'OfferWasAcceptedOfflineInputError'
  _empty?: Maybe<Scalars['String']['output']>
}

export type OfferWasAcceptedOfflineRequest = {
  jobId: Scalars['ID']['input']
  proId: Scalars['ID']['input']
}

export type OfferWasAcceptedOfflineResult = AssignedAcceptedJob | OfferWasAcceptedOfflineInputError

export type Offering = {
  __typename?: 'Offering'
  areIntentionsEnabled: Scalars['Boolean']['output']
  categories?: Maybe<Category[]>
  executionAddressEnabled: Scalars['Boolean']['output']
  executionDates: ExpectedExecution[]
  fromPrice?: Maybe<Scalars['Float']['output']>
  icon?: Maybe<Icon>
  id: Scalars['ID']['output']
  intentions?: Maybe<Intention[]>
  label: Scalars['String']['output']
  /** Questions that are NOT linked to an Intention. */
  questions?: Maybe<Question[]>
  showClientExpectation: Scalars['Boolean']['output']
  siteVisitEnabled: Scalars['Boolean']['output']
  slug?: Maybe<Scalars['String']['output']>
  status: OfferingStatus
  type: OfferingType
}

export type OfferingQuestionsArgs = {
  filterBy?: InputMaybe<QuestionFilter>
  orderBy?: InputMaybe<QuestionOrder>
}

export type OfferingFilters = {
  status?: InputMaybe<OfferingStatus>
}

export type OfferingSearchItem = {
  __typename?: 'OfferingSearchItem'
  fromPrice?: Maybe<Scalars['Float']['output']>
  icon?: Maybe<Icon>
  id: Scalars['ID']['output']
  label: Scalars['String']['output']
  slug?: Maybe<Scalars['String']['output']>
  type: OfferingType
}

export enum OfferingStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

export enum OfferingType {
  MinimumPrice = 'MINIMUM_PRICE',
  OfferBased = 'OFFER_BASED',
  PriceRange = 'PRICE_RANGE',
}

export type OfferingsOrder = {
  label?: InputMaybe<Ordering>
}

export enum Ordering {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type OtherJobsFilter = {
  overdue?: InputMaybe<Scalars['Boolean']['input']>
}

export enum Ownership {
  Homeowner = 'HOMEOWNER',
  Tenant = 'TENANT',
}

export type Page = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type ParquetMaintenanceStaticJobQuestions = {
  __typename?: 'ParquetMaintenanceStaticJobQuestions'
  age?: Maybe<Scalars['Int']['output']>
  areSkirtingBoardsGlued?: Maybe<Scalars['Boolean']['output']>
  areThereObstaclesOnTheFloor?: Maybe<Scalars['Boolean']['output']>
  condition: ParquetMaintenance_ParquetCondition
  hasBeenSandedBefore?: Maybe<Scalars['Boolean']['output']>
  material?: Maybe<Scalars['String']['output']>
  sizeOfFloor: Scalars['Int']['output']
  skirtingBoardLength: Scalars['Int']['output']
}

export type ParquetMaintenanceStaticJobQuestionsInput = {
  age?: InputMaybe<Scalars['Int']['input']>
  areSkirtingBoardsGlued?: InputMaybe<Scalars['Boolean']['input']>
  areThereObstaclesOnTheFloor?: InputMaybe<Scalars['Boolean']['input']>
  condition: ParquetMaintenance_ParquetCondition
  hasBeenSandedBefore?: InputMaybe<Scalars['Boolean']['input']>
  material?: InputMaybe<Scalars['String']['input']>
  sizeOfFloor: Scalars['Int']['input']
  skirtingBoardLength: Scalars['Int']['input']
}

export enum ParquetMaintenance_ParquetCondition {
  HeavyWearAndTear = 'HEAVY_WEAR_AND_TEAR',
  NormalWearAndTear = 'NORMAL_WEAR_AND_TEAR',
  WaterDamage = 'WATER_DAMAGE',
}

export type PartialAddress = {
  __typename?: 'PartialAddress'
  floor?: Maybe<Scalars['String']['output']>
  locality?: Maybe<Scalars['String']['output']>
  postalCode?: Maybe<Scalars['Int']['output']>
  streetAndHouseNumber?: Maybe<Scalars['String']['output']>
}

export type PartialAddressInput = {
  floor?: InputMaybe<Scalars['String']['input']>
  locality?: InputMaybe<Scalars['String']['input']>
  postalCode?: InputMaybe<Scalars['Int']['input']>
  streetAndHouseNumber?: InputMaybe<Scalars['String']['input']>
}

export type PartialContactPerson = {
  __typename?: 'PartialContactPerson'
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
  phoneNumber?: Maybe<Scalars['String']['output']>
  salutation?: Maybe<Salutation>
}

export type PartialContactPersonInput = {
  email?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  salutation?: InputMaybe<Salutation>
}

export type Payback = {
  __typename?: 'Payback'
  bexioInvoiceId?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  paidAt?: Maybe<Scalars['DateTime']['output']>
  pro: Pro
  proId: Scalars['ID']['output']
  relatedInvoiceIds: Scalars['String']['output'][]
  relatedInvoices: PaybackRelatedInvoice[]
  sentAt?: Maybe<Scalars['DateTime']['output']>
  status: PaybackStatus
}

export type PaybackOrder = {
  createdAt?: InputMaybe<Ordering>
}

export type PaybackRelatedInvoice = {
  __typename?: 'PaybackRelatedInvoice'
  clientFeeIncVat: Scalars['Float']['output']
  invoice: Invoice
  invoiceId: Scalars['ID']['output']
  job: Job
  proFeeIncVat: Scalars['Float']['output']
  proId: Scalars['ID']['output']
}

export type PaybackSearchCriteria = {
  month?: InputMaybe<Scalars['Int']['input']>
  searchText?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<PaybackStatus>
  year?: InputMaybe<Scalars['Int']['input']>
}

export type PaybackSearchResult = {
  __typename?: 'PaybackSearchResult'
  data: Payback[]
  total: Scalars['Int']['output']
}

export type PaybackSearchResultItem = {
  __typename?: 'PaybackSearchResultItem'
  payback: Payback
}

export enum PaybackStatus {
  Draft = 'DRAFT',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  Sent = 'SENT',
}

export enum PaymentPhoneCallReminderName {
  PhoneCallReminder_1 = 'PHONE_CALL_REMINDER_1',
  PhoneCallReminder_2 = 'PHONE_CALL_REMINDER_2',
}

export type PaymentReminder = {
  __typename?: 'PaymentReminder'
  done: Scalars['Boolean']['output']
  doneAt?: Maybe<Scalars['DateTime']['output']>
  name: PaymentReminderName
  scheduledFor: Scalars['DateTime']['output']
  type: PaymentReminderType
}

export type PaymentReminderInput = {
  name: PaymentReminderName
  scheduledFor: Scalars['DateTime']['input']
}

export type PaymentReminderMutedAuditData = {
  __typename?: 'PaymentReminderMutedAuditData'
  invoice: AuditInvoice
  job: AuditJob
}

export enum PaymentReminderName {
  BuildigoFriendly_1 = 'BUILDIGO_FRIENDLY_1',
  BuildigoFriendly_2 = 'BUILDIGO_FRIENDLY_2',
  BuildigoFriendlyEarly = 'BUILDIGO_FRIENDLY_EARLY',
  BuildigoOfficial = 'BUILDIGO_OFFICIAL',
  PayrexxReminder = 'PAYREXX_REMINDER',
  PayrexxReminderFriendly = 'PAYREXX_REMINDER_FRIENDLY',
  PhoneCallReminder_1 = 'PHONE_CALL_REMINDER_1',
  PhoneCallReminder_2 = 'PHONE_CALL_REMINDER_2',
}

export enum PaymentReminderType {
  AutomaticEmail = 'AUTOMATIC_EMAIL',
  ManualPhoneCall = 'MANUAL_PHONE_CALL',
  Payrexx = 'PAYREXX',
}

export type PaymentReminders = {
  __typename?: 'PaymentReminders'
  allReminders: PaymentReminder[]
  mostRecentlyDoneReminder?: Maybe<PaymentReminder>
  muted: Scalars['Boolean']['output']
  nextReminder?: Maybe<PaymentReminder>
}

export type PaymentRemindersUpdatedAuditData = {
  __typename?: 'PaymentRemindersUpdatedAuditData'
  invoice: AuditInvoice
  paymentReminders: Scalars['JSON']['output']
}

export enum PaymentSystem {
  Bexio = 'BEXIO',
  Damage = 'DAMAGE',
  External = 'EXTERNAL',
  Payrexx = 'PAYREXX',
}

/**
 * The Payout interface is is the common representation for payouts between pros and admins.
 *
 * As of now it is not exposed to pro's yet, but it is already here to make sure it's field don't get bloated with admin specific things.
 */
export type Payout = {
  actions: PayoutActions[]
  amount: Scalars['Int']['output']
  dueDate: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  status: PayoutStatus
}

export enum PayoutActions {
  Approve = 'APPROVE',
  BackToDraft = 'BACK_TO_DRAFT',
  MarkAsPaid = 'MARK_AS_PAID',
  MarkAsPartial = 'MARK_AS_PARTIAL',
}

export type PayoutApprovedAuditData = {
  __typename?: 'PayoutApprovedAuditData'
  payout: AuditPayout
}

export type PayoutCreatedAuditData = {
  __typename?: 'PayoutCreatedAuditData'
  payout: AuditPayout
}

export type PayoutDeletedAuditData = {
  __typename?: 'PayoutDeletedAuditData'
  payout: AuditPayout
}

export type PayoutPaidAuditData = {
  __typename?: 'PayoutPaidAuditData'
  payout: AuditPayout
}

export type PayoutPartiallyPaidAuditData = {
  __typename?: 'PayoutPartiallyPaidAuditData'
  payout: AuditPayout
}

export enum PayoutStatus {
  Draft = 'DRAFT',
  Paid = 'PAID',
  Partial = 'PARTIAL',
  Pending = 'PENDING',
}

export type PayoutUnapprovedAuditData = {
  __typename?: 'PayoutUnapprovedAuditData'
  payout: AuditPayout
}

export type PayoutUpdateReceivedAuditData = {
  __typename?: 'PayoutUpdateReceivedAuditData'
  paymentSystem: Scalars['String']['output']
  update: Scalars['JSON']['output']
}

export type Place = {
  __typename?: 'Place'
  locality: Scalars['String']['output']
  postalCode: Scalars['Int']['output']
}

export type PlanChangeScheduled = {
  __typename?: 'PlanChangeScheduled'
  planPriceId: Scalars['ID']['output']
}

/**
 * Error thrown when checking out a plan is not possible because of feature compliance.
 * This means at least one feature would be overflown when the plan is changed
 * For instance, the number of users must be reduced first
 */
export type PlanCheckoutFeatureComplianceError = {
  __typename?: 'PlanCheckoutFeatureComplianceError'
  /** Users with a role that is not supported by the new plan */
  incompatibleRoleError: PlanCheckoutRoleComplianceError
  message: Scalars['String']['output']
  numLocationOverflow: Scalars['Int']['output']
  numUserOverflow: Scalars['Int']['output']
}

export type PlanCheckoutLink = {
  __typename?: 'PlanCheckoutLink'
  expireAt?: Maybe<Scalars['DateTime']['output']>
  url: Scalars['String']['output']
}

export type PlanCheckoutRedirections = {
  cancelUrl: Scalars['String']['input']
  successUrl: Scalars['String']['input']
}

export type PlanCheckoutRoleComplianceError = {
  __typename?: 'PlanCheckoutRoleComplianceError'
  allowedRole: ProRole
  numUserWithIncompatibleRole: Scalars['Int']['output']
}

export type PlanCoupon = {
  __typename?: 'PlanCoupon'
  discount: Scalars['Int']['output']
  id: Scalars['ID']['output']
}

export type PlatformSearchItem = ArticleSearchItem | OfferingSearchItem | ProDirectorySearchItem

export type PostalCode = {
  __typename?: 'PostalCode'
  canton: Scalars['String']['output']
  id: Scalars['String']['output']
  postalDistrict: Scalars['String']['output']
  zip: Scalars['Int']['output']
}

export type PriceBasedVoucherTerm = {
  __typename?: 'PriceBasedVoucherTerm'
  /** Discount applied to the invoice, in rappen */
  discount: Scalars['Float']['output']
  /** Minimum amount of money (in rappen) to spend for the discount to be applied */
  from: Scalars['Float']['output']
}

export type PriceRange = {
  __typename?: 'PriceRange'
  /**
   * When there are certain unknowns in the calculation the price range could be inaccurate which could for example
   * mean additional consultation with the craftsman is necessary.
   */
  containUnknowns: Scalars['Boolean']['output']
  /** Certain price range jobs are free of charge */
  freeOfCharge: Scalars['Boolean']['output']
  fromAmount: Scalars['Float']['output']
  /** A breakdown of the price range expressed in percentage */
  percentageBreakdown: PriceRangePercentageBreakdown
  toAmount: Scalars['Float']['output']
}

export type PriceRangePercentageBreakdown = {
  __typename?: 'PriceRangePercentageBreakdown'
  additionalCost?: Maybe<Scalars['Float']['output']>
  buildigoFee: Scalars['Float']['output']
  materialCost?: Maybe<Scalars['Float']['output']>
  serviceCosts: Scalars['Float']['output']
}

export type PrioritiseProResult = {
  __typename?: 'PrioritiseProResult'
  prioritised: Scalars['Boolean']['output']
}

export type Pro = {
  __typename?: 'Pro'
  /** Possible actions a the requesting user is allowed to execute based on the permission and the state of the pro */
  actions?: Maybe<ProUpdateAction[]>
  additionallySpokenLanguages: ProSpokenLanguage[]
  agent?: Maybe<Agent>
  agentId?: Maybe<Scalars['String']['output']>
  /**
   * Are reminders for this pro muted globally?
   *
   * Weekly Summary is still sent.
   */
  areRemindersMuted: Scalars['Boolean']['output']
  /**
   * All jobs that the pro has completed
   * A job is completed once its status is COMPLETED
   *
   * **Role**: Requires to own the pro
   */
  assignedCompletedJobs?: Maybe<AssignedAcceptedJob[]>
  /**
   * All jobs requests that the pro has selected “Interested” but that aren’t done yet
   * A job is "done" when it is entirely paid for (NOT YET IMPLEMENTED --> won jobs will stay in progress)
   *
   * **Role**: Requires to own the pro
   */
  assignedInProgressJobs?: Maybe<AssignedAcceptedJob[]>
  /**
   * All jobs assigned to this pro
   * **Role**: Requires to own the pro
   */
  assignedJobs?: Maybe<AssignedJob[]>
  /**
   * All jobs requests that the pro has lost
   * A job is "lost" when another pro was chosen or canceled
   *
   * **Role**: Requires to own the pro
   */
  assignedLostJobs?: Maybe<AssignedAcceptedJob[]>
  /**
   * All new job requests assigned to this pro
   * A "new" job is when a pro has not yet accepted/declined
   *
   *
   * **Role**: Requires to own the pro
   */
  assignedNewJobs?: Maybe<AssignedNewJob[]>
  associations: ProAssociation[]
  chatSettings: ProChatSettings
  companyName: Scalars['String']['output']
  /** Company representative */
  contactPerson: ContactPerson
  createdAt: Scalars['DateTime']['output']
  emergencyContact?: Maybe<EmergencyContact>
  hasSubsidiaryToRegister: Scalars['Boolean']['output']
  id: Scalars['String']['output']
  industries?: Maybe<Industry[]>
  isDirectoryListingEnabled: Scalars['Boolean']['output']
  isMobiliarCustomer: Scalars['Boolean']['output']
  isProfilePublished?: Maybe<PublishedStatus>
  language: Scalars['String']['output']
  locations: ProLocation[]
  merchant: ProMerchant
  mobiliarPNumber?: Maybe<Scalars['String']['output']>
  networkIds: Scalars['Int']['output'][]
  networks: Network[]
  otherAssociations?: Maybe<Scalars['String']['output']>
  otherCompetences?: Maybe<Scalars['String']['output']>
  registrationSource: ProRegistrationSource
  services?: Maybe<Service[]>
  skills?: Maybe<Skill[]>
  slug: Scalars['String']['output']
  status: ProStatus
  /**
   * Active subscription for the pro
   *
   * **Role**: Requires to be one of the pro admin user or global admin
   */
  subscription: ProSubscription
  website?: Maybe<Scalars['String']['output']>
}

export type ProAssignedJobsArgs = {
  filterByStatus?: InputMaybe<AssignmentStatus>
}

export type ProLocationsArgs = {
  filterByStatus?: InputMaybe<ProLocationStatus>
}

export type ProAgency = {
  __typename?: 'ProAgency'
  locality?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  type: ProAgencyType
}

export enum ProAgencyType {
  Mobiliar = 'MOBILIAR',
}

export type ProAgentPrioritisation = {
  __typename?: 'ProAgentPrioritisation'
  agent?: Maybe<Agent>
  agentId?: Maybe<Scalars['ID']['output']>
  prioritised: Scalars['Boolean']['output']
}

export type ProAnotherProChosenAuditData = {
  __typename?: 'ProAnotherProChosenAuditData'
  job: ProAuditJob
  pro: ProAuditPro
}

export type ProApplication = {
  __typename?: 'ProApplication'
  companyName: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  hasLiabilityInsurance: Scalars['Boolean']['output']
  id: Scalars['String']['output']
  isMobiliarCustomer: Scalars['Boolean']['output']
  locality: Scalars['String']['output']
  mainContactPerson: ContactPerson
}

export type ProApplicationRequest = {
  companyName: Scalars['String']['input']
  hasLiabilityInsurance: Scalars['Boolean']['input']
  isMobiliarCustomer: Scalars['Boolean']['input']
  locality: Scalars['String']['input']
  mainContactPerson: ContactPersonInput
  subscription?: InputMaybe<ProApplicationSubscription>
}

export type ProApplicationResult = ProApplication | ProRegisterRequestUserInputError

export type ProApplicationSubscription = {
  period: Scalars['String']['input']
  planId: Scalars['String']['input']
}

export enum ProAssociation {
  Bodenschweiz = 'BODENSCHWEIZ',
  Eit = 'EIT',
  Jardin = 'JARDIN',
  Kuecheschweiz = 'KUECHESCHWEIZ',
  Other = 'OTHER',
  Smvg = 'SMVG',
  Suissetec = 'SUISSETEC',
  Vssm = 'VSSM',
}

export type ProAuditData =
  | ProAnotherProChosenAuditData
  | ProExecutionDateScheduledAuditData
  | ProFirstContactEstablishedAuditData
  | ProFirstContactUnresponsiveAuditData
  | ProInvoiceCancelledAuditData
  | ProInvoiceCreatedAuditData
  | ProInvoicePaidAuditData
  | ProJobAcceptedAuditData
  | ProJobAssignedAuditData
  | ProJobCancelledAuditData
  | ProJobDeclinedAuditData
  | ProMessageSentToAdminFromProAuditData
  | ProNoReactionFromProAuditData
  | ProOfferAcceptedAuditData
  | ProOfferAcceptedOfflineAuditData
  | ProOfferCanceledAuditData
  | ProOfferDeclinedAuditData
  | ProOfferInValidationAuditData
  | ProOfferPublishedAuditData
  | ProRollbackDeclinedAuditData
  | ProRollbackNoReactionAuditData
  | ProSiteVisitNotNeededAuditData
  | ProSiteVisitScheduledAuditData
  | ProWaitingForClientAuditData

export type ProAuditEvent = {
  __typename?: 'ProAuditEvent'
  createdAt: Scalars['DateTime']['output']
  data?: Maybe<ProAuditData>
  id: Scalars['String']['output']
  type: AuditEventType
}

export type ProAuditInvoice = {
  __typename?: 'ProAuditInvoice'
  discount?: Maybe<Scalars['Float']['output']>
  dueDays: Scalars['Int']['output']
  id: Scalars['String']['output']
  invoiceAmount: Scalars['Float']['output']
  message?: Maybe<Scalars['String']['output']>
}

export type ProAuditJob = {
  __typename?: 'ProAuditJob'
  id: Scalars['String']['output']
}

export type ProAuditOffer = {
  __typename?: 'ProAuditOffer'
  cost?: Maybe<Scalars['Float']['output']>
  costUpperLimit?: Maybe<Scalars['Float']['output']>
  hourlyCost?: Maybe<HourlyCost>
  id: Scalars['String']['output']
  messageToClient?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  type: OfferType
  vat?: Maybe<Scalars['Float']['output']>
}

export type ProAuditPro = {
  __typename?: 'ProAuditPro'
  companyName: Scalars['String']['output']
  id: Scalars['String']['output']
}

export type ProAuditSiteVisit = {
  __typename?: 'ProAuditSiteVisit'
  date: Scalars['DateTime']['output']
  fromTime: Scalars['String']['output']
  toTime?: Maybe<Scalars['String']['output']>
}

export type ProAuditUser = {
  __typename?: 'ProAuditUser'
  email?: Maybe<Scalars['String']['output']>
  firstName?: Maybe<Scalars['String']['output']>
  id?: Maybe<Scalars['String']['output']>
  lastName?: Maybe<Scalars['String']['output']>
}

export type ProChatSettings = {
  __typename?: 'ProChatSettings'
  default: UpdateProChatSettingsMembers
  orphaned: UpdateProChatSettingsMembers
}

export type ProDamage = {
  __typename?: 'ProDamage'
  agencyId: Scalars['ID']['output']
  agencyName?: Maybe<Scalars['String']['output']>
  agent?: Maybe<ProDamageAgent>
  agentId: Scalars['ID']['output']
  caseNumber: Scalars['String']['output']
  id: Scalars['ID']['output']
  occurredAt: Scalars['DateTime']['output']
}

export type ProDamageAgent = {
  __typename?: 'ProDamageAgent'
  email?: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  phoneNumber?: Maybe<Scalars['String']['output']>
}

export type ProDirectorySearchItem = {
  __typename?: 'ProDirectorySearchItem'
  additionallySpokenLanguages: ProSpokenLanguage[]
  associations: ProAssociation[]
  companyName: Scalars['String']['output']
  coverFileKey?: Maybe<Scalars['String']['output']>
  coverImage?: Maybe<Image>
  description?: Maybe<Scalars['String']['output']>
  galleryFileKeys: Scalars['String']['output'][]
  galleryImages: Image[]
  id: Scalars['ID']['output']
  industries: IndustrySearchItem[]
  isBuildigoVerified: Scalars['Boolean']['output']
  isErneuerbarheizenNetwork: Scalars['Boolean']['output']
  isMobiliarNetwork: Scalars['Boolean']['output']
  isProfilePublished: ProProfilePublishStatus
  language: Scalars['String']['output']
  locations: SearchLocation[]
  logoFileKey?: Maybe<Scalars['String']['output']>
  logoImage?: Maybe<Image>
  numApprentices: Scalars['Int']['output']
  numEmployees: Scalars['Int']['output']
  otherAssociations?: Maybe<Scalars['String']['output']>
  recommendingAgency?: Maybe<ProAgency>
  responseTimeInHours?: Maybe<Scalars['Int']['output']>
  slug: Scalars['String']['output']
  yearsAsTopPartner?: Maybe<Scalars['Int']['output'][]>
}

export type ProDirectorySearchResult = {
  __typename?: 'ProDirectorySearchResult'
  items: ProDirectorySearchItem[]
  total: Scalars['Int']['output']
}

export type ProExecutionDateScheduledAuditData = {
  __typename?: 'ProExecutionDateScheduledAuditData'
  dates: ProExecutionScheduledDate[]
  job: ProAuditJob
  pro: ProAuditPro
}

export type ProExecutionScheduledDate = {
  __typename?: 'ProExecutionScheduledDate'
  date: Scalars['DateTime']['output']
  fromTime: Scalars['String']['output']
  toTime?: Maybe<Scalars['String']['output']>
}

export type ProFirstContactEstablishedAuditData = {
  __typename?: 'ProFirstContactEstablishedAuditData'
  job: ProAuditJob
  pro: ProAuditPro
}

export type ProFirstContactEstablishedInputError = {
  __typename?: 'ProFirstContactEstablishedInputError'
  _empty?: Maybe<Scalars['String']['output']>
}

export type ProFirstContactEstablishedRequest = {
  jobId: Scalars['String']['input']
  proId: Scalars['String']['input']
}

export type ProFirstContactEstablishedResult = AssignedAcceptedJob | ProFirstContactEstablishedInputError

export type ProFirstContactUnresponsiveAuditData = {
  __typename?: 'ProFirstContactUnresponsiveAuditData'
  job: ProAuditJob
  pro: ProAuditPro
}

export type ProFirstContactUnresponsiveInputError = {
  __typename?: 'ProFirstContactUnresponsiveInputError'
  _empty?: Maybe<Scalars['String']['output']>
}

export type ProFirstContactUnresponsiveRequest = {
  jobId: Scalars['String']['input']
  proId: Scalars['String']['input']
}

export type ProFirstContactUnresponsiveResult = AssignedAcceptedJob | ProFirstContactUnresponsiveInputError

export type ProInvoiceCancelledAuditData = {
  __typename?: 'ProInvoiceCancelledAuditData'
  invoice: ProAuditInvoice
}

export type ProInvoiceCreatedAuditData = {
  __typename?: 'ProInvoiceCreatedAuditData'
  invoice: ProAuditInvoice
  job: ProAuditJob
  pro: ProAuditPro
}

export type ProInvoicePaidAuditData = {
  __typename?: 'ProInvoicePaidAuditData'
  invoice: ProAuditInvoice
  job: ProAuditJob
}

export type ProInvoiceSearchResult = {
  __typename?: 'ProInvoiceSearchResult'
  data: ProInvoiceSearchResultItem[]
  total: Scalars['Int']['output']
}

export type ProInvoiceSearchResultItem = {
  __typename?: 'ProInvoiceSearchResultItem'
  invoice: Invoice
  job: Job
}

export type ProInvoiceSearchResultItemInvoiceArgs = {
  perspective?: InputMaybe<InvoicePerspective>
}

export enum ProJobAcceptanceError {
  AnotherProChosen = 'ANOTHER_PRO_CHOSEN',
  JobCanceled = 'JOB_CANCELED',
}

export type ProJobAcceptanceInputError = {
  __typename?: 'ProJobAcceptanceInputError'
  error?: Maybe<ProJobAcceptanceError>
  message: Scalars['String']['output']
}

export type ProJobAcceptanceRequest = {
  jobId: Scalars['String']['input']
  proId: Scalars['String']['input']
}

export type ProJobAcceptanceResult = AssignedAcceptedJob | ProJobAcceptanceInputError | ProMonthlyJobLimitExceeded

export type ProJobAcceptedAuditData = {
  __typename?: 'ProJobAcceptedAuditData'
  job: ProAuditJob
  pro: ProAuditPro
}

export type ProJobAssignedAuditData = {
  __typename?: 'ProJobAssignedAuditData'
  job: ProAuditJob
}

export type ProJobCancelledAuditData = {
  __typename?: 'ProJobCancelledAuditData'
  reason?: Maybe<JobCancellationReason>
  reasonText?: Maybe<Scalars['String']['output']>
}

export type ProJobCounts = {
  __typename?: 'ProJobCounts'
  accepted: Scalars['Int']['output']
  assigned: Scalars['Int']['output']
  declined: Scalars['Int']['output']
  won: Scalars['Int']['output']
}

export enum ProJobDeclinatureError {
  AnotherProChosen = 'ANOTHER_PRO_CHOSEN',
  JobCanceled = 'JOB_CANCELED',
}

export type ProJobDeclinatureInputError = {
  __typename?: 'ProJobDeclinatureInputError'
  error?: Maybe<ProJobDeclinatureError>
  message: Scalars['String']['output']
}

export type ProJobDeclinatureRequest = {
  declineReason: AssignmentDeclineReason
  declineReasonText?: InputMaybe<Scalars['String']['input']>
  jobId: Scalars['String']['input']
  proId: Scalars['String']['input']
}

export type ProJobDeclinatureResult = AssignedNewJob | ProJobDeclinatureInputError

export type ProJobDeclinedAuditData = {
  __typename?: 'ProJobDeclinedAuditData'
  job: ProAuditJob
  pro: ProAuditPro
  reason?: Maybe<AssignmentDeclineReason>
  reasonText?: Maybe<Scalars['String']['output']>
}

export type ProJobExecutionSchedulingInputError = Error & {
  __typename?: 'ProJobExecutionSchedulingInputError'
  error: ScheduleExecutionUserError
  message: Scalars['String']['output']
}

export type ProJobExecutionSchedulingResult = AssignedAcceptedJob | ProJobExecutionSchedulingInputError

export type ProJobInvolvment = {
  __typename?: 'ProJobInvolvment'
  job: AdminJob
  status: AssignmentStatus
}

export type ProJobScheduleExecutionRequest = {
  dates: ScheduledExecutionDateInput[]
  jobId: Scalars['String']['input']
  proId: Scalars['String']['input']
}

export type ProJobScheduleVisitRequest = {
  date: Scalars['DateTime']['input']
  fromTime: Scalars['String']['input']
  jobId: Scalars['String']['input']
  proId: Scalars['String']['input']
  toTime?: InputMaybe<Scalars['String']['input']>
}

export type ProJobSchedulingInputError = {
  __typename?: 'ProJobSchedulingInputError'
  message: Scalars['String']['output']
}

export type ProJobVisitNotNeededResult = AssignedAcceptedJob | ProJobSchedulingInputError

export type ProJobVisitSchedulingResult = AssignedAcceptedJob | ProJobSchedulingInputError

export type ProJobWaitingForClientResult = AssignedAcceptedJob | ProJobSchedulingInputError

export type ProLocation = {
  __typename?: 'ProLocation'
  address: BaseAddress
  default: Scalars['Boolean']['output']
  email?: Maybe<Scalars['String']['output']>
  googlePlace?: Maybe<AssociatedGooglePlace>
  id: Scalars['ID']['output']
  phoneNumber: Scalars['String']['output']
  representativePerson?: Maybe<ProLocationRepresentative>
  status: ProLocationStatus
}

export type ProLocationFilter = {
  status?: InputMaybe<ProLocationStatus>
}

export type ProLocationInput = {
  address: AddressInput
  email?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  phoneNumber: Scalars['String']['input']
  representativePerson?: InputMaybe<ProLocationRepresentativeInput>
  status: ProLocationStatus
}

export type ProLocationRepresentative = {
  __typename?: 'ProLocationRepresentative'
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  role?: Maybe<Scalars['String']['output']>
}

export type ProLocationRepresentativeInput = {
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  role?: InputMaybe<Scalars['String']['input']>
}

export type ProLocationSearchFilter = {
  industryIds?: InputMaybe<Scalars['String']['input'][]>
  locationStatus?: InputMaybe<ProLocationStatus[]>
}

export type ProLocationSearchOrder = {
  /**
   * Provide a job ID to sort by distance (a combination of drive time and distance) from the execution address of a job
   * The backend will decide if drive time can be calculated or will fallback to distance based on geometry data
   */
  distanceToJob?: InputMaybe<Scalars['String']['input']>
}

export type ProLocationSearchResult = {
  __typename?: 'ProLocationSearchResult'
  data: ProLocationSearchResultItem[]
  total: Scalars['Int']['output']
}

export type ProLocationSearchResultItem = {
  __typename?: 'ProLocationSearchResultItem'
  distance?: Maybe<DestinationDistance>
  location: LocationSearchResult
  pro: ProSearchResultItem
}

export enum ProLocationStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type ProMerchant = {
  __typename?: 'ProMerchant'
  bankAccountHolder?: Maybe<Scalars['String']['output']>
  bankAccountIban?: Maybe<Scalars['String']['output']>
  /** This is the Swiss VAT or UID (Unternehmens-Identifikationsnummer) */
  businessIdentificationNumber?: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  hasLiabilityInsurance: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  isSubjectToVAT: Scalars['Boolean']['output']
  liabilityInsurance?: Maybe<Scalars['String']['output']>
  liabilityInsuranceExpirationDate?: Maybe<Scalars['DateTime']['output']>
  proId: Scalars['ID']['output']
}

export enum ProMerchantUpdateAction {
  CreatePaymentAccount = 'CREATE_PAYMENT_ACCOUNT',
}

export type ProMessageSentToAdminFromProAuditData = {
  __typename?: 'ProMessageSentToAdminFromProAuditData'
  job: ProAuditJob
  message: Scalars['String']['output']
  pro: ProAuditPro
}

export type ProMetrics = {
  __typename?: 'ProMetrics'
  jobCounts: ProJobCounts
  turnOver: Scalars['Float']['output']
}

export type ProMonthlyJobLimitExceeded = {
  __typename?: 'ProMonthlyJobLimitExceeded'
  message: Scalars['String']['output']
  monthlyLimit: Scalars['Int']['output']
}

export type ProNoReactionFromProAuditData = {
  __typename?: 'ProNoReactionFromProAuditData'
  assignment: AuditAssignment
  job: AuditJob
  pro: AuditPro
}

export type ProOfferAcceptedAuditData = {
  __typename?: 'ProOfferAcceptedAuditData'
  job: ProAuditJob
  offer: ProAuditOffer
  pro: ProAuditPro
}

export type ProOfferAcceptedOfflineAuditData = {
  __typename?: 'ProOfferAcceptedOfflineAuditData'
  job: ProAuditJob
  pro: ProAuditPro
}

export type ProOfferCanceledAuditData = {
  __typename?: 'ProOfferCanceledAuditData'
  job: ProAuditJob
  offer: ProAuditOffer
  pro: ProAuditPro
}

export type ProOfferDeclinedAuditData = {
  __typename?: 'ProOfferDeclinedAuditData'
  job: ProAuditJob
  offer: ProAuditOffer
  pro: ProAuditPro
}

export type ProOfferInValidationAuditData = {
  __typename?: 'ProOfferInValidationAuditData'
  job: ProAuditJob
  offer: ProAuditOffer
  pro: ProAuditPro
}

export type ProOfferPublishedAuditData = {
  __typename?: 'ProOfferPublishedAuditData'
  job: ProAuditJob
  offer: ProAuditOffer
  pro: ProAuditPro
}

export type ProPayoutSettings = {
  __typename?: 'ProPayoutSettings'
  bankAccountHolder: Scalars['String']['output']
  bankAccountIban: Scalars['String']['output']
}

export type ProPlan = {
  __typename?: 'ProPlan'
  features: ProPlanFeatures
  id: Scalars['ID']['output']
  monthlyPrice?: Maybe<ProPlanPrice>
  name: Scalars['String']['output']
  yearlyPrice: ProPlanPrice
}

export type ProPlanFeatures = {
  __typename?: 'ProPlanFeatures'
  feeCap?: Maybe<Scalars['Int']['output']>
  feeRate: Scalars['Float']['output']
  /**
   * Maximum number of active locations a pro can have
   * Unlimited if undefined
   */
  maxLocations?: Maybe<Scalars['Int']['output']>
  /**
   * Maximum number of jobs accepted in a month for a pro
   * Unlimited if undefined
   */
  maxMonthlyAcceptedJobs?: Maybe<Scalars['Int']['output']>
  /**
   * Maximum number of active users a pro can have
   * Unlimited if undefined
   */
  maxUsers?: Maybe<Scalars['Int']['output']>
  /** Whether the pro can manage their user's notifications */
  notificationManagementAvailable: Scalars['Boolean']['output']
  /** Whether the pro can assign roles to its users */
  userRolesAvailable: Scalars['Boolean']['output']
}

export type ProPlanPrice = {
  __typename?: 'ProPlanPrice'
  cost: Scalars['Int']['output']
  id: Scalars['ID']['output']
  mobiliarCoupon?: Maybe<PlanCoupon>
}

export type ProProfile = {
  __typename?: 'ProProfile'
  aboutCompanyDescription?: Maybe<LocalizedString>
  aboutCompanyTitle?: Maybe<LocalizedString>
  coverFileKey?: Maybe<Scalars['String']['output']>
  coverImage?: Maybe<Image>
  experienceInYears: Scalars['Int']['output']
  featuredOfferingIds: Scalars['String']['output'][]
  featuredOfferings: Offering[]
  galleryImages: ProProfileGalleryImage[]
  id: Scalars['ID']['output']
  logoFileKey?: Maybe<Scalars['String']['output']>
  logoImage?: Maybe<Image>
  numApprentices: Scalars['Int']['output']
  numEmployees: Scalars['Int']['output']
  prefilledOfferingsAcknowledged: Scalars['Boolean']['output']
  proId: Scalars['ID']['output']
  representativeFileKey?: Maybe<Scalars['String']['output']>
  representativeImage?: Maybe<Image>
  yearsAsTopPartner?: Maybe<Scalars['Int']['output'][]>
}

export type ProProfileDocumentDeleteResult = {
  __typename?: 'ProProfileDocumentDeleteResult'
  errorMessage?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type ProProfileGalleryImage = {
  __typename?: 'ProProfileGalleryImage'
  description?: Maybe<LocalizedString>
  image: Image
  imageFileKey: Scalars['String']['output']
  title?: Maybe<LocalizedString>
}

export type ProProfilePublishStatus = {
  __typename?: 'ProProfilePublishStatus'
  de: Scalars['Boolean']['output']
  en: Scalars['Boolean']['output']
  fr: Scalars['Boolean']['output']
  it: Scalars['Boolean']['output']
}

export type ProProfileUpdateResult = ProProfile

export type ProProfileUpdatedData = {
  __typename?: 'ProProfileUpdatedData'
  isProfilePublished: PublishedStatus
  update: Scalars['JSON']['output']
}

export enum ProRegisterError {
  CompanyAlreadyExists = 'COMPANY_ALREADY_EXISTS',
  InvalidAgentInvitationCode = 'INVALID_AGENT_INVITATION_CODE',
  InvalidCompanyPhoneNumber = 'INVALID_COMPANY_PHONE_NUMBER',
  InvalidMainContactEmail = 'INVALID_MAIN_CONTACT_EMAIL',
  InvalidMainContactPhoneNumber = 'INVALID_MAIN_CONTACT_PHONE_NUMBER',
  InvalidPostalCode = 'INVALID_POSTAL_CODE',
  InvalidUid = 'INVALID_UID',
  MainContactEmailAlreadyExists = 'MAIN_CONTACT_EMAIL_ALREADY_EXISTS',
}

export type ProRegisterRequest = {
  address: AddressInput
  agentInvitationCode: Scalars['String']['input']
  /** Associations that the pro belong to */
  associations: ProAssociation[]
  /** This is the Swiss VAT or UID (Unternehmens-Identifikationsnummer) */
  businessIdentificationNumber?: InputMaybe<Scalars['String']['input']>
  companyDescription?: InputMaybe<Scalars['String']['input']>
  companyName: Scalars['String']['input']
  /**
   * Company representative
   * **A user account will be created using this field**
   */
  contactPerson: ContactPersonInput
  /** If the pro offers and emergency 24/7 service, these details are specified */
  emergencyContact?: InputMaybe<EmergencyContactInput>
  hasLiabilityInsurance?: InputMaybe<Scalars['Boolean']['input']>
  hasSubsidiaryToRegister: Scalars['Boolean']['input']
  isDirectoryListingEnabled?: InputMaybe<Scalars['Boolean']['input']>
  isMobiliarCustomer: Scalars['Boolean']['input']
  isSubjectToVAT: Scalars['Boolean']['input']
  mobiliarPNumber?: InputMaybe<Scalars['String']['input']>
  otherAssociations?: InputMaybe<Scalars['String']['input']>
  /** Open field to allow a pro to communicate competences he has that weren't covered in existing skills / services */
  otherCompetences?: InputMaybe<Scalars['String']['input']>
  phoneNumber: Scalars['String']['input']
  preRegister?: InputMaybe<Scalars['Boolean']['input']>
  /** List of services the pro knows how to do */
  serviceIds: Scalars['String']['input'][]
  website?: InputMaybe<Scalars['String']['input']>
}

export type ProRegisterRequestCaptchaError = Error & {
  __typename?: 'ProRegisterRequestCaptchaError'
  error: CaptchaError
  message: Scalars['String']['output']
}

export type ProRegisterRequestUserInputError = Error & {
  __typename?: 'ProRegisterRequestUserInputError'
  error: ProRegisterError
  message: Scalars['String']['output']
}

export type ProRegisterResult = Pro | ProRegisterRequestCaptchaError | ProRegisterRequestUserInputError

export enum ProRegistrationSource {
  AgentInvitation = 'AGENT_INVITATION',
  Organic = 'ORGANIC',
  PreRegistered = 'PRE_REGISTERED',
  Unknown = 'UNKNOWN',
}

export enum ProRole {
  Admin = 'ADMIN',
  Executor = 'EXECUTOR',
}

export type ProRollbackDeclinedAuditData = {
  __typename?: 'ProRollbackDeclinedAuditData'
  assignment: AuditAssignment
  job: AuditJob
  pro: AuditPro
}

export type ProRollbackNoReactionAuditData = {
  __typename?: 'ProRollbackNoReactionAuditData'
  assignment: AuditAssignment
  job: AuditJob
  pro: AuditPro
}

export type ProSearchByAgentFilter = {
  agentId?: InputMaybe<Scalars['ID']['input']>
  /** Limits the search to pro's that are in the only damage network. */
  inOnlyDamageNetwork?: InputMaybe<Scalars['Boolean']['input']>
  industryIds?: InputMaybe<Scalars['String']['input'][]>
  isPrioritisedByAnAgent?: InputMaybe<Scalars['Boolean']['input']>
  /**
   * Search text filter, applied to fields:
   * - companyName
   * - contactPerson.firstName
   * - contactPerson.lastName
   * - agent.firstName
   * - agent.lastName
   */
  searchText?: InputMaybe<Scalars['String']['input']>
  year?: InputMaybe<Scalars['String']['input']>
}

export type ProSearchByAgentOrder = {
  accepted?: InputMaybe<Ordering>
  assigned?: InputMaybe<Ordering>
  companyName?: InputMaybe<Ordering>
  declined?: InputMaybe<Ordering>
  turnover?: InputMaybe<Ordering>
  won?: InputMaybe<Ordering>
}

export type ProSearchByAgentResult = {
  __typename?: 'ProSearchByAgentResult'
  data: ProSearchResultItem[]
  total: Scalars['Int']['output']
}

export type ProSearchFilter = {
  /**
   * Search text filter, applied to fields:
   * - companyName
   * - contactPerson.firstName
   * - contactPerson.lastName
   * - agent.firstName
   * - agent.lastName
   */
  searchText?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<ProStatus[]>
}

export type ProSearchOrder = {
  companyName?: InputMaybe<Ordering>
  createdAt?: InputMaybe<Ordering>
}

export type ProSearchResult = {
  __typename?: 'ProSearchResult'
  data: ProSearchResultItem[]
  total: Scalars['Int']['output']
}

export type ProSearchResultAgent = {
  __typename?: 'ProSearchResultAgent'
  firstName: Scalars['String']['output']
  id: Scalars['String']['output']
  lastName: Scalars['String']['output']
}

export type ProSearchResultItem = {
  __typename?: 'ProSearchResultItem'
  agent?: Maybe<ProSearchResultAgent>
  companyName: Scalars['String']['output']
  contactPerson: ContactPerson
  createdAt: Scalars['DateTime']['output']
  defaultLocation: LocationSearchResult
  id: Scalars['String']['output']
  industries?: Maybe<Industry[]>
  locations: ProLocation[]
  /** Role: requires global_admin role */
  merchant: ProMerchant
  metrics: ProMetrics
  networkIds: Scalars['Int']['output'][]
  networks: Network[]
  prioritisation: ProAgentPrioritisation
  skills?: Maybe<Skill[]>
  status: ProStatus
  subscription: ProSubscription
}

export type ProSearchResultItemLocationsArgs = {
  filter?: InputMaybe<ProLocationFilter>
}

export type ProSendMessageToAdminRequest = {
  jobId: Scalars['String']['input']
  message: Scalars['String']['input']
  proId: Scalars['String']['input']
}

export type ProSiteVisitNotNeededAuditData = {
  __typename?: 'ProSiteVisitNotNeededAuditData'
  job: ProAuditJob
  pro: ProAuditPro
}

export type ProSiteVisitScheduledAuditData = {
  __typename?: 'ProSiteVisitScheduledAuditData'
  job: ProAuditJob
  pro: ProAuditPro
  siteVisit: ProAuditSiteVisit
}

export enum ProSpokenLanguage {
  English = 'ENGLISH',
  French = 'FRENCH',
  German = 'GERMAN',
  Italian = 'ITALIAN',
}

export enum ProStatus {
  AccountClosed = 'ACCOUNT_CLOSED',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  NotVerified = 'NOT_VERIFIED',
  PreRegistered = 'PRE_REGISTERED',
}

export type ProStatusChangedData = {
  __typename?: 'ProStatusChangedData'
  from: ProStatus
  to: ProStatus
}

export type ProSubscription = {
  __typename?: 'ProSubscription'
  currentTermEndDate?: Maybe<Scalars['DateTime']['output']>
  features: ProSubscriptionFeatures
  id: Scalars['ID']['output']
  nextBillingDate?: Maybe<Scalars['DateTime']['output']>
  /**
   * ID of the plan,
   * Is only defined when a managed plan was chosen
   */
  planId?: Maybe<Scalars['ID']['output']>
  planName: Scalars['String']['output']
  proId: Scalars['ID']['output']
  /** Set when a subscription has scheduled changes that will take effect at the end of the current term (next renewal) */
  scheduledChanges?: Maybe<ScheduledChanges>
  usage: ProSubscriptionUsage
}

export type ProSubscriptionCancelledAuditData = {
  __typename?: 'ProSubscriptionCancelledAuditData'
  subscription: AuditProSubscription
}

export type ProSubscriptionChangedAuditData = {
  __typename?: 'ProSubscriptionChangedAuditData'
  subscription: AuditProSubscription
}

export type ProSubscriptionChangesScheduledAuditData = {
  __typename?: 'ProSubscriptionChangesScheduledAuditData'
  scheduledChanges?: Maybe<AuditProSubscriptionScheduledChanges>
  subscription: AuditProSubscription
}

export type ProSubscriptionCreatedAuditData = {
  __typename?: 'ProSubscriptionCreatedAuditData'
  subscription: AuditProSubscription
}

export type ProSubscriptionFeatures = {
  __typename?: 'ProSubscriptionFeatures'
  feeCap?: Maybe<Scalars['Int']['output']>
  feeRate: Scalars['Float']['output']
  /**
   * Maximum number of active locations a pro can have
   * Unlimited if undefined
   */
  maxLocations?: Maybe<Scalars['Int']['output']>
  /**
   * Maximum number of jobs a pro can accept in a month
   * Unlimited if undefined
   */
  maxMonthlyAcceptedJobs?: Maybe<Scalars['Int']['output']>
  /**
   * Maximum number of active users a pro can have
   * Unlimited if undefined
   */
  maxUsers?: Maybe<Scalars['Int']['output']>
  notificationManagementAvailable: Scalars['Boolean']['output']
  paymentGuarantee: Scalars['Boolean']['output']
  userRolesAvailable: Scalars['Boolean']['output']
}

export type ProSubscriptionScheduledChangesRemovedAuditData = {
  __typename?: 'ProSubscriptionScheduledChangesRemovedAuditData'
  subscription: AuditProSubscription
}

export type ProSubscriptionUsage = {
  __typename?: 'ProSubscriptionUsage'
  numLocations: Scalars['Int']['output']
  numMonthlyAcceptedJobs: Scalars['Int']['output']
  numUsers: Scalars['Int']['output']
}

export enum ProUpdateAction {
  Activate = 'ACTIVATE',
  CloseAccount = 'CLOSE_ACCOUNT',
  Deactivate = 'DEACTIVATE',
  Register = 'REGISTER',
  ReopenAccount = 'REOPEN_ACCOUNT',
  Unverify = 'UNVERIFY',
  Verify = 'VERIFY',
}

export enum ProUpdateError {
  ValidationError = 'VALIDATION_ERROR',
}

export type ProUpdateInput = {
  associations?: InputMaybe<ProAssociation[]>
  companyName?: InputMaybe<Scalars['String']['input']>
  /**
   * Updating this will update the contact person visible in jobs, but will NOT update the user account (for login)
   * For updating the user account, use the user mutations
   */
  contactPerson?: InputMaybe<ContactPersonInput>
  emergencyContact?: InputMaybe<EmergencyContactInput>
  hasAssociations?: InputMaybe<Scalars['Boolean']['input']>
  hasSubsidiaryToRegister?: InputMaybe<Scalars['Boolean']['input']>
  isMobiliarCustomer?: InputMaybe<Scalars['Boolean']['input']>
  /**
   * Locations to add or update
   * Will not delete locations that are not specified in there
   */
  locations?: InputMaybe<ProLocationInput[]>
  mobiliarPNumber?: InputMaybe<Scalars['String']['input']>
  otherAssociations?: InputMaybe<Scalars['String']['input']>
  otherCompetences?: InputMaybe<Scalars['String']['input']>
  providesEmergencyService?: InputMaybe<Scalars['Boolean']['input']>
  serviceIds?: InputMaybe<Scalars['String']['input'][]>
  slug?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
}

export type ProUpdateInputError = Error & {
  __typename?: 'ProUpdateInputError'
  error?: Maybe<ProUpdateError>
  message: Scalars['String']['output']
}

export type ProUpdateProfileRequest = {
  aboutCompanyDescription: LocalizedStringInput
  aboutCompanyTitle: LocalizedStringInput
  additionallySpokenLanguages: ProSpokenLanguage[]
  coverFileKey?: InputMaybe<Scalars['String']['input']>
  experienceInYears: Scalars['Int']['input']
  featuredOfferingIds: Scalars['String']['input'][]
  galleryImages: GalleryImageInput[]
  isProfilePublished: PublishedStatusInput
  logoFileKey?: InputMaybe<Scalars['String']['input']>
  numApprentices: Scalars['Int']['input']
  numEmployees: Scalars['Int']['input']
  prefilledOfferingsAcknowledged: Scalars['Boolean']['input']
  representativeFileKey?: InputMaybe<Scalars['String']['input']>
}

export type ProUpdateRequest = {
  /** Fields to update on the merchant with the given "proId". */
  merchant?: InputMaybe<MerchantUpdateInput>
  /** Fields to update on the pro with the given "proId". */
  pro?: InputMaybe<ProUpdateInput>
  proId: Scalars['ID']['input']
}

export type ProUpdateResult = Pro | ProUpdateInputError

export type ProUser = {
  __typename?: 'ProUser'
  pro: Pro
  role: ProRole
  settings: ProUserSettings
}

export type ProUserFilter = {
  status?: InputMaybe<UserStatus[]>
}

export type ProUserSearchResult = {
  __typename?: 'ProUserSearchResult'
  /** Global actions on users allowed for the current pro */
  actions: GlobalUserActions[]
  data: User[]
  proId: Scalars['ID']['output']
  total: Scalars['Int']['output']
  /** Returns the current user quota for the users of the pro */
  userQuota: UserQuota
}

export type ProUserSettings = {
  __typename?: 'ProUserSettings'
  sendJobNotificationsViaEmail: Scalars['Boolean']['output']
  sendJobRequestsViaSms: Scalars['Boolean']['output']
  sendPaymentNotificationsViaEmail: Scalars['Boolean']['output']
}

export type ProUserSettingsInput = {
  sendJobNotificationsViaEmail: Scalars['Boolean']['input']
  sendJobRequestsViaSms: Scalars['Boolean']['input']
  /**
   * Disabling requires a pro plan with the 'notificationManagementAvailable' entitlement
   *
   * Sending null will reset the value to the default value.
   * A pro user without the entitlement should send null, as the form checkbox should be disabled/not be part of the form.
   */
  sendPaymentNotificationsViaEmail?: InputMaybe<Scalars['Boolean']['input']>
}

export type ProUserType = {
  __typename?: 'ProUserType'
  proId: Scalars['ID']['output']
  role: ProRole
  type: ProUserTypeDiscriminator
}

export enum ProUserTypeDiscriminator {
  Pro = 'PRO',
}

export type ProWaitingForClientAuditData = {
  __typename?: 'ProWaitingForClientAuditData'
  job: ProAuditJob
  pro: ProAuditPro
}

export type ProWithProfile = {
  __typename?: 'ProWithProfile'
  pro: Pro
  profile: ProProfile
}

export enum ProofOfIdentityType {
  DriversLicense = 'DRIVERS_LICENSE',
  IdCard = 'ID_CARD',
  Passport = 'PASSPORT',
}

export type PublicMinimalProProfile = {
  __typename?: 'PublicMinimalProProfile'
  coverFileKey?: Maybe<Scalars['String']['output']>
  coverImage?: Maybe<Image>
  featuredOfferings: Offering[]
  id: Scalars['ID']['output']
  isBuildigoVerified: Scalars['Boolean']['output']
  isErneuerbarheizenNetwork: Scalars['Boolean']['output']
  isMobiliarNetwork: Scalars['Boolean']['output']
  logoFileKey?: Maybe<Scalars['String']['output']>
  logoImage?: Maybe<Image>
  proId: Scalars['ID']['output']
}

export type PublicPro = {
  __typename?: 'PublicPro'
  associations: ProAssociation[]
  companyName: Scalars['String']['output']
  id: Scalars['ID']['output']
  industries: Scalars['String']['output'][]
  isProfilePublished: ProProfilePublishStatus
  locations: PublicProLocation[]
  profile: PublicProProfile
  recommendingAgency?: Maybe<ProAgency>
  slug: Scalars['String']['output']
  spokenLanguages: ProSpokenLanguage[]
}

export type PublicProLocation = {
  __typename?: 'PublicProLocation'
  default: Scalars['Boolean']['output']
  googlePlace?: Maybe<AssociatedGooglePlace>
  id: Scalars['ID']['output']
  place: Place
  representativePerson?: Maybe<ProLocationRepresentative>
}

export type PublicProPlan = {
  __typename?: 'PublicProPlan'
  features: PublicProPlanFeatures
  highlighted?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['String']['output']
  monthlyPrice?: Maybe<PublicProPlanPrice>
  name: Scalars['String']['output']
  yearlyPrice: PublicProPlanPrice
}

export type PublicProPlanFeatures = {
  __typename?: 'PublicProPlanFeatures'
  feeCap?: Maybe<Scalars['Int']['output']>
  feeRate: Scalars['Float']['output']
  maxLocations?: Maybe<Scalars['Int']['output']>
  maxMonthlyAcceptedJobs?: Maybe<Scalars['Int']['output']>
  maxUsers?: Maybe<Scalars['Int']['output']>
  /** Whether the pro can manage advanced notifications */
  notificationManagementAvailable: Scalars['Boolean']['output']
  /** Whether the pro can assign roles to its users */
  userRolesAvailable: Scalars['Boolean']['output']
}

export type PublicProPlanPrice = {
  __typename?: 'PublicProPlanPrice'
  cost: Scalars['Int']['output']
  id: Scalars['String']['output']
  mobiliarCoupon: PublicProPriceMobiliarCoupon
}

export type PublicProPriceMobiliarCoupon = {
  __typename?: 'PublicProPriceMobiliarCoupon'
  discount: Scalars['Int']['output']
}

export type PublicProProfile = PublicMinimalProProfile | PublicPublishedProProfile

export type PublicProProfileGalleryImage = {
  __typename?: 'PublicProProfileGalleryImage'
  description?: Maybe<Scalars['String']['output']>
  image: Image
  imageFileKey: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
}

export type PublicPublishedProProfile = {
  __typename?: 'PublicPublishedProProfile'
  aboutCompanyDescription?: Maybe<Scalars['String']['output']>
  aboutCompanyTitle?: Maybe<Scalars['String']['output']>
  coverFileKey?: Maybe<Scalars['String']['output']>
  coverImage?: Maybe<Image>
  experienceInYears: Scalars['Int']['output']
  featuredOfferings: Offering[]
  galleryImages: PublicProProfileGalleryImage[]
  id: Scalars['ID']['output']
  isBuildigoVerified: Scalars['Boolean']['output']
  isErneuerbarheizenNetwork: Scalars['Boolean']['output']
  isMobiliarNetwork: Scalars['Boolean']['output']
  logoFileKey?: Maybe<Scalars['String']['output']>
  logoImage?: Maybe<Image>
  numApprentices: Scalars['Int']['output']
  numEmployees: Scalars['Int']['output']
  proId: Scalars['ID']['output']
  representativeFileKey?: Maybe<Scalars['String']['output']>
  representativeImage?: Maybe<Image>
}

export enum PublishOfferError {
  AnotherProChosen = 'ANOTHER_PRO_CHOSEN',
  EstimateRequiresMessage = 'ESTIMATE_REQUIRES_MESSAGE',
  HourlyCostRequired = 'HOURLY_COST_REQUIRED',
  InvalidRange = 'INVALID_RANGE',
  JobCanceled = 'JOB_CANCELED',
  OfferRequiresCost = 'OFFER_REQUIRES_COST',
}

export type PublishOfferInputError = Error & {
  __typename?: 'PublishOfferInputError'
  error: PublishOfferError
  message: Scalars['String']['output']
}

export type PublishOfferResult = Offer | PublishOfferInputError

export type PublishedStatus = {
  __typename?: 'PublishedStatus'
  de: Scalars['Boolean']['output']
  en: Scalars['Boolean']['output']
  fr: Scalars['Boolean']['output']
  it: Scalars['Boolean']['output']
}

export type PublishedStatusInput = {
  de: Scalars['Boolean']['input']
  en: Scalars['Boolean']['input']
  fr: Scalars['Boolean']['input']
  it: Scalars['Boolean']['input']
}

export type Query = {
  __typename?: 'Query'
  _empty?: Maybe<Scalars['String']['output']>
  /**
   * Returns the accounting report.
   * To set a time range (based on invoices creation date), use the "from" argument for the start time and the "to" argument for the end time.
   * Both start and end times are included in the range.
   */
  accountingReport: AccountingReport
  adminJobById: AdminJob
  adminJobCounts: AdminJobCounts
  adminJobMetrics: AdminJobMetrics
  adminProById: AdminPro
  agencyById?: Maybe<Agency>
  agencyBySlug?: Maybe<AgencyBySlug>
  agentById: Agent
  agentByInvitationCode?: Maybe<AgentByInvitationCode>
  agentByJobRequestCode?: Maybe<AgentByJobRequestCode>
  agentJobs: AgentJobsResult
  agentProById: AgentPro
  /** Only contains invites that haven't been used. */
  agentProInvites: AgentProInvitesResult
  agentRecruitingJobs: RecruitingAgentJobsResult
  allAdminJobs: AdminJob[]
  allAgencies: Agency[]
  allAgentsInSameAgencyAs: Agent[]
  allApplications: ProApplication[]
  allCategories: Category[]
  allIndustries: Industry[]
  allJobs: Job[]
  allOfferings: Offering[]
  allServices: Service[]
  allSkills: Skill[]
  applicationById?: Maybe<ProApplication>
  /**
   * Gets the assigned jobs of a pro
   * **Role**: Requires to own the pro to get its assigned jobs
   */
  assignedJob?: Maybe<AssignedJob>
  /**
   * Calculates an invoice amount, vat, vat rate, etc.
   * The values are influenced by the pro subscription, and job type (damage / standard)
   */
  calculateInvoiceAmounts: CalculateInvoiceAmountResult
  calculatePriceRange: PriceRange
  clientById?: Maybe<Client>
  getCompanyFromUIDRegistry?: Maybe<UidRegisteredCompany>
  industriesByIds: Industry[]
  jobBookmarks: JobBookmark[]
  jobById?: Maybe<JobByIdResult>
  listRooms: Room[]
  listRoomsRelatedToJob: Room[]
  manualAccountingEntries: ManualAccountingEntryResult
  offerById?: Maybe<Offer>
  offeringById?: Maybe<Offering>
  offeringByIds: Offering[]
  paybackById?: Maybe<Payback>
  /** Returns all subscription plans available for a pro */
  proAvailablePlans: ProPlan[]
  /**
   * Gets a pro
   * **Role**: Requires to own the pro to query it
   */
  proById?: Maybe<Pro>
  proBySlug?: Maybe<PublicPro>
  /** All available plans pros can choose for a subscription */
  proPlans: PublicProPlan[]
  proWithProfile?: Maybe<ProWithProfile>
  prosBySlug: PublicPro[]
  roomById: Room
  searchAdminContacts: AdminContactSearchResult
  /**
   * Search for articles
   * Results are sorted by publication date (latest first)
   */
  searchArticles: ArticleSearchResult
  searchAssignedJobs: AssignedJobsSearchResult
  searchClientJobs: ClientJobSearchResult
  /**
   * Search for postal code or regions
   * - **searchText**: search text will be matched against postal code, postal district and region name
   */
  searchGeography?: Maybe<GeoResult[]>
  searchInvoices: InvoiceSearchResult
  searchJobs: JobSearchResult
  /**
   * Search for offerings
   * Note: Only enabled offerings are returned
   */
  searchOfferings: OfferingSearchItem[]
  searchPaybacks: PaybackSearchResult
  searchPlatform: PlatformSearchItem[]
  /** Search for postal codes by location or zip */
  searchPostalCode?: Maybe<PostalCode[]>
  searchProDirectory: ProDirectorySearchResult
  searchProInvoices: ProInvoiceSearchResult
  searchProLocations: ProLocationSearchResult
  /** Returns a filtered, ordered and paginated list of users belong to a pro (based on argument **proId**) */
  searchProUsers: ProUserSearchResult
  searchPros: ProSearchResult
  searchProsByAgent: ProSearchByAgentResult
  searchUIDRegistryByCompanyName: UidRegisteredCompany[]
  searchUsers: AdminUserSearchResult
  userById: User
  validateVoucher: VoucherValidationResult
}

export type QueryAccountingReportArgs = {
  from?: InputMaybe<Scalars['DateTime']['input']>
  snapshotDate: Scalars['DateTime']['input']
}

export type QueryAdminJobByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryAdminJobCountsArgs = {
  previousXDays: Scalars['Int']['input']
}

export type QueryAdminJobMetricsArgs = {
  filterByJobId?: InputMaybe<Scalars['ID']['input']>
  filterByLanguages: Language[]
  filterByYear?: InputMaybe<Scalars['Int']['input']>
}

export type QueryAdminProByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryAgencyByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryAgencyBySlugArgs = {
  slug: Scalars['String']['input']
}

export type QueryAgentByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryAgentByInvitationCodeArgs = {
  code: Scalars['String']['input']
}

export type QueryAgentByJobRequestCodeArgs = {
  code: Scalars['String']['input']
}

export type QueryAgentJobsArgs = {
  agentId: Scalars['ID']['input']
  filterLevel: FilterLevel
}

export type QueryAgentProByIdArgs = {
  agentId: Scalars['ID']['input']
  proId: Scalars['ID']['input']
}

export type QueryAgentProInvitesArgs = {
  agentId: Scalars['ID']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryAgentRecruitingJobsArgs = {
  agentId: Scalars['ID']['input']
  filterLevel: FilterLevel
}

export type QueryAllAgentsInSameAgencyAsArgs = {
  agentId: Scalars['ID']['input']
}

export type QueryAllCategoriesArgs = {
  filterBy?: InputMaybe<CategoryFilters>
  orderBy?: InputMaybe<CategoryOrder>
}

export type QueryAllIndustriesArgs = {
  status?: InputMaybe<CompetenceStatus>
}

export type QueryAllOfferingsArgs = {
  filterBy?: InputMaybe<OfferingFilters>
}

export type QueryAllServicesArgs = {
  status?: InputMaybe<CompetenceStatus>
}

export type QueryAllSkillsArgs = {
  status?: InputMaybe<CompetenceStatus>
}

export type QueryApplicationByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryAssignedJobArgs = {
  request: AssignedJobInput
}

export type QueryCalculateInvoiceAmountsArgs = {
  request: CalculateInvoiceAmountInput
}

export type QueryCalculatePriceRangeArgs = {
  jobQuestions: StaticJobQuestionsInput
}

export type QueryClientByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryGetCompanyFromUidRegistryArgs = {
  uid: Scalars['String']['input']
}

export type QueryIndustriesByIdsArgs = {
  ids: Scalars['ID']['input'][]
  orderBy?: InputMaybe<IndustryOrder>
}

export type QueryJobByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryListRoomsArgs = {
  userId: Scalars['ID']['input']
}

export type QueryListRoomsRelatedToJobArgs = {
  jobId: Scalars['ID']['input']
}

export type QueryManualAccountingEntriesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type QueryOfferByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryOfferingByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryOfferingByIdsArgs = {
  ids: Scalars['ID']['input'][]
}

export type QueryPaybackByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryProAvailablePlansArgs = {
  proId: Scalars['ID']['input']
}

export type QueryProByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryProBySlugArgs = {
  slug: Scalars['String']['input']
}

export type QueryProWithProfileArgs = {
  proId: Scalars['ID']['input']
}

export type QueryProsBySlugArgs = {
  slugs: Scalars['String']['input'][]
}

export type QueryRoomByIdArgs = {
  roomId: Scalars['ID']['input']
}

export type QuerySearchAdminContactsArgs = {
  searchQuery: Scalars['String']['input']
  type: AdminContactSearchType
}

export type QuerySearchArticlesArgs = {
  page?: InputMaybe<Page>
  searchCriteria: SearchArticlesCriteria
}

export type QuerySearchAssignedJobsArgs = {
  proId: Scalars['ID']['input']
}

export type QuerySearchClientJobsArgs = {
  clientId: Scalars['ID']['input']
  filter?: InputMaybe<ClientSearchFilterInput>
}

export type QuerySearchGeographyArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  searchText: Scalars['String']['input']
}

export type QuerySearchInvoicesArgs = {
  criteria?: InputMaybe<InvoiceSearchCriteria>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order?: InputMaybe<InvoiceOrder>
}

export type QuerySearchJobsArgs = {
  jobSearchCriteria?: InputMaybe<JobSearchCriteria>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchOfferingsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  searchCriteria: SearchOfferingCriteria
}

export type QuerySearchPaybacksArgs = {
  criteria?: InputMaybe<PaybackSearchCriteria>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order?: InputMaybe<PaybackOrder>
}

export type QuerySearchPlatformArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  searchCriteria: SearchPlatformCriteria
}

export type QuerySearchPostalCodeArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  locationOrZip: Scalars['String']['input']
}

export type QuerySearchProDirectoryArgs = {
  order?: InputMaybe<SearchProDirectoryOrder>
  page?: InputMaybe<Page>
  searchCriteria: SearchProDirectoryCriteria
}

export type QuerySearchProInvoicesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order?: InputMaybe<InvoiceOrder>
  proId: Scalars['ID']['input']
}

export type QuerySearchProLocationsArgs = {
  filter?: InputMaybe<ProLocationSearchFilter>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order?: InputMaybe<ProLocationSearchOrder>
}

export type QuerySearchProUsersArgs = {
  filter?: InputMaybe<ProUserFilter>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order?: InputMaybe<UserOrder>
  proId: Scalars['ID']['input']
}

export type QuerySearchProsArgs = {
  filter?: InputMaybe<ProSearchFilter>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order?: InputMaybe<ProSearchOrder>
}

export type QuerySearchProsByAgentArgs = {
  agentId: Scalars['String']['input']
  filter?: InputMaybe<ProSearchByAgentFilter>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order?: InputMaybe<ProSearchByAgentOrder>
}

export type QuerySearchUidRegistryByCompanyNameArgs = {
  companyName: Scalars['String']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchUsersArgs = {
  filter?: InputMaybe<AdminUserFilter>
  limit?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  order?: InputMaybe<UserOrder>
}

export type QueryUserByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryValidateVoucherArgs = {
  code: Scalars['String']['input']
}

export type Question = {
  __typename?: 'Question'
  clientFacingLabel: Scalars['String']['output']
  errorLabel?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  possibleAnswers?: Maybe<Answer[]>
  proFacingLabel: Scalars['String']['output']
  rank: Scalars['Int']['output']
  required: Scalars['Boolean']['output']
  status: QuestionStatus
  stepperConfiguration?: Maybe<QuestionStepperConfiguration>
  tooltip?: Maybe<Scalars['String']['output']>
  type: QuestionType
}

export type QuestionPossibleAnswersArgs = {
  filterBy?: InputMaybe<AnswerFilter>
  orderBy?: InputMaybe<AnswerOrder>
}

export type QuestionFilter = {
  status?: InputMaybe<QuestionStatus>
}

export type QuestionOrder = {
  rank?: InputMaybe<Ordering>
}

export enum QuestionStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

export type QuestionStepperConfiguration = {
  __typename?: 'QuestionStepperConfiguration'
  lowerBound: Scalars['Int']['output']
  stepSize: Scalars['Int']['output']
  upperBound: Scalars['Int']['output']
}

export enum QuestionType {
  MultipleSelectCheckboxes = 'MULTIPLE_SELECT_CHECKBOXES',
  MultipleSelectTiles = 'MULTIPLE_SELECT_TILES',
  OpenTextField = 'OPEN_TEXT_FIELD',
  SingleSelectDropdown = 'SINGLE_SELECT_DROPDOWN',
  SingleSelectRadio = 'SINGLE_SELECT_RADIO',
  SingleSelectTiles = 'SINGLE_SELECT_TILES',
  Stepper = 'STEPPER',
}

export type RecruitingAgentAssignedAuditData = {
  __typename?: 'RecruitingAgentAssignedAuditData'
  agent: AuditAgent
  job: AuditJob
}

export type RecruitingAgentJob = {
  __typename?: 'RecruitingAgentJob'
  agents: Agent[]
  id: Scalars['ID']['output']
  invites: Invite[]
  offering?: Maybe<JobOffering>
  place?: Maybe<Place>
  submittedAt: Scalars['DateTime']['output']
  title: Scalars['String']['output']
}

export type RecruitingAgentJobsResult = {
  __typename?: 'RecruitingAgentJobsResult'
  agentId: Scalars['ID']['output']
  data: RecruitingAgentJob[]
  total: Scalars['Int']['output']
}

export type Region = {
  __typename?: 'Region'
  id: Scalars['String']['output']
  postalCodes: Maybe<Scalars['Int']['output']>[]
  regionName: Scalars['String']['output']
}

export enum RemoveRoomMemberError {
  AtLeastTwoMembersRequired = 'AT_LEAST_TWO_MEMBERS_REQUIRED',
}

export type RemoveRoomMemberInputError = {
  __typename?: 'RemoveRoomMemberInputError'
  error: RemoveRoomMemberError
  message: Scalars['String']['output']
}

export type RemoveRoomMemberResult = RemoveRoomMemberInputError | Room

export type RenewableEnergyConsultingQuestions = {
  __typename?: 'RenewableEnergyConsultingQuestions'
  boilerSystem: RenewableEnergyConsulting_BoilerSystem
  buildingOccupants: Scalars['Int']['output']
  buildingType: RenewableEnergyConsulting_BuildingType
  constructionYear: Scalars['Int']['output']
  heatedAreaSquareMeters: Scalars['Float']['output']
  heatingEnergyConsumption: Scalars['Float']['output']
  heatingSystem: RenewableEnergyConsulting_HeatingSystem
  heatingSystemAge: RenewableEnergyConsulting_HeatingSystemAge
  heatingType: RenewableEnergyConsulting_HeatingType
  numberOfApartments?: Maybe<RenewableEnergyConsulting_NumberOfApartments>
  recentRenovations: Scalars['Boolean']['output']
  recentRenovationsDescription?: Maybe<Scalars['String']['output']>
}

export type RenewableEnergyConsultingQuestionsInput = {
  boilerSystem: RenewableEnergyConsulting_BoilerSystem
  buildingOccupants: Scalars['Int']['input']
  buildingType: RenewableEnergyConsulting_BuildingType
  constructionYear: Scalars['Int']['input']
  heatedAreaSquareMeters: Scalars['Float']['input']
  heatingEnergyConsumption: Scalars['Float']['input']
  heatingSystem: RenewableEnergyConsulting_HeatingSystem
  heatingSystemAge: RenewableEnergyConsulting_HeatingSystemAge
  heatingType: RenewableEnergyConsulting_HeatingType
  numberOfApartments?: InputMaybe<RenewableEnergyConsulting_NumberOfApartments>
  recentRenovations: Scalars['Boolean']['input']
  recentRenovationsDescription?: InputMaybe<Scalars['String']['input']>
}

export enum RenewableEnergyConsulting_BoilerSystem {
  ConnectedToHeating = 'CONNECTED_TO_HEATING',
  Electric = 'ELECTRIC',
  HeatPump = 'HEAT_PUMP',
  Unknown = 'UNKNOWN',
}

export enum RenewableEnergyConsulting_BuildingType {
  MultiFamily = 'MULTI_FAMILY',
  SingleFamily = 'SINGLE_FAMILY',
}

export enum RenewableEnergyConsulting_HeatingSystem {
  Floor = 'FLOOR',
  Other = 'OTHER',
  RadiatorHighTemp = 'RADIATOR_HIGH_TEMP',
  RadiatorLowTemp = 'RADIATOR_LOW_TEMP',
  Unknown = 'UNKNOWN',
}

export enum RenewableEnergyConsulting_HeatingSystemAge {
  LessThan_10Years = 'LESS_THAN_10_YEARS',
  MoreThan_10Years = 'MORE_THAN_10_YEARS',
  Unknown = 'UNKNOWN',
}

export enum RenewableEnergyConsulting_HeatingType {
  Electric = 'ELECTRIC',
  Gas = 'GAS',
  Oil = 'OIL',
  Other = 'OTHER',
  Pellet = 'PELLET',
  Solar = 'SOLAR',
  Wood = 'WOOD',
}

export enum RenewableEnergyConsulting_NumberOfApartments {
  MoreThanSix = 'MORE_THAN_SIX',
  SixOrLess = 'SIX_OR_LESS',
}

export enum RequestAdditionalOfferError {
  CannotRequestMore = 'CANNOT_REQUEST_MORE',
}

export type RequestAdditionalOfferInputError = {
  __typename?: 'RequestAdditionalOfferInputError'
  error: RequestAdditionalOfferError
}

export type RequestAdditionalOfferResult = Job | RequestAdditionalOfferInputError

export type RequestEmergencyJobUserInputError = Error & {
  __typename?: 'RequestEmergencyJobUserInputError'
  error: EmergencyJobRequestError
  message: Scalars['String']['output']
}

export enum RequestJobError {
  /** Error raised when we are unable to create or link the client to a user account */
  EmailLinkedToAnotherAccount = 'EMAIL_LINKED_TO_ANOTHER_ACCOUNT',
  InvalidAddressPostalCode = 'INVALID_ADDRESS_POSTAL_CODE',
  InvalidBillingPostalCode = 'INVALID_BILLING_POSTAL_CODE',
  InvalidEmail = 'INVALID_EMAIL',
  InvalidPhoneNumber = 'INVALID_PHONE_NUMBER',
}

export type RequestJobUserInputError = Error & {
  __typename?: 'RequestJobUserInputError'
  error: RequestJobError
  message: Scalars['String']['output']
}

export type ReviseInvoiceInputError = Error & {
  __typename?: 'ReviseInvoiceInputError'
  error: SaveInvoiceUserError
  message: Scalars['String']['output']
}

export type ReviseInvoiceResult = Invoice | ReviseInvoiceInputError

export type RollbackDeclinedAuditData = {
  __typename?: 'RollbackDeclinedAuditData'
  assignment: AuditAssignment
  job: AuditJob
  pro: AuditPro
}

export type RollbackNoReactionAuditData = {
  __typename?: 'RollbackNoReactionAuditData'
  assignment: AuditAssignment
  job: AuditJob
  pro: AuditPro
}

export type RollbackOfferAcceptedToPublishedAuditData = {
  __typename?: 'RollbackOfferAcceptedToPublishedAuditData'
  assignments: RollbackOfferAcceptedToPublishedAuditDataAssignments
  rolledBackJob: AuditJob
  rolledBackOffer: AuditOffer
}

export type RollbackOfferAcceptedToPublishedAuditDataAssignments = {
  __typename?: 'RollbackOfferAcceptedToPublishedAuditDataAssignments'
  after: AuditAssignment[]
  before: AuditAssignment[]
  untouched: AuditAssignment[]
}

export type RollbackProFromAcceptedToDeclinedAuditData = {
  __typename?: 'RollbackProFromAcceptedToDeclinedAuditData'
  assignment: AuditAssignment
  job: AuditJob
  pro: AuditPro
}

export type Room = {
  __typename?: 'Room'
  createdAt?: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  members: RoomMember[]
  messages: Message[]
  /** Contextual information related to a Chat Room, such as a job or a pro */
  relatedInfo?: Maybe<RoomInfo>
  updatedAt?: Maybe<Scalars['DateTime']['output']>
}

export type RoomMessagesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>
  sortDirection?: InputMaybe<MessageSortDirection>
}

export type RoomContextClient = {
  __typename?: 'RoomContextClient'
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
}

export type RoomContextExecutionAddress = {
  __typename?: 'RoomContextExecutionAddress'
  locality: Scalars['String']['output']
  postalCode: Scalars['Int']['output']
  streetAndHouseNumber: Scalars['String']['output']
}

export type RoomContextJob = {
  __typename?: 'RoomContextJob'
  client: RoomContextClient
  executionAddress: RoomContextExecutionAddress
  id: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
}

export type RoomContextPro = {
  __typename?: 'RoomContextPro'
  companyName: Scalars['String']['output']
  id: Scalars['String']['output']
}

export type RoomInfo = {
  __typename?: 'RoomInfo'
  assignmentId?: Maybe<Scalars['String']['output']>
  job?: Maybe<RoomContextJob>
  jobId?: Maybe<Scalars['String']['output']>
  pro?: Maybe<RoomContextPro>
}

export type RoomMember = {
  __typename?: 'RoomMember'
  capacity: Capacity
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
}

export enum Salutation {
  Madam = 'MADAM',
  None = 'NONE',
  Sir = 'SIR',
}

export type SaveInvoiceInputError = Error & {
  __typename?: 'SaveInvoiceInputError'
  error: SaveInvoiceUserError
  message: Scalars['String']['output']
}

export type SaveInvoiceResult = Invoice | SaveInvoiceInputError

export enum SaveInvoiceUserError {
  InvalidAmount = 'INVALID_AMOUNT',
}

export type SaveInvoiceUserInputError = Error & {
  __typename?: 'SaveInvoiceUserInputError'
  error: SubmitOfferUserError
  message: Scalars['String']['output']
}

export enum ScheduleExecutionUserError {
  InvalidStateTransition = 'INVALID_STATE_TRANSITION',
}

export type ScheduledChanges = {
  __typename?: 'ScheduledChanges'
  planId: Scalars['ID']['output']
  planName: Scalars['String']['output']
}

export type ScheduledDate = {
  __typename?: 'ScheduledDate'
  date: Scalars['DateTime']['output']
  fromTime: Scalars['String']['output']
  toTime?: Maybe<Scalars['String']['output']>
}

export type ScheduledExecution = {
  __typename?: 'ScheduledExecution'
  dates: ScheduledDate[]
}

export type ScheduledExecutionDateInput = {
  date: Scalars['DateTime']['input']
  fromTime: Scalars['String']['input']
  toTime?: InputMaybe<Scalars['String']['input']>
}

export type SearchArticlesCriteria = {
  /** Filter articles with from a specific author */
  authorName?: InputMaybe<Scalars['String']['input']>
  /** Searches in article's title, then preview */
  searchText?: InputMaybe<Scalars['String']['input']>
  /** Filter articles with a specific 'showInOverview' value */
  showInOverview?: InputMaybe<Scalars['Boolean']['input']>
  /** Filter articles from a topic (provide the topic slug) */
  topic?: InputMaybe<Scalars['String']['input']>
}

export type SearchAsset = {
  __typename?: 'SearchAsset'
  file: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
}

export type SearchGooglePlace = {
  __typename?: 'SearchGooglePlace'
  rating?: Maybe<Scalars['Float']['output']>
  userRatingCount?: Maybe<Scalars['Int']['output']>
}

export type SearchLocation = {
  __typename?: 'SearchLocation'
  default: Scalars['Boolean']['output']
  geoPoint?: Maybe<SearchLocationGeoPoint>
  googlePlace?: Maybe<SearchGooglePlace>
  locality: Scalars['String']['output']
  postalCode: Scalars['Int']['output']
}

export type SearchLocationGeoPoint = {
  __typename?: 'SearchLocationGeoPoint'
  lat: Scalars['Float']['output']
  lng: Scalars['Float']['output']
}

export type SearchOfferingCriteria = {
  category?: InputMaybe<Scalars['Int']['input']>
  searchText?: InputMaybe<Scalars['String']['input']>
}

export type SearchPlatformCriteria = {
  searchText: Scalars['String']['input']
}

export type SearchProDirectoryCriteria = {
  industryIds?: InputMaybe<Scalars['String']['input'][]>
  /**
   * When filtering geographically, you have the option to provide a region or a location
   * You can provide only one - region will be ignored if location is set
   * - **location**: any pro with a location within radius "distance" in meters from postal code will be included
   */
  location?: InputMaybe<SearchProDirectoryLocationInput>
  offeringIds?: InputMaybe<Scalars['String']['input'][]>
  /**
   * When filtering geographically, you have the option to provide a region or a location
   * You can provide only one - region will be ignored if location is set
   * - **region**: any pro with a location in the list of postal codes will be included
   */
  region?: InputMaybe<SearchProDirectoryRegionInput>
  searchText?: InputMaybe<Scalars['String']['input']>
}

export type SearchProDirectoryLocationInput = {
  /** Distance in meters from zip */
  distance: Scalars['Int']['input']
  postalDistrict: Scalars['String']['input']
  zip: Scalars['Int']['input']
}

export type SearchProDirectoryOrder = {
  /** When this is specified we order pros closes to this point. */
  geoLocation?: InputMaybe<GeoPoint>
  sortBy: SearchProDirectorySorting
}

export type SearchProDirectoryRegionInput = {
  postalCodes: Scalars['Int']['input'][]
}

export enum SearchProDirectorySorting {
  DistanceAsc = 'DISTANCE_ASC',
  DistanceDesc = 'DISTANCE_DESC',
  RelevanceDesc = 'RELEVANCE_DESC',
}

export type SendMessageError = {
  __typename?: 'SendMessageError'
  cause: SendMessageErrorCause
  message: Scalars['String']['output']
}

export enum SendMessageErrorCause {
  NotMember = 'NOT_MEMBER',
}

export type SendMessageResult = Message | SendMessageError

export type SendMessageToAdminResult = {
  __typename?: 'SendMessageToAdminResult'
  errorMessage?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type Service = {
  __typename?: 'Service'
  id: Scalars['String']['output']
  label: Scalars['String']['output']
  rank: Scalars['Int']['output']
}

export type ServiceFeeVoucherTerm = {
  __typename?: 'ServiceFeeVoucherTerm'
  clientServiceFee: Scalars['Boolean']['output']
}

export enum Signum {
  Minus = 'MINUS',
  Plus = 'PLUS',
}

export type SimpleTextMessageContent = {
  __typename?: 'SimpleTextMessageContent'
  text: Scalars['String']['output']
}

export type SimpleTextMessageContentInput = {
  text: Scalars['String']['input']
}

export type SiteVisit = {
  __typename?: 'SiteVisit'
  date: Scalars['DateTime']['output']
  fromTime: Scalars['String']['output']
  toTime?: Maybe<Scalars['String']['output']>
}

export type SiteVisitNotNeededAuditData = {
  __typename?: 'SiteVisitNotNeededAuditData'
  job: AuditJob
  pro: AuditPro
}

export type SiteVisitScheduledAuditData = {
  __typename?: 'SiteVisitScheduledAuditData'
  job: AuditJob
  pro: AuditPro
  siteVisit: AuditSiteVisit
}

export type Skill = {
  __typename?: 'Skill'
  id: Scalars['String']['output']
  label: Scalars['String']['output']
  rank: Scalars['Int']['output']
  services?: Maybe<Service[]>
  status: CompetenceStatus
}

export type SkillServicesArgs = {
  status?: InputMaybe<CompetenceStatus>
}

export type SmsSentAuditData = {
  __typename?: 'SmsSentAuditData'
  sms: AuditSms
}

export type SolarInstallationStaticJobQuestions = {
  __typename?: 'SolarInstallationStaticJobQuestions'
  addons: SolarInstallation_Addons[]
  buildingType: SolarInstallation_BuildingType
  desiredSystem: SolarInstallation_DesiredSystem
  mostImportantFactor: SolarInstallation_MostImportantFactor
  otherBuildingType?: Maybe<Scalars['String']['output']>
  otherDesiredSystem?: Maybe<Scalars['String']['output']>
  roofSquareMeters: Scalars['Float']['output']
}

export type SolarInstallationStaticJobQuestionsInput = {
  addons: SolarInstallation_Addons[]
  buildingType: SolarInstallation_BuildingType
  desiredSystem: SolarInstallation_DesiredSystem
  mostImportantFactor: SolarInstallation_MostImportantFactor
  /** If buildingType is OTHER, specify the desired system here */
  otherBuildingType?: InputMaybe<Scalars['String']['input']>
  /** If desiredSystem is OTHER, specify the desired system here */
  otherDesiredSystem?: InputMaybe<Scalars['String']['input']>
  /** How many square meters of roof (approx.) is available for solar panels and oriented south, west or east ? */
  roofSquareMeters: Scalars['Float']['input']
}

export enum SolarInstallation_Addons {
  BatteryForUnusedEnergyStorage = 'BATTERY_FOR_UNUSED_ENERGY_STORAGE',
  CarChargingStation = 'CAR_CHARGING_STATION',
}

export enum SolarInstallation_BuildingType {
  ApartmentBuilding = 'APARTMENT_BUILDING',
  Commercial = 'COMMERCIAL',
  MultiFamilyHouse = 'MULTI_FAMILY_HOUSE',
  Other = 'OTHER',
  SingleFamilyHouse = 'SINGLE_FAMILY_HOUSE',
}

export enum SolarInstallation_DesiredSystem {
  Other = 'OTHER',
  RoofPhotovoltaic = 'ROOF_PHOTOVOLTAIC',
}

export enum SolarInstallation_MostImportantFactor {
  LocalPartner = 'LOCAL_PARTNER',
  NoPreference = 'NO_PREFERENCE',
  QuickInstallation = 'QUICK_INSTALLATION',
}

export type StaticJobQuestions = {
  __typename?: 'StaticJobQuestions'
  boilerDescaling?: Maybe<BoilerDecalingStaticJobQuestions>
  catDoorInstallation?: Maybe<CatDoorInstallationStaticJobQuestions>
  hedgeTrimming?: Maybe<HedgeTrimmingStaticJobQuestions>
  installLamps?: Maybe<InstallLampsStaticJobQuestions>
  lawnMaintenance?: Maybe<LawnMaintenanceStaticJobQuestions>
  lawnMowing?: Maybe<LawnMowningStaticJobQuestions>
  layingParquet?: Maybe<LayingParquetStaticJobQuestions>
  parquetMaintenance?: Maybe<ParquetMaintenanceStaticJobQuestions>
  renewableEnergyConsulting?: Maybe<RenewableEnergyConsultingQuestions>
  solarInstallation?: Maybe<SolarInstallationStaticJobQuestions>
  treeCutting?: Maybe<TreeCuttingStaticJobQuestions>
}

/** One field only should be provided */
export type StaticJobQuestionsInput = {
  boilerDescaling?: InputMaybe<BoilerDecalingStaticJobQuestionsInput>
  catDoorInstallation?: InputMaybe<CatDoorInstallationStaticJobQuestionsInput>
  hedgeTrimming?: InputMaybe<HedgeTrimmingStaticJobQuestionsInput>
  installLamps?: InputMaybe<InstallLampsStaticJobQuestionsInput>
  lawnMaintenance?: InputMaybe<LawnMaintenanceStaticJobQuestionsInput>
  lawnMowing?: InputMaybe<LawnMowningStaticJobQuestionsInput>
  layingParquet?: InputMaybe<LayingParquetStaticJobQuestionsInput>
  parquetMaintenance?: InputMaybe<ParquetMaintenanceStaticJobQuestionsInput>
  renewableEnergyConsulting?: InputMaybe<RenewableEnergyConsultingQuestionsInput>
  solarInstallation?: InputMaybe<SolarInstallationStaticJobQuestionsInput>
  treeCutting?: InputMaybe<TreeCuttingStaticJobQuestionsInput>
}

export type SubmitOfferResult = Offer | SubmitOfferUserInputError

export enum SubmitOfferUserError {
  InvalidCost = 'INVALID_COST',
}

export type SubmitOfferUserInputError = Error & {
  __typename?: 'SubmitOfferUserInputError'
  error: SubmitOfferUserError
  message: Scalars['String']['output']
}

export type Subscription = {
  __typename?: 'Subscription'
  _empty?: Maybe<Scalars['String']['output']>
  onMessageReceivedByRoomId?: Maybe<Message>
  onMessageReceivedByUserId?: Maybe<Message>
}

export type SubscriptionOnMessageReceivedByRoomIdArgs = {
  roomId: Scalars['ID']['input']
}

export type SubscriptionOnMessageReceivedByUserIdArgs = {
  userId: Scalars['ID']['input']
}

export type TreeCuttingStaticJobQuestions = {
  __typename?: 'TreeCuttingStaticJobQuestions'
  from4To6MetersCount: Scalars['Int']['output']
  upTo4MetersCount: Scalars['Int']['output']
}

export type TreeCuttingStaticJobQuestionsInput = {
  from4To6MetersCount: Scalars['Int']['input']
  upTo4MetersCount: Scalars['Int']['input']
}

export type UidRegisteredCompany = {
  __typename?: 'UIDRegisteredCompany'
  address: BaseAddress
  companyName: Scalars['String']['output']
  uid: Scalars['String']['output']
  vatStatus: UidRegisteredVatStatus
}

export enum UidRegisteredVatStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type UnsupportedFileTypeError = {
  __typename?: 'UnsupportedFileTypeError'
  supportedContentTypes: Scalars['String']['output'][]
}

export enum UpdateJobError {
  InvalidBillingPostalCode = 'INVALID_BILLING_POSTAL_CODE',
  InvalidEmail = 'INVALID_EMAIL',
  InvalidPhoneNumber = 'INVALID_PHONE_NUMBER',
}

export enum UpdateProChatSettingsError {
  MembersRequired = 'MEMBERS_REQUIRED',
  OrphanedExecutorsNotAllowed = 'ORPHANED_EXECUTORS_NOT_ALLOWED',
}

export type UpdateProChatSettingsInput = {
  default: UpdateProChatSettingsMembersInput
  orphaned: UpdateProChatSettingsMembersInput
}

export type UpdateProChatSettingsInputError = Error & {
  __typename?: 'UpdateProChatSettingsInputError'
  error: UpdateProChatSettingsError
  message: Scalars['String']['output']
}

export type UpdateProChatSettingsMembers = {
  __typename?: 'UpdateProChatSettingsMembers'
  all: Scalars['Boolean']['output']
  members?: Maybe<ProRole>
}

export type UpdateProChatSettingsMembersInput = {
  all: Scalars['Boolean']['input']
  members?: InputMaybe<ProRole>
}

export type UpdateProChatSettingsResult = ProChatSettings | UpdateProChatSettingsInputError

export type UpdateProLocationGooglePlaceInput = {
  googlePlace?: InputMaybe<AssociatedGooglePlaceInput>
}

export type UpdateProLocationGooglePlaceResult = ProLocation

export type UpdateProMarketingSettingsInput = {
  isDirectoryListingEnabled?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateProMarketingSettingsResult = {
  __typename?: 'UpdateProMarketingSettingsResult'
  isDirectoryListingEnabled: Scalars['Boolean']['output']
}

export enum UpdateProPayoutSettingsError {
  InvalidIban = 'INVALID_IBAN',
}

export type UpdateProPayoutSettingsInput = {
  bankAccountHolder: Scalars['String']['input']
  bankAccountIban: Scalars['String']['input']
}

export type UpdateProPayoutSettingsInputError = Error & {
  __typename?: 'UpdateProPayoutSettingsInputError'
  error: UpdateProPayoutSettingsError
  message: Scalars['String']['output']
}

export type UpdateProPayoutSettingsResult = ProPayoutSettings | UpdateProPayoutSettingsInputError

export enum UpdateProSettingsError {
  NotificationManagementUnavailable = 'NOTIFICATION_MANAGEMENT_UNAVAILABLE',
  UserNotFound = 'USER_NOT_FOUND',
}

export type UpdateProSettingsInputError = Error & {
  __typename?: 'UpdateProSettingsInputError'
  error: UpdateProSettingsError
  message: Scalars['String']['output']
}

export type UpdateProSettingsResult = UpdateProSettingsInputError | User

export type UpdateProStatusAsTopPartnerInput = {
  yearsAsTopPartner: Scalars['Int']['input'][]
}

export type User = {
  __typename?: 'User'
  /** Actions that can be executed on a specific user */
  actions: UserAction[]
  agent?: Maybe<Agent>
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  language: Scalars['String']['output']
  lastName: Scalars['String']['output']
  phoneNumber: Scalars['String']['output']
  pro?: Maybe<ProUser>
  salutation: Salutation
  status: UserStatus
  types: UserType[]
}

export enum UserAction {
  ChangeRole = 'CHANGE_ROLE',
  Delete = 'DELETE',
  Disable = 'DISABLE',
  EditSettings = 'EDIT_SETTINGS',
  Enable = 'ENABLE',
}

export type UserMutations = {
  __typename?: 'UserMutations'
  _empty?: Maybe<Scalars['String']['output']>
  changeProRole: ChangeProRoleResult
  createAgentUser: CreateAgentUserResult
  createProUser: CreateProUserResult
  deleteProUser: DeleteProUserResult
  disable: DisableUserResult
  enable: EnableUserResult
  updateProSettings: UpdateProSettingsResult
}

export type UserMutationsChangeProRoleArgs = {
  newRole: ProRole
  userId: Scalars['ID']['input']
}

export type UserMutationsCreateAgentUserArgs = {
  userInput: CreateAgentUserInput
}

export type UserMutationsCreateProUserArgs = {
  userInput: CreateProUserInput
}

export type UserMutationsDeleteProUserArgs = {
  userId: Scalars['ID']['input']
}

export type UserMutationsDisableArgs = {
  userId: Scalars['ID']['input']
}

export type UserMutationsEnableArgs = {
  userId: Scalars['ID']['input']
}

export type UserMutationsUpdateProSettingsArgs = {
  proId: Scalars['ID']['input']
  settings: ProUserSettingsInput
  userId: Scalars['ID']['input']
}

export enum UserOrder {
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
}

export type UserQuota = {
  __typename?: 'UserQuota'
  /**
   * Maximum number of users
   * Unlimited if undefined
   */
  max?: Maybe<Scalars['Int']['output']>
  /** Current number of used users */
  used: Scalars['Int']['output']
}

export enum UserStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

export type UserType = AdminUserType | AgentUserType | ClientUserType | ProUserType

export enum UserTypeFilter {
  Admin = 'ADMIN',
  Agent = 'AGENT',
  Client = 'CLIENT',
  Pro = 'PRO',
}

export type Voucher = {
  __typename?: 'Voucher'
  id: Scalars['String']['output']
  /**
   * Used to determine the vouchers kind, e.g. bring a friend, agent voucher, etc.
   *
   * Let's us display them differently in the UI, for instance.
   */
  kind: VoucherKind
  terms: VoucherTerm[]
}

export enum VoucherKind {
  AgentVoucher = 'AGENT_VOUCHER',
  BringAFriendInvite = 'BRING_A_FRIEND_INVITE',
  BringAFriendReward = 'BRING_A_FRIEND_REWARD',
  Generic = 'GENERIC',
}

export type VoucherTerm = PriceBasedVoucherTerm | ServiceFeeVoucherTerm

export enum VoucherValidationError {
  Expired = 'EXPIRED',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
}

export type VoucherValidationInputError = Error & {
  __typename?: 'VoucherValidationInputError'
  error: VoucherValidationError
  message: Scalars['String']['output']
}

export type VoucherValidationResult = Voucher | VoucherValidationInputError

export type WaitingForClientAuditData = {
  __typename?: 'WaitingForClientAuditData'
  job: AuditJob
  pro: AuditPro
}

export type UserProfile_AgentByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type UserProfile_AgentByIdQuery = {
  __typename?: 'Query'
  agentById: {__typename?: 'Agent'; agency?: {__typename?: 'Agency'; name: string} | undefined}
}

export type UserProfile_ProByIdQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type UserProfile_ProByIdQuery = {
  __typename?: 'Query'
  proById?: {__typename?: 'Pro'; companyName: string} | undefined
}

export type Messaging_ListRoomsForUserQueryVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type Messaging_ListRoomsForUserQuery = {
  __typename?: 'Query'
  listRooms: {
    __typename?: 'Room'
    id: string
    members: {__typename?: 'RoomMember'; id: string; firstName: string; lastName: string}[]
    lastMessage: {
      __typename?: 'Message'
      id: string
      sentAt: any
      sender: {__typename?: 'RoomMember'; id: string; firstName: string; lastName: string}
      content:
        | {
            __typename: 'FileMessageContent'
            companionText?: string | undefined
            file: {__typename?: 'File'; contentType: string}
          }
        | {__typename: 'SimpleTextMessageContent'; text: string}
    }[]
    relatedInfo?:
      | {
          __typename?: 'RoomInfo'
          job?:
            | {
                __typename?: 'RoomContextJob'
                id: string
                title?: string | undefined
                client: {__typename?: 'RoomContextClient'; firstName: string; lastName: string}
                executionAddress: {__typename?: 'RoomContextExecutionAddress'; locality: string}
              }
            | undefined
          pro?: {__typename?: 'RoomContextPro'; companyName: string} | undefined
        }
      | undefined
  }[]
}

export type Messaging_ListRoomsForUserRelatedToJobQueryVariables = Exact<{
  jobId: Scalars['ID']['input']
}>

export type Messaging_ListRoomsForUserRelatedToJobQuery = {
  __typename?: 'Query'
  listRoomsRelatedToJob: {
    __typename?: 'Room'
    id: string
    members: {__typename?: 'RoomMember'; id: string; firstName: string; lastName: string}[]
    lastMessage: {
      __typename?: 'Message'
      id: string
      sentAt: any
      sender: {__typename?: 'RoomMember'; id: string; firstName: string; lastName: string}
      content:
        | {
            __typename: 'FileMessageContent'
            companionText?: string | undefined
            file: {__typename?: 'File'; contentType: string}
          }
        | {__typename: 'SimpleTextMessageContent'; text: string}
    }[]
    relatedInfo?:
      | {
          __typename?: 'RoomInfo'
          job?:
            | {
                __typename?: 'RoomContextJob'
                id: string
                title?: string | undefined
                client: {__typename?: 'RoomContextClient'; firstName: string; lastName: string}
                executionAddress: {__typename?: 'RoomContextExecutionAddress'; locality: string}
              }
            | undefined
          pro?: {__typename?: 'RoomContextPro'; companyName: string} | undefined
        }
      | undefined
  }[]
}

export type Messaging_LoadRoomQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type Messaging_LoadRoomQuery = {
  __typename?: 'Query'
  roomById: {
    __typename?: 'Room'
    id: string
    members: {__typename?: 'RoomMember'; id: string; firstName: string; lastName: string}[]
    lastMessage: {
      __typename?: 'Message'
      id: string
      sentAt: any
      sender: {__typename?: 'RoomMember'; id: string; firstName: string; lastName: string}
      content:
        | {
            __typename: 'FileMessageContent'
            companionText?: string | undefined
            file: {__typename?: 'File'; contentType: string}
          }
        | {__typename: 'SimpleTextMessageContent'; text: string}
    }[]
    relatedInfo?:
      | {
          __typename?: 'RoomInfo'
          job?:
            | {
                __typename?: 'RoomContextJob'
                id: string
                title?: string | undefined
                client: {__typename?: 'RoomContextClient'; firstName: string; lastName: string}
                executionAddress: {__typename?: 'RoomContextExecutionAddress'; locality: string}
              }
            | undefined
          pro?: {__typename?: 'RoomContextPro'; companyName: string} | undefined
        }
      | undefined
  }
}

export type Messaging_RoomByIdQueryVariables = Exact<{
  roomId: Scalars['ID']['input']
  sortDirection: MessageSortDirection
}>

export type Messaging_RoomByIdQuery = {
  __typename?: 'Query'
  roomById: {
    __typename?: 'Room'
    id: string
    members: {__typename?: 'RoomMember'; id: string; firstName: string; lastName: string}[]
    messages: {
      __typename?: 'Message'
      id: string
      sentAt: any
      roomId: string
      content:
        | {
            __typename: 'FileMessageContent'
            companionText?: string | undefined
            file: {
              __typename?: 'File'
              url: string
              contentType: string
              fileKey: string
              name?: string | undefined
              dimensions?: {__typename?: 'ImageDimensions'; height: number; width: number} | undefined
            }
          }
        | {__typename: 'SimpleTextMessageContent'; text: string}
      sender: {__typename?: 'RoomMember'; id: string; firstName: string; lastName: string}
    }[]
    relatedInfo?:
      | {
          __typename?: 'RoomInfo'
          job?:
            | {
                __typename?: 'RoomContextJob'
                id: string
                title?: string | undefined
                client: {__typename?: 'RoomContextClient'; firstName: string; lastName: string}
                executionAddress: {__typename?: 'RoomContextExecutionAddress'; locality: string}
              }
            | undefined
          pro?: {__typename?: 'RoomContextPro'; companyName: string} | undefined
        }
      | undefined
  }
}

export type Messaging_SendMessageMutationVariables = Exact<{
  message: MessageInput
}>

export type Messaging_SendMessageMutation = {
  __typename?: 'Mutation'
  sendMessage: {
    __typename?: 'Message'
    id: string
    sentAt: any
    roomId: string
    content:
      | {
          __typename: 'FileMessageContent'
          companionText?: string | undefined
          file: {
            __typename?: 'File'
            url: string
            contentType: string
            fileKey: string
            name?: string | undefined
            dimensions?: {__typename?: 'ImageDimensions'; height: number; width: number} | undefined
          }
        }
      | {__typename: 'SimpleTextMessageContent'; text: string}
    sender: {__typename?: 'RoomMember'; id: string; firstName: string; lastName: string}
  }
}

export type Messaging_AddMemberMutationVariables = Exact<{
  roomId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
  capacity: Capacity
}>

export type Messaging_AddMemberMutation = {
  __typename?: 'Mutation'
  addRoomMember: {
    __typename?: 'Room'
    id: string
    members: {__typename?: 'RoomMember'; id: string; firstName: string; lastName: string}[]
    relatedInfo?:
      | {
          __typename?: 'RoomInfo'
          job?:
            | {
                __typename?: 'RoomContextJob'
                id: string
                title?: string | undefined
                client: {__typename?: 'RoomContextClient'; firstName: string; lastName: string}
                executionAddress: {__typename?: 'RoomContextExecutionAddress'; locality: string}
              }
            | undefined
          pro?: {__typename?: 'RoomContextPro'; companyName: string} | undefined
        }
      | undefined
  }
}

export type Messaging_RemoveMemberMutationVariables = Exact<{
  roomId: Scalars['ID']['input']
  userId: Scalars['ID']['input']
}>

export type Messaging_RemoveMemberMutation = {
  __typename?: 'Mutation'
  removeRoomMember:
    | {__typename?: 'RemoveRoomMemberInputError'; message: string; error: RemoveRoomMemberError}
    | {
        __typename?: 'Room'
        id: string
        members: {__typename?: 'RoomMember'; id: string; firstName: string; lastName: string}[]
        relatedInfo?:
          | {
              __typename?: 'RoomInfo'
              job?:
                | {
                    __typename?: 'RoomContextJob'
                    id: string
                    title?: string | undefined
                    client: {__typename?: 'RoomContextClient'; firstName: string; lastName: string}
                    executionAddress: {__typename?: 'RoomContextExecutionAddress'; locality: string}
                  }
                | undefined
              pro?: {__typename?: 'RoomContextPro'; companyName: string} | undefined
            }
          | undefined
      }
}

export type Messaging_GenerateFileUploadCoordinatesMutationVariables = Exact<{
  roomId: Scalars['ID']['input']
  file: FileUploadRequest
}>

export type Messaging_GenerateFileUploadCoordinatesMutation = {
  __typename?: 'Mutation'
  generateChatMessageFileUploadLink:
    | {
        __typename?: 'FileUploadError'
        message: string
        cause:
          | {__typename: 'FileTooBigError'; maxContentLength: number}
          | {__typename: 'UnsupportedFileTypeError'; supportedContentTypes: string[]}
      }
    | {__typename?: 'FileUploadLink'; downloadLink: string; fileKey: string; uploadLink: string}
}

export type Messaging_OnMessageReceivedSubscriptionVariables = Exact<{
  userId: Scalars['ID']['input']
}>

export type Messaging_OnMessageReceivedSubscription = {
  __typename?: 'Subscription'
  onMessageReceivedByUserId?:
    | {
        __typename?: 'Message'
        id: string
        sentAt: any
        roomId: string
        content:
          | {
              __typename: 'FileMessageContent'
              companionText?: string | undefined
              file: {
                __typename?: 'File'
                url: string
                contentType: string
                fileKey: string
                name?: string | undefined
                dimensions?: {__typename?: 'ImageDimensions'; height: number; width: number} | undefined
              }
            }
          | {__typename: 'SimpleTextMessageContent'; text: string}
        sender: {__typename?: 'RoomMember'; id: string; firstName: string; lastName: string}
      }
    | undefined
}

export type Messaging_RoomByIdFragment = {
  __typename?: 'Room'
  id: string
  members: {__typename?: 'RoomMember'; id: string; firstName: string; lastName: string}[]
  messages: {
    __typename?: 'Message'
    id: string
    sentAt: any
    roomId: string
    content:
      | {
          __typename: 'FileMessageContent'
          companionText?: string | undefined
          file: {
            __typename?: 'File'
            url: string
            contentType: string
            fileKey: string
            name?: string | undefined
            dimensions?: {__typename?: 'ImageDimensions'; height: number; width: number} | undefined
          }
        }
      | {__typename: 'SimpleTextMessageContent'; text: string}
    sender: {__typename?: 'RoomMember'; id: string; firstName: string; lastName: string}
  }[]
  relatedInfo?:
    | {
        __typename?: 'RoomInfo'
        job?:
          | {
              __typename?: 'RoomContextJob'
              id: string
              title?: string | undefined
              client: {__typename?: 'RoomContextClient'; firstName: string; lastName: string}
              executionAddress: {__typename?: 'RoomContextExecutionAddress'; locality: string}
            }
          | undefined
        pro?: {__typename?: 'RoomContextPro'; companyName: string} | undefined
      }
    | undefined
}

export type Messaging_RoomDetailsFragment = {
  __typename?: 'Room'
  id: string
  members: {__typename?: 'RoomMember'; id: string; firstName: string; lastName: string}[]
  relatedInfo?:
    | {
        __typename?: 'RoomInfo'
        job?:
          | {
              __typename?: 'RoomContextJob'
              id: string
              title?: string | undefined
              client: {__typename?: 'RoomContextClient'; firstName: string; lastName: string}
              executionAddress: {__typename?: 'RoomContextExecutionAddress'; locality: string}
            }
          | undefined
        pro?: {__typename?: 'RoomContextPro'; companyName: string} | undefined
      }
    | undefined
}

export type Messaging_RoomFragment = {
  __typename?: 'Room'
  id: string
  members: {__typename?: 'RoomMember'; id: string; firstName: string; lastName: string}[]
  lastMessage: {
    __typename?: 'Message'
    id: string
    sentAt: any
    sender: {__typename?: 'RoomMember'; id: string; firstName: string; lastName: string}
    content:
      | {
          __typename: 'FileMessageContent'
          companionText?: string | undefined
          file: {__typename?: 'File'; contentType: string}
        }
      | {__typename: 'SimpleTextMessageContent'; text: string}
  }[]
  relatedInfo?:
    | {
        __typename?: 'RoomInfo'
        job?:
          | {
              __typename?: 'RoomContextJob'
              id: string
              title?: string | undefined
              client: {__typename?: 'RoomContextClient'; firstName: string; lastName: string}
              executionAddress: {__typename?: 'RoomContextExecutionAddress'; locality: string}
            }
          | undefined
        pro?: {__typename?: 'RoomContextPro'; companyName: string} | undefined
      }
    | undefined
}

export type Messaging_RoomLastMessageFragment = {
  __typename?: 'Message'
  id: string
  sentAt: any
  sender: {__typename?: 'RoomMember'; id: string; firstName: string; lastName: string}
  content:
    | {
        __typename: 'FileMessageContent'
        companionText?: string | undefined
        file: {__typename?: 'File'; contentType: string}
      }
    | {__typename: 'SimpleTextMessageContent'; text: string}
}

export type Messaging_MessageFragment = {
  __typename?: 'Message'
  id: string
  sentAt: any
  roomId: string
  content:
    | {
        __typename: 'FileMessageContent'
        companionText?: string | undefined
        file: {
          __typename?: 'File'
          url: string
          contentType: string
          fileKey: string
          name?: string | undefined
          dimensions?: {__typename?: 'ImageDimensions'; height: number; width: number} | undefined
        }
      }
    | {__typename: 'SimpleTextMessageContent'; text: string}
  sender: {__typename?: 'RoomMember'; id: string; firstName: string; lastName: string}
}

export type Messaging_RoomInfoFragment = {
  __typename?: 'RoomInfo'
  job?:
    | {
        __typename?: 'RoomContextJob'
        id: string
        title?: string | undefined
        client: {__typename?: 'RoomContextClient'; firstName: string; lastName: string}
        executionAddress: {__typename?: 'RoomContextExecutionAddress'; locality: string}
      }
    | undefined
  pro?: {__typename?: 'RoomContextPro'; companyName: string} | undefined
}

export type ArticleOverviewPage_SearchArticlesQueryVariables = Exact<{
  page?: InputMaybe<Page>
  searchText?: InputMaybe<Scalars['String']['input']>
  topic?: InputMaybe<Scalars['String']['input']>
  authorName?: InputMaybe<Scalars['String']['input']>
}>

export type ArticleOverviewPage_SearchArticlesQuery = {
  __typename?: 'Query'
  searchArticles: {
    __typename?: 'ArticleSearchResult'
    total: number
    items: {
      __typename?: 'ArticleSearchItem'
      slug: string
      title: string
      readingTime?: number | undefined
      author: {
        __typename?: 'ArticleAuthor'
        name: string
        portrait?: {__typename?: 'ArticleAuthorPortrait'; title?: string | undefined; url: string} | undefined
      }[]
      topic?: {__typename?: 'ArticleSearchItemTopic'; title: string; breadcrumbTitle?: string | undefined} | undefined
      bannerImage?: {__typename?: 'SearchAsset'; title?: string | undefined; file: string} | undefined
    }[]
  }
}

export type ArticleOverviewPage_ArticleFieldsFragment = {
  __typename?: 'ArticleSearchItem'
  slug: string
  title: string
  readingTime?: number | undefined
  author: {
    __typename?: 'ArticleAuthor'
    name: string
    portrait?: {__typename?: 'ArticleAuthorPortrait'; title?: string | undefined; url: string} | undefined
  }[]
  topic?: {__typename?: 'ArticleSearchItemTopic'; title: string; breadcrumbTitle?: string | undefined} | undefined
  bannerImage?: {__typename?: 'SearchAsset'; title?: string | undefined; file: string} | undefined
}

export type ArticlePage_SearchArticlesQueryVariables = Exact<{
  topic?: InputMaybe<Scalars['String']['input']>
  searchText?: InputMaybe<Scalars['String']['input']>
  page?: InputMaybe<Page>
}>

export type ArticlePage_SearchArticlesQuery = {
  __typename?: 'Query'
  searchArticles: {
    __typename?: 'ArticleSearchResult'
    total: number
    items: {
      __typename?: 'ArticleSearchItem'
      slug: string
      title: string
      readingTime?: number | undefined
      author: {
        __typename?: 'ArticleAuthor'
        name: string
        portrait?: {__typename?: 'ArticleAuthorPortrait'; title?: string | undefined; url: string} | undefined
      }[]
      topic?: {__typename?: 'ArticleSearchItemTopic'; title: string; breadcrumbTitle?: string | undefined} | undefined
      bannerImage?: {__typename?: 'SearchAsset'; title?: string | undefined; file: string} | undefined
    }[]
  }
}

export type ArticlePage_ArticleFragment = {
  __typename?: 'ArticleSearchItem'
  slug: string
  title: string
  readingTime?: number | undefined
  author: {
    __typename?: 'ArticleAuthor'
    name: string
    portrait?: {__typename?: 'ArticleAuthorPortrait'; title?: string | undefined; url: string} | undefined
  }[]
  topic?: {__typename?: 'ArticleSearchItemTopic'; title: string; breadcrumbTitle?: string | undefined} | undefined
  bannerImage?: {__typename?: 'SearchAsset'; title?: string | undefined; file: string} | undefined
}

export type TopicOverviewPage_SearchArticlesQueryVariables = Exact<{
  topic?: InputMaybe<Scalars['String']['input']>
  searchText?: InputMaybe<Scalars['String']['input']>
  page?: InputMaybe<Page>
}>

export type TopicOverviewPage_SearchArticlesQuery = {
  __typename?: 'Query'
  searchArticles: {
    __typename?: 'ArticleSearchResult'
    total: number
    items: {
      __typename?: 'ArticleSearchItem'
      slug: string
      title: string
      readingTime?: number | undefined
      author: {
        __typename?: 'ArticleAuthor'
        name: string
        portrait?: {__typename?: 'ArticleAuthorPortrait'; title?: string | undefined; url: string} | undefined
      }[]
      topic?: {__typename?: 'ArticleSearchItemTopic'; title: string; breadcrumbTitle?: string | undefined} | undefined
      bannerImage?: {__typename?: 'SearchAsset'; title?: string | undefined; file: string} | undefined
    }[]
  }
}

export type TopicOverviewPage_ArticleFragment = {
  __typename?: 'ArticleSearchItem'
  slug: string
  title: string
  readingTime?: number | undefined
  author: {
    __typename?: 'ArticleAuthor'
    name: string
    portrait?: {__typename?: 'ArticleAuthorPortrait'; title?: string | undefined; url: string} | undefined
  }[]
  topic?: {__typename?: 'ArticleSearchItemTopic'; title: string; breadcrumbTitle?: string | undefined} | undefined
  bannerImage?: {__typename?: 'SearchAsset'; title?: string | undefined; file: string} | undefined
}

export type CraftsmenRegionDirectoryPage_GetIndustriesQueryVariables = Exact<{
  industryIds: Scalars['ID']['input'][] | Scalars['ID']['input']
  orderBy?: InputMaybe<IndustryOrder>
}>

export type CraftsmenRegionDirectoryPage_GetIndustriesQuery = {
  __typename?: 'Query'
  industriesByIds: {__typename?: 'Industry'; id: string; label: string}[]
}

export type EmergencyLandingPage_GetOfferingQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type EmergencyLandingPage_GetOfferingQuery = {
  __typename?: 'Query'
  offeringById?:
    | {
        __typename?: 'Offering'
        id: string
        label: string
        showClientExpectation: boolean
        icon?: {__typename?: 'Icon'; name: string; collection: string} | undefined
        questions?:
          | {
              __typename?: 'Question'
              id: string
              clientFacingLabel: string
              proFacingLabel: string
              errorLabel?: string | undefined
              rank: number
              required: boolean
              tooltip?: string | undefined
              type: QuestionType
              possibleAnswers?:
                | {
                    __typename?: 'Answer'
                    id: string
                    label?: string | undefined
                    rank: number
                    requireAdditionalInformation: boolean
                  }[]
                | undefined
              stepperConfiguration?:
                | {
                    __typename?: 'QuestionStepperConfiguration'
                    lowerBound: number
                    stepSize: number
                    upperBound: number
                  }
                | undefined
            }[]
          | undefined
      }
    | undefined
}

export type IndustryOverviewPage_CreateLeadMutationVariables = Exact<{
  req: LeadCreationRequest
}>

export type IndustryOverviewPage_CreateLeadMutation = {
  __typename?: 'Mutation'
  createLead: {__typename?: 'LeadCreationResult'; success: boolean}
}

export type GenerateJobUploadLinkMutationVariables = Exact<{
  file: FileUploadRequest
}>

export type GenerateJobUploadLinkMutation = {
  __typename?: 'Mutation'
  generateJobFileUploadLink:
    | {
        __typename?: 'FileUploadError'
        message: string
        cause:
          | {__typename: 'FileTooBigError'; maxContentLength: number}
          | {__typename: 'UnsupportedFileTypeError'; supportedContentTypes: string[]}
      }
    | {__typename?: 'FileUploadLink'; downloadLink: string; fileKey: string; uploadLink: string}
}

export type DeleteJobFileMutationVariables = Exact<{
  request: JobFileDeleteRequest
}>

export type DeleteJobFileMutation = {
  __typename?: 'Mutation'
  deleteJobFile: {__typename?: 'JobFileDeleteResult'; errorMessage?: string | undefined; success: boolean}
}

export type JobRequestDetail_ValidateVoucherQueryVariables = Exact<{
  voucherCode: Scalars['String']['input']
}>

export type JobRequestDetail_ValidateVoucherQuery = {
  __typename?: 'Query'
  validateVoucher:
    | {
        __typename: 'Voucher'
        id: string
        terms: (
          | {__typename: 'PriceBasedVoucherTerm'; discount: number; from: number}
          | {__typename: 'ServiceFeeVoucherTerm'; clientServiceFee: boolean}
        )[]
      }
    | {__typename: 'VoucherValidationInputError'; error: VoucherValidationError; message: string}
}

export type JobRequestDetail_CalculatePriceRangeQueryVariables = Exact<{
  jobQuestions: StaticJobQuestionsInput
}>

export type JobRequestDetail_CalculatePriceRangeQuery = {
  __typename?: 'Query'
  calculatePriceRange: {
    __typename?: 'PriceRange'
    fromAmount: number
    toAmount: number
    containUnknowns: boolean
    freeOfCharge: boolean
    percentageBreakdown: {
      __typename?: 'PriceRangePercentageBreakdown'
      additionalCost?: number | undefined
      buildigoFee: number
      serviceCosts: number
      materialCost?: number | undefined
    }
  }
}

export type PriceRangeFragment = {
  __typename?: 'PriceRange'
  fromAmount: number
  toAmount: number
  containUnknowns: boolean
  freeOfCharge: boolean
  percentageBreakdown: {
    __typename?: 'PriceRangePercentageBreakdown'
    additionalCost?: number | undefined
    buildigoFee: number
    serviceCosts: number
    materialCost?: number | undefined
  }
}

export type RequestJobMutationVariables = Exact<{
  jobRequest: JobRequestInput
}>

export type RequestJobMutation = {
  __typename?: 'Mutation'
  requestJob:
    | {__typename: 'Job'; id: string}
    | {__typename: 'RequestJobUserInputError'; message: string; error: RequestJobError}
}

export type RequestDamageJobMutationVariables = Exact<{
  jobRequest: JobRequestInput
  damage: DamageInput
}>

export type RequestDamageJobMutation = {
  __typename?: 'Mutation'
  requestDamageJob:
    | {__typename: 'Job'; id: string}
    | {__typename: 'RequestJobUserInputError'; message: string; error: RequestJobError}
}

export type RequestJobForAgencyMutationVariables = Exact<{
  jobRequest: JobRequestInput
  agencyJobRequestCode: Scalars['String']['input']
}>

export type RequestJobForAgencyMutation = {
  __typename?: 'Mutation'
  requestJobForAgency:
    | {__typename: 'Job'; id: string}
    | {__typename: 'RequestJobUserInputError'; message: string; error: RequestJobError}
}

export type RequestJobForPreferredProMutationVariables = Exact<{
  jobRequest: JobRequestInput
  proId: Scalars['ID']['input']
}>

export type RequestJobForPreferredProMutation = {
  __typename?: 'Mutation'
  requestJobForPreferredPro:
    | {__typename: 'Job'; id: string}
    | {__typename: 'RequestJobUserInputError'; message: string; error: RequestJobError}
}

type JobRequestResult_Job_Fragment = {__typename: 'Job'; id: string}

type JobRequestResult_RequestJobUserInputError_Fragment = {
  __typename: 'RequestJobUserInputError'
  message: string
  error: RequestJobError
}

export type JobRequestResultFragment =
  | JobRequestResult_Job_Fragment
  | JobRequestResult_RequestJobUserInputError_Fragment

export type JobRequestDetail_GetOfferingQueryVariables = Exact<{
  offeringId: Scalars['ID']['input']
}>

export type JobRequestDetail_GetOfferingQuery = {
  __typename?: 'Query'
  offeringById?:
    | {
        __typename?: 'Offering'
        id: string
        label: string
        type: OfferingType
        fromPrice?: number | undefined
        showClientExpectation: boolean
        executionDates: ExpectedExecution[]
        areIntentionsEnabled: boolean
        executionAddressEnabled: boolean
        icon?: {__typename?: 'Icon'; name: string; collection: string} | undefined
        questions?:
          | {
              __typename?: 'Question'
              id: string
              clientFacingLabel: string
              proFacingLabel: string
              errorLabel?: string | undefined
              rank: number
              required: boolean
              tooltip?: string | undefined
              type: QuestionType
              possibleAnswers?:
                | {
                    __typename?: 'Answer'
                    id: string
                    label?: string | undefined
                    rank: number
                    requireAdditionalInformation: boolean
                  }[]
                | undefined
              stepperConfiguration?:
                | {
                    __typename?: 'QuestionStepperConfiguration'
                    lowerBound: number
                    stepSize: number
                    upperBound: number
                  }
                | undefined
            }[]
          | undefined
        intentions?:
          | {
              __typename?: 'Intention'
              slug: string
              label: string
              icon: {__typename?: 'Icon'; collection: string; name: string}
              questions?:
                | {
                    __typename?: 'Question'
                    id: string
                    clientFacingLabel: string
                    proFacingLabel: string
                    errorLabel?: string | undefined
                    rank: number
                    required: boolean
                    tooltip?: string | undefined
                    type: QuestionType
                    possibleAnswers?:
                      | {
                          __typename?: 'Answer'
                          id: string
                          label?: string | undefined
                          rank: number
                          requireAdditionalInformation: boolean
                        }[]
                      | undefined
                    stepperConfiguration?:
                      | {
                          __typename?: 'QuestionStepperConfiguration'
                          lowerBound: number
                          stepSize: number
                          upperBound: number
                        }
                      | undefined
                  }[]
                | undefined
            }[]
          | undefined
      }
    | undefined
}

export type JobRequestDetail_QuestionsFragment = {
  __typename?: 'Question'
  id: string
  clientFacingLabel: string
  proFacingLabel: string
  errorLabel?: string | undefined
  rank: number
  required: boolean
  tooltip?: string | undefined
  type: QuestionType
  possibleAnswers?:
    | {
        __typename?: 'Answer'
        id: string
        label?: string | undefined
        rank: number
        requireAdditionalInformation: boolean
      }[]
    | undefined
  stepperConfiguration?:
    | {__typename?: 'QuestionStepperConfiguration'; lowerBound: number; stepSize: number; upperBound: number}
    | undefined
}

export type JobRequestDetail_GetAgentQueryVariables = Exact<{
  agentJobRequestCode: Scalars['String']['input']
}>

export type JobRequestDetail_GetAgentQuery = {
  __typename?: 'Query'
  agentByJobRequestCode?:
    | {
        __typename?: 'AgentByJobRequestCode'
        jobRequestCode?: string | undefined
        firstName: string
        lastName: string
        phoneNumber?: string | undefined
        email?: string | undefined
        agency?: {__typename?: 'AgencyByJobRequestCode'; name: string} | undefined
      }
    | undefined
}

export type JobRequestDetail_GetProQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type JobRequestDetail_GetProQuery = {
  __typename?: 'Query'
  proBySlug?: {__typename?: 'PublicPro'; id: string} | undefined
}

export type JobRequestDetail_GetAgencyQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type JobRequestDetail_GetAgencyQuery = {
  __typename?: 'Query'
  agencyBySlug?:
    | {
        __typename?: 'AgencyBySlug'
        jobRequestCode?: string | undefined
        logo?: {__typename?: 'ImageAsset'; title?: string | undefined; url: string} | undefined
      }
    | undefined
}

export type OfferingDetailPage_CreateLeadMutationVariables = Exact<{
  req: LeadCreationRequest
}>

export type OfferingDetailPage_CreateLeadMutation = {
  __typename?: 'Mutation'
  createLead: {__typename?: 'LeadCreationResult'; success: boolean}
}

export type OfferingPage_GetOfferingIntentionsByIdQueryVariables = Exact<{
  offeringId: Scalars['ID']['input']
}>

export type OfferingPage_GetOfferingIntentionsByIdQuery = {
  __typename?: 'Query'
  offeringById?:
    | {
        __typename?: 'Offering'
        id: string
        slug?: string | undefined
        areIntentionsEnabled: boolean
        intentions?: {__typename?: 'Intention'; slug: string; label: string}[] | undefined
      }
    | undefined
}

export type ProDirectoryPage_GetIndustriesQueryVariables = Exact<{
  industryIds: Scalars['ID']['input'][] | Scalars['ID']['input']
  orderBy?: InputMaybe<IndustryOrder>
}>

export type ProDirectoryPage_GetIndustriesQuery = {
  __typename?: 'Query'
  industriesByIds: {
    __typename?: 'Industry'
    id: string
    label: string
    offerings: {__typename?: 'Offering'; id: string; label: string; slug?: string | undefined}[]
  }[]
}

export type ProDirectoryPage_GetTop10OfferingsQueryVariables = Exact<Record<string, never>>

export type ProDirectoryPage_GetTop10OfferingsQuery = {
  __typename?: 'Query'
  searchOfferings: {
    __typename?: 'OfferingSearchItem'
    id: string
    label: string
    slug?: string | undefined
    icon?: {__typename?: 'Icon'; name: string; collection: string} | undefined
  }[]
}

export type GetCompetencesQueryVariables = Exact<Record<string, never>>

export type GetCompetencesQuery = {
  __typename?: 'Query'
  allIndustries: {
    __typename?: 'Industry'
    id: string
    label: string
    rank: number
    skills?:
      | {
          __typename?: 'Skill'
          id: string
          label: string
          rank: number
          services?: {__typename?: 'Service'; id: string; label: string; rank: number}[] | undefined
        }[]
      | undefined
  }[]
}

export type RegisterProMutationVariables = Exact<{
  registrationRequest: ProRegisterRequest
  captchaToken: Scalars['String']['input']
}>

export type RegisterProMutation = {
  __typename?: 'Mutation'
  registerPro:
    | {__typename: 'Pro'; id: string}
    | {__typename: 'ProRegisterRequestCaptchaError'; captchaError: CaptchaError}
    | {__typename: 'ProRegisterRequestUserInputError'; userInputError: ProRegisterError}
}

export type GetAgentForRegisterQueryVariables = Exact<{
  invitationCode: Scalars['String']['input']
}>

export type GetAgentForRegisterQuery = {
  __typename?: 'Query'
  agentByInvitationCode?:
    | {
        __typename?: 'AgentByInvitationCode'
        firstName: string
        lastName: string
        email?: string | undefined
        phoneNumber?: string | undefined
        agency?: {__typename?: 'AgencyByInviteCode'; name: string} | undefined
      }
    | undefined
}

export type GetCompanyDetailsByUidQueryVariables = Exact<{
  uid: Scalars['String']['input']
}>

export type GetCompanyDetailsByUidQuery = {
  __typename?: 'Query'
  getCompanyFromUIDRegistry?:
    | {
        __typename?: 'UIDRegisteredCompany'
        companyName: string
        vatStatus: UidRegisteredVatStatus
        uid: string
        address: {
          __typename?: 'BaseAddress'
          floor?: string | undefined
          locality: string
          postalCode: number
          streetAndHouseNumber: string
        }
      }
    | undefined
}

export type GetCompanyDetailsByNameQueryVariables = Exact<{
  companyName: Scalars['String']['input']
}>

export type GetCompanyDetailsByNameQuery = {
  __typename?: 'Query'
  searchUIDRegistryByCompanyName: {
    __typename?: 'UIDRegisteredCompany'
    companyName: string
    vatStatus: UidRegisteredVatStatus
    uid: string
    address: {
      __typename?: 'BaseAddress'
      floor?: string | undefined
      locality: string
      postalCode: number
      streetAndHouseNumber: string
    }
  }[]
}

export type UidRegisteredCompanyFieldsFragment = {
  __typename?: 'UIDRegisteredCompany'
  companyName: string
  vatStatus: UidRegisteredVatStatus
  uid: string
  address: {
    __typename?: 'BaseAddress'
    floor?: string | undefined
    locality: string
    postalCode: number
    streetAndHouseNumber: string
  }
}

export type GetPublicProPlansQueryVariables = Exact<Record<string, never>>

export type GetPublicProPlansQuery = {
  __typename?: 'Query'
  proPlans: {
    __typename?: 'PublicProPlan'
    id: string
    name: string
    highlighted?: boolean | undefined
    features: {
      __typename?: 'PublicProPlanFeatures'
      maxUsers?: number | undefined
      maxLocations?: number | undefined
      feeCap?: number | undefined
      feeRate: number
      userRolesAvailable: boolean
      notificationManagementAvailable: boolean
      maxMonthlyAcceptedJobs?: number | undefined
    }
    yearlyPrice: {
      __typename?: 'PublicProPlanPrice'
      id: string
      cost: number
      mobiliarCoupon: {__typename?: 'PublicProPriceMobiliarCoupon'; discount: number}
    }
    monthlyPrice?:
      | {
          __typename?: 'PublicProPlanPrice'
          id: string
          cost: number
          mobiliarCoupon: {__typename?: 'PublicProPriceMobiliarCoupon'; discount: number}
        }
      | undefined
  }[]
}

export type ProRegisterPlanPage_PlanFragment = {
  __typename?: 'PublicProPlan'
  id: string
  name: string
  highlighted?: boolean | undefined
  features: {
    __typename?: 'PublicProPlanFeatures'
    maxUsers?: number | undefined
    maxLocations?: number | undefined
    feeCap?: number | undefined
    feeRate: number
    userRolesAvailable: boolean
    notificationManagementAvailable: boolean
    maxMonthlyAcceptedJobs?: number | undefined
  }
  yearlyPrice: {
    __typename?: 'PublicProPlanPrice'
    id: string
    cost: number
    mobiliarCoupon: {__typename?: 'PublicProPriceMobiliarCoupon'; discount: number}
  }
  monthlyPrice?:
    | {
        __typename?: 'PublicProPlanPrice'
        id: string
        cost: number
        mobiliarCoupon: {__typename?: 'PublicProPriceMobiliarCoupon'; discount: number}
      }
    | undefined
}

export type GetProPlanQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetProPlanQuery = {
  __typename?: 'Query'
  proById?:
    | {
        __typename?: 'Pro'
        id: string
        subscription: {__typename?: 'ProSubscription'; id: string; planId?: string | undefined}
      }
    | undefined
}

export type RegisterDialog_GetAgentQueryVariables = Exact<{
  code: Scalars['String']['input']
}>

export type RegisterDialog_GetAgentQuery = {
  __typename?: 'Query'
  agentByInvitationCode?: {__typename?: 'AgentByInvitationCode'; invitationCode: string} | undefined
}

export type GetPublicProProfileQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type GetPublicProProfileQuery = {
  __typename?: 'Query'
  proBySlug?:
    | {
        __typename?: 'PublicPro'
        companyName: string
        spokenLanguages: ProSpokenLanguage[]
        associations: ProAssociation[]
        industries: string[]
        slug: string
        recommendingAgency?: {__typename?: 'ProAgency'; name: string} | undefined
        locations: {
          __typename?: 'PublicProLocation'
          default: boolean
          place: {__typename?: 'Place'; locality: string; postalCode: number}
          googlePlace?:
            | {__typename?: 'AssociatedGooglePlace'; rating?: number | undefined; userRatingCount?: number | undefined}
            | undefined
          representativePerson?:
            | {__typename?: 'ProLocationRepresentative'; firstName: string; lastName: string; role?: string | undefined}
            | undefined
        }[]
        isProfilePublished: {__typename?: 'ProProfilePublishStatus'; de: boolean; en: boolean; fr: boolean; it: boolean}
        profile:
          | {
              __typename?: 'PublicMinimalProProfile'
              isBuildigoVerified: boolean
              isErneuerbarheizenNetwork: boolean
              isMobiliarNetwork: boolean
              featuredOfferings: {
                __typename?: 'Offering'
                id: string
                label: string
                icon?: {__typename?: 'Icon'; collection: string; name: string} | undefined
              }[]
              coverImage?: {__typename?: 'Image'; url: string} | undefined
              logoImage?: {__typename?: 'Image'; url: string} | undefined
            }
          | {
              __typename?: 'PublicPublishedProProfile'
              numEmployees: number
              numApprentices: number
              experienceInYears: number
              aboutCompanyTitle?: string | undefined
              aboutCompanyDescription?: string | undefined
              isBuildigoVerified: boolean
              isErneuerbarheizenNetwork: boolean
              isMobiliarNetwork: boolean
              featuredOfferings: {
                __typename?: 'Offering'
                id: string
                label: string
                icon?: {__typename?: 'Icon'; collection: string; name: string} | undefined
              }[]
              logoImage?: {__typename?: 'Image'; url: string} | undefined
              coverImage?: {__typename?: 'Image'; url: string} | undefined
              galleryImages: {
                __typename?: 'PublicProProfileGalleryImage'
                title?: string | undefined
                description?: string | undefined
                image: {__typename?: 'Image'; url: string}
              }[]
            }
      }
    | undefined
}

export type PublicProWithProfileFieldsFragment = {
  __typename?: 'PublicPro'
  companyName: string
  spokenLanguages: ProSpokenLanguage[]
  associations: ProAssociation[]
  industries: string[]
  slug: string
  recommendingAgency?: {__typename?: 'ProAgency'; name: string} | undefined
  locations: {
    __typename?: 'PublicProLocation'
    default: boolean
    place: {__typename?: 'Place'; locality: string; postalCode: number}
    googlePlace?:
      | {__typename?: 'AssociatedGooglePlace'; rating?: number | undefined; userRatingCount?: number | undefined}
      | undefined
    representativePerson?:
      | {__typename?: 'ProLocationRepresentative'; firstName: string; lastName: string; role?: string | undefined}
      | undefined
  }[]
  isProfilePublished: {__typename?: 'ProProfilePublishStatus'; de: boolean; en: boolean; fr: boolean; it: boolean}
  profile:
    | {
        __typename?: 'PublicMinimalProProfile'
        isBuildigoVerified: boolean
        isErneuerbarheizenNetwork: boolean
        isMobiliarNetwork: boolean
        featuredOfferings: {
          __typename?: 'Offering'
          id: string
          label: string
          icon?: {__typename?: 'Icon'; collection: string; name: string} | undefined
        }[]
        coverImage?: {__typename?: 'Image'; url: string} | undefined
        logoImage?: {__typename?: 'Image'; url: string} | undefined
      }
    | {
        __typename?: 'PublicPublishedProProfile'
        numEmployees: number
        numApprentices: number
        experienceInYears: number
        aboutCompanyTitle?: string | undefined
        aboutCompanyDescription?: string | undefined
        isBuildigoVerified: boolean
        isErneuerbarheizenNetwork: boolean
        isMobiliarNetwork: boolean
        featuredOfferings: {
          __typename?: 'Offering'
          id: string
          label: string
          icon?: {__typename?: 'Icon'; collection: string; name: string} | undefined
        }[]
        logoImage?: {__typename?: 'Image'; url: string} | undefined
        coverImage?: {__typename?: 'Image'; url: string} | undefined
        galleryImages: {
          __typename?: 'PublicProProfileGalleryImage'
          title?: string | undefined
          description?: string | undefined
          image: {__typename?: 'Image'; url: string}
        }[]
      }
}

type PublicProProfileFields_PublicMinimalProProfile_Fragment = {
  __typename?: 'PublicMinimalProProfile'
  isBuildigoVerified: boolean
  isErneuerbarheizenNetwork: boolean
  isMobiliarNetwork: boolean
  featuredOfferings: {
    __typename?: 'Offering'
    id: string
    label: string
    icon?: {__typename?: 'Icon'; collection: string; name: string} | undefined
  }[]
  coverImage?: {__typename?: 'Image'; url: string} | undefined
  logoImage?: {__typename?: 'Image'; url: string} | undefined
}

type PublicProProfileFields_PublicPublishedProProfile_Fragment = {
  __typename?: 'PublicPublishedProProfile'
  numEmployees: number
  numApprentices: number
  experienceInYears: number
  aboutCompanyTitle?: string | undefined
  aboutCompanyDescription?: string | undefined
  isBuildigoVerified: boolean
  isErneuerbarheizenNetwork: boolean
  isMobiliarNetwork: boolean
  featuredOfferings: {
    __typename?: 'Offering'
    id: string
    label: string
    icon?: {__typename?: 'Icon'; collection: string; name: string} | undefined
  }[]
  logoImage?: {__typename?: 'Image'; url: string} | undefined
  coverImage?: {__typename?: 'Image'; url: string} | undefined
  galleryImages: {
    __typename?: 'PublicProProfileGalleryImage'
    title?: string | undefined
    description?: string | undefined
    image: {__typename?: 'Image'; url: string}
  }[]
}

export type PublicProProfileFieldsFragment =
  | PublicProProfileFields_PublicMinimalProProfile_Fragment
  | PublicProProfileFields_PublicPublishedProProfile_Fragment

export type PublicMinimalProProfileFieldsFragment = {
  __typename?: 'PublicMinimalProProfile'
  isBuildigoVerified: boolean
  isErneuerbarheizenNetwork: boolean
  isMobiliarNetwork: boolean
  featuredOfferings: {
    __typename?: 'Offering'
    id: string
    label: string
    icon?: {__typename?: 'Icon'; collection: string; name: string} | undefined
  }[]
  coverImage?: {__typename?: 'Image'; url: string} | undefined
  logoImage?: {__typename?: 'Image'; url: string} | undefined
}

export type PublicPublishedProProfileFieldsFragment = {
  __typename?: 'PublicPublishedProProfile'
  numEmployees: number
  numApprentices: number
  experienceInYears: number
  aboutCompanyTitle?: string | undefined
  aboutCompanyDescription?: string | undefined
  isBuildigoVerified: boolean
  isErneuerbarheizenNetwork: boolean
  isMobiliarNetwork: boolean
  featuredOfferings: {
    __typename?: 'Offering'
    id: string
    label: string
    icon?: {__typename?: 'Icon'; collection: string; name: string} | undefined
  }[]
  logoImage?: {__typename?: 'Image'; url: string} | undefined
  coverImage?: {__typename?: 'Image'; url: string} | undefined
  galleryImages: {
    __typename?: 'PublicProProfileGalleryImage'
    title?: string | undefined
    description?: string | undefined
    image: {__typename?: 'Image'; url: string}
  }[]
}

export type GetPublicProProfilesQueryVariables = Exact<{
  slugs: Scalars['String']['input'][] | Scalars['String']['input']
}>

export type GetPublicProProfilesQuery = {
  __typename?: 'Query'
  prosBySlug: {
    __typename?: 'PublicPro'
    id: string
    slug: string
    companyName: string
    industries: string[]
    profile:
      | {__typename?: 'PublicMinimalProProfile'; isErneuerbarheizenNetwork: boolean}
      | {
          __typename?: 'PublicPublishedProProfile'
          isErneuerbarheizenNetwork: boolean
          logoImage?: {__typename?: 'Image'; url: string; contentType: string} | undefined
          representativeImage?: {__typename?: 'Image'; contentType: string; url: string} | undefined
        }
  }[]
}

export type PublicProFieldsFragment = {
  __typename?: 'PublicPro'
  id: string
  slug: string
  companyName: string
  industries: string[]
  profile:
    | {__typename?: 'PublicMinimalProProfile'; isErneuerbarheizenNetwork: boolean}
    | {
        __typename?: 'PublicPublishedProProfile'
        isErneuerbarheizenNetwork: boolean
        logoImage?: {__typename?: 'Image'; url: string; contentType: string} | undefined
        representativeImage?: {__typename?: 'Image'; contentType: string; url: string} | undefined
      }
}

export type PlatformSearch_SearchAllQueryVariables = Exact<{
  searchText: Scalars['String']['input']
}>

export type PlatformSearch_SearchAllQuery = {
  __typename?: 'Query'
  searchPlatform: (
    | {
        __typename?: 'ArticleSearchItem'
        slug: string
        title: string
        preview?: string | undefined
        topic?: {__typename?: 'ArticleSearchItemTopic'; title: string; breadcrumbTitle?: string | undefined} | undefined
        bannerImage?: {__typename?: 'SearchAsset'; title?: string | undefined; file: string} | undefined
      }
    | {
        __typename?: 'OfferingSearchItem'
        id: string
        label: string
        fromPrice?: number | undefined
        type: OfferingType
        icon?: {__typename?: 'Icon'; collection: string; name: string} | undefined
      }
    | {__typename?: 'ProDirectorySearchItem'; id: string}
  )[]
}

export type PlatformSearch_SearchOfferingsQueryVariables = Exact<{
  searchText: Scalars['String']['input']
}>

export type PlatformSearch_SearchOfferingsQuery = {
  __typename?: 'Query'
  searchOfferings: {
    __typename?: 'OfferingSearchItem'
    id: string
    label: string
    fromPrice?: number | undefined
    type: OfferingType
    icon?: {__typename?: 'Icon'; collection: string; name: string} | undefined
  }[]
}

export type PlatformSearch_CommonOfferingsQueryVariables = Exact<Record<string, never>>

export type PlatformSearch_CommonOfferingsQuery = {
  __typename?: 'Query'
  searchOfferings: {
    __typename?: 'OfferingSearchItem'
    id: string
    label: string
    fromPrice?: number | undefined
    type: OfferingType
    icon?: {__typename?: 'Icon'; collection: string; name: string} | undefined
  }[]
}

export type PlatformSearch_OfferingSearchItemFragment = {
  __typename?: 'OfferingSearchItem'
  id: string
  label: string
  fromPrice?: number | undefined
  type: OfferingType
  icon?: {__typename?: 'Icon'; collection: string; name: string} | undefined
}

export type PlatformSearch_SearchArticlesQueryVariables = Exact<{
  searchText: Scalars['String']['input']
}>

export type PlatformSearch_SearchArticlesQuery = {
  __typename?: 'Query'
  searchArticles: {
    __typename?: 'ArticleSearchResult'
    items: {
      __typename?: 'ArticleSearchItem'
      slug: string
      title: string
      preview?: string | undefined
      topic?: {__typename?: 'ArticleSearchItemTopic'; title: string; breadcrumbTitle?: string | undefined} | undefined
      bannerImage?: {__typename?: 'SearchAsset'; title?: string | undefined; file: string} | undefined
    }[]
  }
}

export type PlatformSearch_AllArticlesQueryVariables = Exact<Record<string, never>>

export type PlatformSearch_AllArticlesQuery = {
  __typename?: 'Query'
  searchArticles: {
    __typename?: 'ArticleSearchResult'
    items: {
      __typename?: 'ArticleSearchItem'
      slug: string
      title: string
      preview?: string | undefined
      topic?: {__typename?: 'ArticleSearchItemTopic'; title: string; breadcrumbTitle?: string | undefined} | undefined
      bannerImage?: {__typename?: 'SearchAsset'; title?: string | undefined; file: string} | undefined
    }[]
  }
}

export type PlatformSearch_ArticleSearchItemFragment = {
  __typename?: 'ArticleSearchItem'
  slug: string
  title: string
  preview?: string | undefined
  topic?: {__typename?: 'ArticleSearchItemTopic'; title: string; breadcrumbTitle?: string | undefined} | undefined
  bannerImage?: {__typename?: 'SearchAsset'; title?: string | undefined; file: string} | undefined
}

export type PlatformSearch_ProDirectorySearchItemFragment = {__typename?: 'ProDirectorySearchItem'; id: string}

export type SignupSection_CreateLeadMutationVariables = Exact<{
  req: LeadCreationRequest
}>

export type SignupSection_CreateLeadMutation = {
  __typename?: 'Mutation'
  createLead: {__typename?: 'LeadCreationResult'; success: boolean}
}

export type ChatRoom_SearchUsersQueryVariables = Exact<{
  proId: Scalars['ID']['input']
}>

export type ChatRoom_SearchUsersQuery = {
  __typename?: 'Query'
  searchProUsers: {
    __typename?: 'ProUserSearchResult'
    data: {__typename?: 'User'; id: string; firstName: string; lastName: string}[]
  }
}

export type LocationAutocomplete_SearchPostalCodeQueryVariables = Exact<{
  locationOrZip: Scalars['String']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type LocationAutocomplete_SearchPostalCodeQuery = {
  __typename?: 'Query'
  searchPostalCode?: {__typename?: 'PostalCode'; postalDistrict: string; zip: number}[] | undefined
}

export type ProDirectory_GetIndustriesQueryVariables = Exact<{
  industryIds: Scalars['ID']['input'][] | Scalars['ID']['input']
}>

export type ProDirectory_GetIndustriesQuery = {
  __typename?: 'Query'
  industriesByIds: {__typename?: 'Industry'; id: string; label: string; rank: number}[]
}

export type ProDirectory_GetOfferingsQueryVariables = Exact<{
  offeringIds: Scalars['ID']['input'][] | Scalars['ID']['input']
}>

export type ProDirectory_GetOfferingsQuery = {
  __typename?: 'Query'
  offeringByIds: {__typename?: 'Offering'; id: string; label: string}[]
}

export type ProDirectory_SearchQueryVariables = Exact<{
  criteria: SearchProDirectoryCriteria
  order?: InputMaybe<SearchProDirectoryOrder>
  page?: InputMaybe<Page>
}>

export type ProDirectory_SearchQuery = {
  __typename?: 'Query'
  searchProDirectory: {
    __typename?: 'ProDirectorySearchResult'
    total: number
    items: {
      __typename?: 'ProDirectorySearchItem'
      id: string
      companyName: string
      coverFileKey?: string | undefined
      description?: string | undefined
      language: string
      logoFileKey?: string | undefined
      slug: string
      associations: ProAssociation[]
      otherAssociations?: string | undefined
      isErneuerbarheizenNetwork: boolean
      isBuildigoVerified: boolean
      isMobiliarNetwork: boolean
      numApprentices: number
      responseTimeInHours?: number | undefined
      additionallySpokenLanguages: ProSpokenLanguage[]
      galleryFileKeys: string[]
      yearsAsTopPartner?: number[] | undefined
      coverImage?: {__typename?: 'Image'; contentType: string; url: string} | undefined
      industries: {__typename?: 'IndustrySearchItem'; id: string; label: string}[]
      isProfilePublished: {__typename?: 'ProProfilePublishStatus'; de: boolean; en: boolean; fr: boolean; it: boolean}
      locations: {
        __typename?: 'SearchLocation'
        locality: string
        postalCode: number
        default: boolean
        geoPoint?: {__typename?: 'SearchLocationGeoPoint'; lat: number; lng: number} | undefined
        googlePlace?:
          | {__typename?: 'SearchGooglePlace'; rating?: number | undefined; userRatingCount?: number | undefined}
          | undefined
      }[]
      logoImage?: {__typename?: 'Image'; url: string; contentType: string} | undefined
      recommendingAgency?: {__typename?: 'ProAgency'; name: string} | undefined
      galleryImages: {__typename?: 'Image'; url: string; contentType: string}[]
    }[]
  }
}

export type ProDirectory_AutocompleteGeoSearchQueryVariables = Exact<{
  searchText: Scalars['String']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type ProDirectory_AutocompleteGeoSearchQuery = {
  __typename?: 'Query'
  searchGeography?:
    | (
        | {__typename: 'PostalCode'; postalDistrict: string; zip: number}
        | {__typename: 'Region'; postalCodes: (number | undefined)[]; regionName: string}
      )[]
    | undefined
}

export type ProDirectory_SearchItemFragment = {
  __typename?: 'ProDirectorySearchItem'
  id: string
  companyName: string
  coverFileKey?: string | undefined
  description?: string | undefined
  language: string
  logoFileKey?: string | undefined
  slug: string
  associations: ProAssociation[]
  otherAssociations?: string | undefined
  isErneuerbarheizenNetwork: boolean
  isBuildigoVerified: boolean
  isMobiliarNetwork: boolean
  numApprentices: number
  responseTimeInHours?: number | undefined
  additionallySpokenLanguages: ProSpokenLanguage[]
  galleryFileKeys: string[]
  yearsAsTopPartner?: number[] | undefined
  coverImage?: {__typename?: 'Image'; contentType: string; url: string} | undefined
  industries: {__typename?: 'IndustrySearchItem'; id: string; label: string}[]
  isProfilePublished: {__typename?: 'ProProfilePublishStatus'; de: boolean; en: boolean; fr: boolean; it: boolean}
  locations: {
    __typename?: 'SearchLocation'
    locality: string
    postalCode: number
    default: boolean
    geoPoint?: {__typename?: 'SearchLocationGeoPoint'; lat: number; lng: number} | undefined
    googlePlace?:
      | {__typename?: 'SearchGooglePlace'; rating?: number | undefined; userRatingCount?: number | undefined}
      | undefined
  }[]
  logoImage?: {__typename?: 'Image'; url: string; contentType: string} | undefined
  recommendingAgency?: {__typename?: 'ProAgency'; name: string} | undefined
  galleryImages: {__typename?: 'Image'; url: string; contentType: string}[]
}

export type PostalCodeFieldsFragment = {__typename: 'PostalCode'; postalDistrict: string; zip: number}

export type RegionFieldsFragment = {__typename: 'Region'; postalCodes: (number | undefined)[]; regionName: string}

export type Common_StaticQuestionsSummaryFieldsFragment = {
  __typename?: 'StaticJobQuestions'
  installLamps?:
    | {
        __typename?: 'InstallLampsStaticJobQuestions'
        electricalConnections: number
        disposalRequired: boolean
        heaviestLampWeight: InstallLamps_LampWeight
        highestRoomCeiling: InstallLamps_RoomCeiling
        numberOfLamps: number
        connectionProximity: {
          __typename?: 'InstallLamps_ConnectionProximity'
          directlyAtConnection?: number | undefined
          moreThanOneMeter?: number | undefined
          upToOneMeter?: number | undefined
        }
        lampTypes: {
          __typename?: 'InstallLamps_TypeOfLamps'
          ceiling?: number | undefined
          chandelier?: number | undefined
          hanging?: number | undefined
          other?: number | undefined
          wall?: number | undefined
          pendant?: number | undefined
        }
        wallMaterial: {
          __typename?: 'InstallLamps_WallMaterial'
          concrete?: number | undefined
          metal?: number | undefined
          plaster?: number | undefined
          unknown?: number | undefined
          wood?: number | undefined
          brick?: number | undefined
        }
      }
    | undefined
  catDoorInstallation?:
    | {
        __typename?: 'CatDoorInstallationStaticJobQuestions'
        assemblyHeight: CatDoorInstallation_AssemblyHeight
        catDoor: CatDoorInstallation_CatDoor
        frame: CatDoorInstallation_Frame
        glassStrength?: CatDoorInstallation_GlassStrength | undefined
        height: number
        surface: CatDoorInstallation_Surface
        target: CatDoorInstallation_Target
        width: number
      }
    | undefined
  renewableEnergyConsulting?:
    | {
        __typename?: 'RenewableEnergyConsultingQuestions'
        boilerSystem: RenewableEnergyConsulting_BoilerSystem
        buildingOccupants: number
        buildingType: RenewableEnergyConsulting_BuildingType
        constructionYear: number
        heatedAreaSquareMeters: number
        heatingEnergyConsumption: number
        heatingSystem: RenewableEnergyConsulting_HeatingSystem
        heatingSystemAge: RenewableEnergyConsulting_HeatingSystemAge
        heatingType: RenewableEnergyConsulting_HeatingType
        numberOfApartments?: RenewableEnergyConsulting_NumberOfApartments | undefined
        recentRenovations: boolean
        recentRenovationsDescription?: string | undefined
      }
    | undefined
  lawnMaintenance?:
    | {__typename?: 'LawnMaintenanceStaticJobQuestions'; sizeOfArea: number; shouldBeMowed: boolean}
    | undefined
  lawnMowing?: {__typename?: 'LawnMowningStaticJobQuestions'; sizeOfArea: number} | undefined
  hedgeTrimming?: {__typename?: 'HedgeTrimmingStaticJobQuestions'; hedgeHeight: number; hedgeLength: number} | undefined
  treeCutting?:
    | {__typename?: 'TreeCuttingStaticJobQuestions'; upTo4MetersCount: number; from4To6MetersCount: number}
    | undefined
  boilerDescaling?: {__typename?: 'BoilerDecalingStaticJobQuestions'; boilerSizeInLiters: number} | undefined
  layingParquet?:
    | {
        __typename?: 'LayingParquetStaticJobQuestions'
        type: LayingParquet_ParquetType
        sizeOfFloor: number
        skirtingBoardLength?: number | undefined
        sizeOfFloorToBeDisposed?: number | undefined
      }
    | undefined
  parquetMaintenance?:
    | {
        __typename?: 'ParquetMaintenanceStaticJobQuestions'
        condition: ParquetMaintenance_ParquetCondition
        sizeOfFloor: number
        age?: number | undefined
        material?: string | undefined
        hasBeenSandedBefore?: boolean | undefined
        areThereObstaclesOnTheFloor?: boolean | undefined
        areSkirtingBoardsGlued?: boolean | undefined
        skirtingBoardLength: number
      }
    | undefined
  solarInstallation?:
    | {
        __typename?: 'SolarInstallationStaticJobQuestions'
        desiredSystem: SolarInstallation_DesiredSystem
        otherDesiredSystem?: string | undefined
        roofSquareMeters: number
        addons: SolarInstallation_Addons[]
        buildingType: SolarInstallation_BuildingType
        otherBuildingType?: string | undefined
        mostImportantFactor: SolarInstallation_MostImportantFactor
      }
    | undefined
}

export type ContactDialog_CreateLeadMutationVariables = Exact<{
  req: LeadCreationRequest
}>

export type ContactDialog_CreateLeadMutation = {
  __typename?: 'Mutation'
  createLead: {__typename?: 'LeadCreationResult'; success: boolean}
}

export const Messaging_MessageFragmentDoc = gql`
  fragment Messaging_Message on Message {
    id
    sentAt
    content {
      __typename
      ... on SimpleTextMessageContent {
        text
      }
      ... on FileMessageContent {
        companionText
        file {
          url
          contentType
          fileKey
          name
          dimensions {
            height
            width
          }
        }
      }
    }
    sender {
      id
      firstName
      lastName
    }
    roomId
  }
`
export const Messaging_RoomInfoFragmentDoc = gql`
  fragment Messaging_RoomInfo on RoomInfo {
    job {
      id
      title
      client {
        firstName
        lastName
      }
      executionAddress {
        locality
      }
    }
    pro {
      companyName
    }
  }
`
export const Messaging_RoomByIdFragmentDoc = gql`
  fragment Messaging_RoomById on Room {
    id
    members {
      id
      firstName
      lastName
    }
    messages(sortDirection: $sortDirection) {
      ...Messaging_Message
    }
    relatedInfo {
      ...Messaging_RoomInfo
    }
  }
  ${Messaging_MessageFragmentDoc}
  ${Messaging_RoomInfoFragmentDoc}
`
export const Messaging_RoomDetailsFragmentDoc = gql`
  fragment Messaging_RoomDetails on Room {
    id
    members {
      id
      firstName
      lastName
    }
    relatedInfo {
      ...Messaging_RoomInfo
    }
  }
  ${Messaging_RoomInfoFragmentDoc}
`
export const Messaging_RoomLastMessageFragmentDoc = gql`
  fragment Messaging_RoomLastMessage on Message {
    id
    sender {
      id
      firstName
      lastName
    }
    content {
      __typename
      ... on SimpleTextMessageContent {
        text
      }
      ... on FileMessageContent {
        companionText
        file {
          contentType
        }
      }
    }
    sentAt
  }
`
export const Messaging_RoomFragmentDoc = gql`
  fragment Messaging_Room on Room {
    id
    members {
      id
      firstName
      lastName
    }
    lastMessage: messages(sortDirection: DESC, limit: 1) {
      ...Messaging_RoomLastMessage
    }
    relatedInfo {
      ...Messaging_RoomInfo
    }
  }
  ${Messaging_RoomLastMessageFragmentDoc}
  ${Messaging_RoomInfoFragmentDoc}
`
export const ArticleOverviewPage_ArticleFieldsFragmentDoc = gql`
  fragment ArticleOverviewPage_ArticleFields on ArticleSearchItem {
    slug
    title
    readingTime
    author {
      name
      portrait {
        title
        url
      }
    }
    topic {
      title
      breadcrumbTitle
    }
    bannerImage {
      title
      file
    }
  }
`
export const ArticlePage_ArticleFragmentDoc = gql`
  fragment ArticlePage_Article on ArticleSearchItem {
    slug
    title
    readingTime
    author {
      name
      portrait {
        title
        url
      }
    }
    topic {
      title
      breadcrumbTitle
    }
    bannerImage {
      title
      file
    }
  }
`
export const TopicOverviewPage_ArticleFragmentDoc = gql`
  fragment TopicOverviewPage_Article on ArticleSearchItem {
    slug
    title
    readingTime
    author {
      name
      portrait {
        title
        url
      }
    }
    topic {
      title
      breadcrumbTitle
    }
    bannerImage {
      title
      file
    }
  }
`
export const PriceRangeFragmentDoc = gql`
  fragment PriceRange on PriceRange {
    fromAmount
    toAmount
    percentageBreakdown {
      additionalCost
      buildigoFee
      serviceCosts
      materialCost
    }
    containUnknowns
    freeOfCharge
  }
`
export const JobRequestResultFragmentDoc = gql`
  fragment JobRequestResult on JobRequestResult {
    __typename
    ... on Job {
      id
    }
    ... on RequestJobUserInputError {
      message
      error
    }
  }
`
export const JobRequestDetail_QuestionsFragmentDoc = gql`
  fragment JobRequestDetail_Questions on Question {
    id
    clientFacingLabel
    proFacingLabel
    errorLabel
    rank
    required
    possibleAnswers(filterBy: {status: ENABLED}, orderBy: {rank: ASC}) {
      id
      label
      rank
      requireAdditionalInformation
    }
    stepperConfiguration {
      lowerBound
      stepSize
      upperBound
    }
    tooltip
    type
  }
`
export const UidRegisteredCompanyFieldsFragmentDoc = gql`
  fragment UIDRegisteredCompanyFields on UIDRegisteredCompany {
    address {
      floor
      locality
      postalCode
      streetAndHouseNumber
    }
    companyName
    vatStatus
    uid
  }
`
export const ProRegisterPlanPage_PlanFragmentDoc = gql`
  fragment ProRegisterPlanPage_Plan on PublicProPlan {
    id
    name
    highlighted
    features {
      maxUsers
      maxLocations
      feeCap
      feeRate
      userRolesAvailable
      notificationManagementAvailable
      maxMonthlyAcceptedJobs
    }
    yearlyPrice {
      id
      cost
      mobiliarCoupon {
        discount
      }
    }
    monthlyPrice {
      id
      cost
      mobiliarCoupon {
        discount
      }
    }
  }
`
export const PublicMinimalProProfileFieldsFragmentDoc = gql`
  fragment PublicMinimalProProfileFields on PublicMinimalProProfile {
    featuredOfferings {
      id
      label
      icon {
        collection
        name
      }
    }
    isBuildigoVerified
    isErneuerbarheizenNetwork
    isMobiliarNetwork
    coverImage {
      url
    }
    logoImage {
      url
    }
  }
`
export const PublicPublishedProProfileFieldsFragmentDoc = gql`
  fragment PublicPublishedProProfileFields on PublicPublishedProProfile {
    numEmployees
    numApprentices
    experienceInYears
    featuredOfferings {
      id
      label
      icon {
        collection
        name
      }
    }
    aboutCompanyTitle
    aboutCompanyDescription
    logoImage {
      url
    }
    coverImage {
      url
    }
    galleryImages {
      image {
        url
      }
      title
      description
    }
    isBuildigoVerified
    isErneuerbarheizenNetwork
    isMobiliarNetwork
  }
`
export const PublicProProfileFieldsFragmentDoc = gql`
  fragment PublicProProfileFields on PublicProProfile {
    ... on PublicMinimalProProfile {
      ...PublicMinimalProProfileFields
    }
    ... on PublicPublishedProProfile {
      ...PublicPublishedProProfileFields
    }
  }
  ${PublicMinimalProProfileFieldsFragmentDoc}
  ${PublicPublishedProProfileFieldsFragmentDoc}
`
export const PublicProWithProfileFieldsFragmentDoc = gql`
  fragment PublicProWithProfileFields on PublicPro {
    companyName
    spokenLanguages
    associations
    industries
    recommendingAgency {
      name
    }
    locations {
      default
      place {
        locality
        postalCode
      }
      googlePlace {
        rating
        userRatingCount
      }
      representativePerson {
        firstName
        lastName
        role
      }
    }
    isProfilePublished {
      de
      en
      fr
      it
    }
    slug
    profile {
      ...PublicProProfileFields
    }
  }
  ${PublicProProfileFieldsFragmentDoc}
`
export const PublicProFieldsFragmentDoc = gql`
  fragment PublicProFields on PublicPro {
    id
    slug
    companyName
    industries
    profile {
      ... on PublicMinimalProProfile {
        isErneuerbarheizenNetwork
      }
      ... on PublicPublishedProProfile {
        isErneuerbarheizenNetwork
        logoImage {
          url
          contentType
        }
        representativeImage {
          contentType
          url
        }
      }
    }
  }
`
export const PlatformSearch_OfferingSearchItemFragmentDoc = gql`
  fragment PlatformSearch_OfferingSearchItem on OfferingSearchItem {
    id
    label
    icon {
      collection
      name
    }
    fromPrice
    type
  }
`
export const PlatformSearch_ArticleSearchItemFragmentDoc = gql`
  fragment PlatformSearch_ArticleSearchItem on ArticleSearchItem {
    slug
    title
    preview
    topic {
      title
      breadcrumbTitle
    }
    bannerImage {
      title
      file
    }
  }
`
export const PlatformSearch_ProDirectorySearchItemFragmentDoc = gql`
  fragment PlatformSearch_ProDirectorySearchItem on ProDirectorySearchItem {
    id
  }
`
export const ProDirectory_SearchItemFragmentDoc = gql`
  fragment ProDirectory_SearchItem on ProDirectorySearchItem {
    id
    companyName
    coverFileKey
    coverImage {
      contentType
      url
    }
    description
    industries {
      id
      label
    }
    isProfilePublished {
      de
      en
      fr
      it
    }
    language
    locations {
      locality
      postalCode
      geoPoint {
        lat
        lng
      }
      googlePlace {
        rating
        userRatingCount
      }
      default
    }
    logoFileKey
    logoImage {
      url
      contentType
    }
    slug
    recommendingAgency {
      name
    }
    associations
    otherAssociations
    isErneuerbarheizenNetwork
    isBuildigoVerified
    isMobiliarNetwork
    numApprentices
    responseTimeInHours
    additionallySpokenLanguages
    galleryFileKeys
    galleryImages {
      url
      contentType
    }
    yearsAsTopPartner
  }
`
export const PostalCodeFieldsFragmentDoc = gql`
  fragment PostalCodeFields on PostalCode {
    __typename
    postalDistrict
    zip
  }
`
export const RegionFieldsFragmentDoc = gql`
  fragment RegionFields on Region {
    __typename
    postalCodes
    regionName
  }
`
export const Common_StaticQuestionsSummaryFieldsFragmentDoc = gql`
  fragment Common_StaticQuestionsSummaryFields on StaticJobQuestions {
    installLamps {
      connectionProximity {
        directlyAtConnection
        moreThanOneMeter
        upToOneMeter
      }
      electricalConnections
      disposalRequired
      heaviestLampWeight
      highestRoomCeiling
      lampTypes {
        ceiling
        chandelier
        hanging
        other
        wall
        pendant
      }
      numberOfLamps
      wallMaterial {
        concrete
        metal
        plaster
        unknown
        wood
        brick
      }
    }
    catDoorInstallation {
      assemblyHeight
      catDoor
      frame
      glassStrength
      height
      surface
      target
      width
    }
    renewableEnergyConsulting {
      boilerSystem
      buildingOccupants
      buildingType
      constructionYear
      heatedAreaSquareMeters
      heatingEnergyConsumption
      heatingSystem
      heatingSystemAge
      heatingType
      numberOfApartments
      recentRenovations
      recentRenovationsDescription
    }
    lawnMaintenance {
      sizeOfArea
      shouldBeMowed
    }
    lawnMowing {
      sizeOfArea
    }
    hedgeTrimming {
      hedgeHeight
      hedgeLength
    }
    treeCutting {
      upTo4MetersCount
      from4To6MetersCount
    }
    boilerDescaling {
      boilerSizeInLiters
    }
    layingParquet {
      type
      sizeOfFloor
      skirtingBoardLength
      sizeOfFloorToBeDisposed
    }
    parquetMaintenance {
      condition
      sizeOfFloor
      age
      material
      hasBeenSandedBefore
      areThereObstaclesOnTheFloor
      areSkirtingBoardsGlued
      skirtingBoardLength
    }
    solarInstallation {
      desiredSystem
      otherDesiredSystem
      roofSquareMeters
      addons
      buildingType
      otherBuildingType
      mostImportantFactor
    }
  }
`
export const UserProfile_AgentByIdDocument = gql`
  query UserProfile_AgentById($id: ID!) {
    agentById(id: $id) {
      agency {
        name
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class UserProfile_AgentByIdGql extends Apollo.Query<
  UserProfile_AgentByIdQuery,
  UserProfile_AgentByIdQueryVariables
> {
  document = UserProfile_AgentByIdDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const UserProfile_ProByIdDocument = gql`
  query UserProfile_ProById($id: ID!) {
    proById(id: $id) {
      companyName
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class UserProfile_ProByIdGql extends Apollo.Query<UserProfile_ProByIdQuery, UserProfile_ProByIdQueryVariables> {
  document = UserProfile_ProByIdDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const Messaging_ListRoomsForUserDocument = gql`
  query Messaging_ListRoomsForUser($userId: ID!) {
    listRooms(userId: $userId) {
      ...Messaging_Room
    }
  }
  ${Messaging_RoomFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class Messaging_ListRoomsForUserGql extends Apollo.Query<
  Messaging_ListRoomsForUserQuery,
  Messaging_ListRoomsForUserQueryVariables
> {
  document = Messaging_ListRoomsForUserDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const Messaging_ListRoomsForUserRelatedToJobDocument = gql`
  query Messaging_ListRoomsForUserRelatedToJob($jobId: ID!) {
    listRoomsRelatedToJob(jobId: $jobId) {
      ...Messaging_Room
    }
  }
  ${Messaging_RoomFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class Messaging_ListRoomsForUserRelatedToJobGql extends Apollo.Query<
  Messaging_ListRoomsForUserRelatedToJobQuery,
  Messaging_ListRoomsForUserRelatedToJobQueryVariables
> {
  document = Messaging_ListRoomsForUserRelatedToJobDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const Messaging_LoadRoomDocument = gql`
  query Messaging_LoadRoom($id: ID!) {
    roomById(roomId: $id) {
      ...Messaging_Room
    }
  }
  ${Messaging_RoomFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class Messaging_LoadRoomGql extends Apollo.Query<Messaging_LoadRoomQuery, Messaging_LoadRoomQueryVariables> {
  document = Messaging_LoadRoomDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const Messaging_RoomByIdDocument = gql`
  query Messaging_RoomById($roomId: ID!, $sortDirection: MessageSortDirection!) {
    roomById(roomId: $roomId) {
      ...Messaging_RoomById
    }
  }
  ${Messaging_RoomByIdFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class Messaging_RoomByIdGql extends Apollo.Query<Messaging_RoomByIdQuery, Messaging_RoomByIdQueryVariables> {
  document = Messaging_RoomByIdDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const Messaging_SendMessageDocument = gql`
  mutation Messaging_SendMessage($message: MessageInput!) {
    sendMessage(input: $message) {
      ...Messaging_Message
    }
  }
  ${Messaging_MessageFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class Messaging_SendMessageGql extends Apollo.Mutation<
  Messaging_SendMessageMutation,
  Messaging_SendMessageMutationVariables
> {
  document = Messaging_SendMessageDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const Messaging_AddMemberDocument = gql`
  mutation Messaging_AddMember($roomId: ID!, $userId: ID!, $capacity: Capacity!) {
    addRoomMember(roomId: $roomId, userId: $userId, capacity: $capacity) {
      ...Messaging_RoomDetails
    }
  }
  ${Messaging_RoomDetailsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class Messaging_AddMemberGql extends Apollo.Mutation<
  Messaging_AddMemberMutation,
  Messaging_AddMemberMutationVariables
> {
  document = Messaging_AddMemberDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const Messaging_RemoveMemberDocument = gql`
  mutation Messaging_RemoveMember($roomId: ID!, $userId: ID!) {
    removeRoomMember(roomId: $roomId, userId: $userId) {
      ...Messaging_RoomDetails
      ... on RemoveRoomMemberInputError {
        message
        error
      }
    }
  }
  ${Messaging_RoomDetailsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class Messaging_RemoveMemberGql extends Apollo.Mutation<
  Messaging_RemoveMemberMutation,
  Messaging_RemoveMemberMutationVariables
> {
  document = Messaging_RemoveMemberDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const Messaging_GenerateFileUploadCoordinatesDocument = gql`
  mutation Messaging_GenerateFileUploadCoordinates($roomId: ID!, $file: FileUploadRequest!) {
    generateChatMessageFileUploadLink(roomId: $roomId, file: $file) {
      ... on FileUploadLink {
        downloadLink
        fileKey
        uploadLink
      }
      ... on FileUploadError {
        message
        cause {
          ... on FileTooBigError {
            __typename
            maxContentLength
          }
          ... on UnsupportedFileTypeError {
            __typename
            supportedContentTypes
          }
        }
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class Messaging_GenerateFileUploadCoordinatesGql extends Apollo.Mutation<
  Messaging_GenerateFileUploadCoordinatesMutation,
  Messaging_GenerateFileUploadCoordinatesMutationVariables
> {
  document = Messaging_GenerateFileUploadCoordinatesDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const Messaging_OnMessageReceivedDocument = gql`
  subscription Messaging_OnMessageReceived($userId: ID!) {
    onMessageReceivedByUserId(userId: $userId) {
      ...Messaging_Message
    }
  }
  ${Messaging_MessageFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class Messaging_OnMessageReceivedGql extends Apollo.Subscription<
  Messaging_OnMessageReceivedSubscription,
  Messaging_OnMessageReceivedSubscriptionVariables
> {
  document = Messaging_OnMessageReceivedDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const ArticleOverviewPage_SearchArticlesDocument = gql`
  query ArticleOverviewPage_SearchArticles($page: Page, $searchText: String, $topic: String, $authorName: String) {
    searchArticles(
      page: $page
      searchCriteria: {searchText: $searchText, topic: $topic, showInOverview: true, authorName: $authorName}
    ) {
      total
      items {
        ...ArticleOverviewPage_ArticleFields
      }
    }
  }
  ${ArticleOverviewPage_ArticleFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class ArticleOverviewPage_SearchArticlesGql extends Apollo.Query<
  ArticleOverviewPage_SearchArticlesQuery,
  ArticleOverviewPage_SearchArticlesQueryVariables
> {
  document = ArticleOverviewPage_SearchArticlesDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const ArticlePage_SearchArticlesDocument = gql`
  query ArticlePage_SearchArticles($topic: String, $searchText: String, $page: Page) {
    searchArticles(searchCriteria: {topic: $topic, searchText: $searchText}, page: $page) {
      total
      items {
        ...ArticlePage_Article
      }
    }
  }
  ${ArticlePage_ArticleFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class ArticlePage_SearchArticlesGql extends Apollo.Query<
  ArticlePage_SearchArticlesQuery,
  ArticlePage_SearchArticlesQueryVariables
> {
  document = ArticlePage_SearchArticlesDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const TopicOverviewPage_SearchArticlesDocument = gql`
  query TopicOverviewPage_SearchArticles($topic: String, $searchText: String, $page: Page) {
    searchArticles(searchCriteria: {topic: $topic, searchText: $searchText}, page: $page) {
      total
      items {
        ...TopicOverviewPage_Article
      }
    }
  }
  ${TopicOverviewPage_ArticleFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class TopicOverviewPage_SearchArticlesGql extends Apollo.Query<
  TopicOverviewPage_SearchArticlesQuery,
  TopicOverviewPage_SearchArticlesQueryVariables
> {
  document = TopicOverviewPage_SearchArticlesDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const CraftsmenRegionDirectoryPage_GetIndustriesDocument = gql`
  query CraftsmenRegionDirectoryPage_GetIndustries($industryIds: [ID!]!, $orderBy: IndustryOrder) {
    industriesByIds(ids: $industryIds, orderBy: $orderBy) {
      id
      label
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class CraftsmenRegionDirectoryPage_GetIndustriesGql extends Apollo.Query<
  CraftsmenRegionDirectoryPage_GetIndustriesQuery,
  CraftsmenRegionDirectoryPage_GetIndustriesQueryVariables
> {
  document = CraftsmenRegionDirectoryPage_GetIndustriesDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const EmergencyLandingPage_GetOfferingDocument = gql`
  query EmergencyLandingPage_GetOffering($id: ID!) {
    offeringById(id: $id) {
      id
      label
      icon {
        name
        collection
      }
      showClientExpectation
      questions(filterBy: {status: ENABLED}, orderBy: {rank: ASC}) {
        id
        clientFacingLabel
        proFacingLabel
        errorLabel
        rank
        required
        possibleAnswers(filterBy: {status: ENABLED}, orderBy: {rank: ASC}) {
          id
          label
          rank
          requireAdditionalInformation
        }
        stepperConfiguration {
          lowerBound
          stepSize
          upperBound
        }
        tooltip
        type
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class EmergencyLandingPage_GetOfferingGql extends Apollo.Query<
  EmergencyLandingPage_GetOfferingQuery,
  EmergencyLandingPage_GetOfferingQueryVariables
> {
  document = EmergencyLandingPage_GetOfferingDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const IndustryOverviewPage_CreateLeadDocument = gql`
  mutation IndustryOverviewPage_CreateLead($req: LeadCreationRequest!) {
    createLead(request: $req) {
      success
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class IndustryOverviewPage_CreateLeadGql extends Apollo.Mutation<
  IndustryOverviewPage_CreateLeadMutation,
  IndustryOverviewPage_CreateLeadMutationVariables
> {
  document = IndustryOverviewPage_CreateLeadDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GenerateJobUploadLinkDocument = gql`
  mutation GenerateJobUploadLink($file: FileUploadRequest!) {
    generateJobFileUploadLink(file: $file) {
      ... on FileUploadLink {
        downloadLink
        fileKey
        uploadLink
      }
      ... on FileUploadError {
        message
        cause {
          ... on FileTooBigError {
            __typename
            maxContentLength
          }
          ... on UnsupportedFileTypeError {
            __typename
            supportedContentTypes
          }
        }
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class GenerateJobUploadLinkGql extends Apollo.Mutation<
  GenerateJobUploadLinkMutation,
  GenerateJobUploadLinkMutationVariables
> {
  document = GenerateJobUploadLinkDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const DeleteJobFileDocument = gql`
  mutation DeleteJobFile($request: JobFileDeleteRequest!) {
    deleteJobFile(request: $request) {
      errorMessage
      success
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class DeleteJobFileGql extends Apollo.Mutation<DeleteJobFileMutation, DeleteJobFileMutationVariables> {
  document = DeleteJobFileDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const JobRequestDetail_ValidateVoucherDocument = gql`
  query JobRequestDetail_ValidateVoucher($voucherCode: String!) {
    validateVoucher(code: $voucherCode) {
      __typename
      ... on Voucher {
        id
        terms {
          __typename
          ... on PriceBasedVoucherTerm {
            discount
            from
          }
          ... on ServiceFeeVoucherTerm {
            clientServiceFee
          }
        }
      }
      ... on VoucherValidationInputError {
        error
        message
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class JobRequestDetail_ValidateVoucherGql extends Apollo.Query<
  JobRequestDetail_ValidateVoucherQuery,
  JobRequestDetail_ValidateVoucherQueryVariables
> {
  document = JobRequestDetail_ValidateVoucherDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const JobRequestDetail_CalculatePriceRangeDocument = gql`
  query JobRequestDetail_CalculatePriceRange($jobQuestions: StaticJobQuestionsInput!) {
    calculatePriceRange(jobQuestions: $jobQuestions) {
      ...PriceRange
    }
  }
  ${PriceRangeFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class JobRequestDetail_CalculatePriceRangeGql extends Apollo.Query<
  JobRequestDetail_CalculatePriceRangeQuery,
  JobRequestDetail_CalculatePriceRangeQueryVariables
> {
  document = JobRequestDetail_CalculatePriceRangeDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const RequestJobDocument = gql`
  mutation RequestJob($jobRequest: JobRequestInput!) {
    requestJob(jobRequest: $jobRequest) {
      ...JobRequestResult
    }
  }
  ${JobRequestResultFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class RequestJobGql extends Apollo.Mutation<RequestJobMutation, RequestJobMutationVariables> {
  document = RequestJobDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const RequestDamageJobDocument = gql`
  mutation RequestDamageJob($jobRequest: JobRequestInput!, $damage: DamageInput!) {
    requestDamageJob(jobRequest: $jobRequest, damage: $damage) {
      ...JobRequestResult
    }
  }
  ${JobRequestResultFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class RequestDamageJobGql extends Apollo.Mutation<RequestDamageJobMutation, RequestDamageJobMutationVariables> {
  document = RequestDamageJobDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const RequestJobForAgencyDocument = gql`
  mutation RequestJobForAgency($jobRequest: JobRequestInput!, $agencyJobRequestCode: String!) {
    requestJobForAgency(jobRequest: $jobRequest, agencyJobRequestCode: $agencyJobRequestCode) {
      ...JobRequestResult
    }
  }
  ${JobRequestResultFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class RequestJobForAgencyGql extends Apollo.Mutation<
  RequestJobForAgencyMutation,
  RequestJobForAgencyMutationVariables
> {
  document = RequestJobForAgencyDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const RequestJobForPreferredProDocument = gql`
  mutation RequestJobForPreferredPro($jobRequest: JobRequestInput!, $proId: ID!) {
    requestJobForPreferredPro(jobRequest: $jobRequest, proId: $proId) {
      ...JobRequestResult
    }
  }
  ${JobRequestResultFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class RequestJobForPreferredProGql extends Apollo.Mutation<
  RequestJobForPreferredProMutation,
  RequestJobForPreferredProMutationVariables
> {
  document = RequestJobForPreferredProDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const JobRequestDetail_GetOfferingDocument = gql`
  query JobRequestDetail_GetOffering($offeringId: ID!) {
    offeringById(id: $offeringId) {
      id
      label
      icon {
        name
        collection
      }
      type
      fromPrice
      showClientExpectation
      executionDates
      questions(filterBy: {status: ENABLED}, orderBy: {rank: ASC}) {
        ...JobRequestDetail_Questions
      }
      areIntentionsEnabled
      intentions {
        slug
        label
        icon {
          collection
          name
        }
        questions(filterBy: {status: ENABLED}, orderBy: {rank: ASC}) {
          ...JobRequestDetail_Questions
        }
      }
      executionAddressEnabled
    }
  }
  ${JobRequestDetail_QuestionsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class JobRequestDetail_GetOfferingGql extends Apollo.Query<
  JobRequestDetail_GetOfferingQuery,
  JobRequestDetail_GetOfferingQueryVariables
> {
  document = JobRequestDetail_GetOfferingDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const JobRequestDetail_GetAgentDocument = gql`
  query JobRequestDetail_GetAgent($agentJobRequestCode: String!) {
    agentByJobRequestCode(code: $agentJobRequestCode) {
      jobRequestCode
      firstName
      lastName
      phoneNumber
      email
      agency {
        name
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class JobRequestDetail_GetAgentGql extends Apollo.Query<
  JobRequestDetail_GetAgentQuery,
  JobRequestDetail_GetAgentQueryVariables
> {
  document = JobRequestDetail_GetAgentDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const JobRequestDetail_GetProDocument = gql`
  query JobRequestDetail_GetPro($slug: String!) {
    proBySlug(slug: $slug) {
      id
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class JobRequestDetail_GetProGql extends Apollo.Query<
  JobRequestDetail_GetProQuery,
  JobRequestDetail_GetProQueryVariables
> {
  document = JobRequestDetail_GetProDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const JobRequestDetail_GetAgencyDocument = gql`
  query JobRequestDetail_GetAgency($slug: String!) {
    agencyBySlug(slug: $slug) {
      jobRequestCode
      logo {
        title
        url
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class JobRequestDetail_GetAgencyGql extends Apollo.Query<
  JobRequestDetail_GetAgencyQuery,
  JobRequestDetail_GetAgencyQueryVariables
> {
  document = JobRequestDetail_GetAgencyDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const OfferingDetailPage_CreateLeadDocument = gql`
  mutation OfferingDetailPage_CreateLead($req: LeadCreationRequest!) {
    createLead(request: $req) {
      success
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class OfferingDetailPage_CreateLeadGql extends Apollo.Mutation<
  OfferingDetailPage_CreateLeadMutation,
  OfferingDetailPage_CreateLeadMutationVariables
> {
  document = OfferingDetailPage_CreateLeadDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const OfferingPage_GetOfferingIntentionsByIdDocument = gql`
  query OfferingPage_GetOfferingIntentionsById($offeringId: ID!) {
    offeringById(id: $offeringId) {
      id
      slug
      areIntentionsEnabled
      intentions {
        slug
        label
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class OfferingPage_GetOfferingIntentionsByIdGql extends Apollo.Query<
  OfferingPage_GetOfferingIntentionsByIdQuery,
  OfferingPage_GetOfferingIntentionsByIdQueryVariables
> {
  document = OfferingPage_GetOfferingIntentionsByIdDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const ProDirectoryPage_GetIndustriesDocument = gql`
  query ProDirectoryPage_GetIndustries($industryIds: [ID!]!, $orderBy: IndustryOrder) {
    industriesByIds(ids: $industryIds, orderBy: $orderBy) {
      id
      label
      offerings {
        id
        label
        slug
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class ProDirectoryPage_GetIndustriesGql extends Apollo.Query<
  ProDirectoryPage_GetIndustriesQuery,
  ProDirectoryPage_GetIndustriesQueryVariables
> {
  document = ProDirectoryPage_GetIndustriesDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const ProDirectoryPage_GetTop10OfferingsDocument = gql`
  query ProDirectoryPage_GetTop10Offerings {
    searchOfferings(limit: 10, searchCriteria: {category: 12}) {
      id
      label
      icon {
        name
        collection
      }
      slug
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class ProDirectoryPage_GetTop10OfferingsGql extends Apollo.Query<
  ProDirectoryPage_GetTop10OfferingsQuery,
  ProDirectoryPage_GetTop10OfferingsQueryVariables
> {
  document = ProDirectoryPage_GetTop10OfferingsDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetCompetencesDocument = gql`
  query GetCompetences {
    allIndustries(status: ENABLED) {
      id
      label
      rank
      skills(status: ENABLED) {
        id
        label
        rank
        services(status: ENABLED) {
          id
          label
          rank
        }
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class GetCompetencesGql extends Apollo.Query<GetCompetencesQuery, GetCompetencesQueryVariables> {
  document = GetCompetencesDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const RegisterProDocument = gql`
  mutation RegisterPro($registrationRequest: ProRegisterRequest!, $captchaToken: String!) {
    registerPro(proRegisterRequest: $registrationRequest, captchaToken: $captchaToken) {
      __typename
      ... on Pro {
        id
      }
      ... on ProRegisterRequestUserInputError {
        __typename
        userInputError: error
      }
      ... on ProRegisterRequestCaptchaError {
        __typename
        captchaError: error
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class RegisterProGql extends Apollo.Mutation<RegisterProMutation, RegisterProMutationVariables> {
  document = RegisterProDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetAgentForRegisterDocument = gql`
  query GetAgentForRegister($invitationCode: String!) {
    agentByInvitationCode(code: $invitationCode) {
      firstName
      lastName
      email
      phoneNumber
      agency {
        name
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class GetAgentForRegisterGql extends Apollo.Query<GetAgentForRegisterQuery, GetAgentForRegisterQueryVariables> {
  document = GetAgentForRegisterDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetCompanyDetailsByUidDocument = gql`
  query GetCompanyDetailsByUID($uid: String!) {
    getCompanyFromUIDRegistry(uid: $uid) {
      ...UIDRegisteredCompanyFields
    }
  }
  ${UidRegisteredCompanyFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetCompanyDetailsByUidGql extends Apollo.Query<
  GetCompanyDetailsByUidQuery,
  GetCompanyDetailsByUidQueryVariables
> {
  document = GetCompanyDetailsByUidDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetCompanyDetailsByNameDocument = gql`
  query GetCompanyDetailsByName($companyName: String!) {
    searchUIDRegistryByCompanyName(companyName: $companyName) {
      ...UIDRegisteredCompanyFields
    }
  }
  ${UidRegisteredCompanyFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetCompanyDetailsByNameGql extends Apollo.Query<
  GetCompanyDetailsByNameQuery,
  GetCompanyDetailsByNameQueryVariables
> {
  document = GetCompanyDetailsByNameDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetPublicProPlansDocument = gql`
  query GetPublicProPlans {
    proPlans {
      ...ProRegisterPlanPage_Plan
    }
  }
  ${ProRegisterPlanPage_PlanFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetPublicProPlansGql extends Apollo.Query<GetPublicProPlansQuery, GetPublicProPlansQueryVariables> {
  document = GetPublicProPlansDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetProPlanDocument = gql`
  query GetProPlan($id: ID!) {
    proById(id: $id) {
      id
      subscription {
        id
        planId
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class GetProPlanGql extends Apollo.Query<GetProPlanQuery, GetProPlanQueryVariables> {
  document = GetProPlanDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const RegisterDialog_GetAgentDocument = gql`
  query RegisterDialog_GetAgent($code: String!) {
    agentByInvitationCode(code: $code) {
      invitationCode
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class RegisterDialog_GetAgentGql extends Apollo.Query<
  RegisterDialog_GetAgentQuery,
  RegisterDialog_GetAgentQueryVariables
> {
  document = RegisterDialog_GetAgentDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetPublicProProfileDocument = gql`
  query GetPublicProProfile($slug: String!) {
    proBySlug(slug: $slug) {
      ...PublicProWithProfileFields
    }
  }
  ${PublicProWithProfileFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetPublicProProfileGql extends Apollo.Query<GetPublicProProfileQuery, GetPublicProProfileQueryVariables> {
  document = GetPublicProProfileDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const GetPublicProProfilesDocument = gql`
  query GetPublicProProfiles($slugs: [String!]!) {
    prosBySlug(slugs: $slugs) {
      ...PublicProFields
    }
  }
  ${PublicProFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class GetPublicProProfilesGql extends Apollo.Query<
  GetPublicProProfilesQuery,
  GetPublicProProfilesQueryVariables
> {
  document = GetPublicProProfilesDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const PlatformSearch_SearchAllDocument = gql`
  query PlatformSearch_SearchAll($searchText: String!) {
    searchPlatform(searchCriteria: {searchText: $searchText}, limit: 30) {
      ... on OfferingSearchItem {
        ...PlatformSearch_OfferingSearchItem
      }
      ... on ArticleSearchItem {
        ...PlatformSearch_ArticleSearchItem
      }
      ... on ProDirectorySearchItem {
        ...PlatformSearch_ProDirectorySearchItem
      }
    }
  }
  ${PlatformSearch_OfferingSearchItemFragmentDoc}
  ${PlatformSearch_ArticleSearchItemFragmentDoc}
  ${PlatformSearch_ProDirectorySearchItemFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class PlatformSearch_SearchAllGql extends Apollo.Query<
  PlatformSearch_SearchAllQuery,
  PlatformSearch_SearchAllQueryVariables
> {
  document = PlatformSearch_SearchAllDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const PlatformSearch_SearchOfferingsDocument = gql`
  query PlatformSearch_SearchOfferings($searchText: String!) {
    searchOfferings(searchCriteria: {searchText: $searchText}, limit: 20) {
      ...PlatformSearch_OfferingSearchItem
    }
  }
  ${PlatformSearch_OfferingSearchItemFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class PlatformSearch_SearchOfferingsGql extends Apollo.Query<
  PlatformSearch_SearchOfferingsQuery,
  PlatformSearch_SearchOfferingsQueryVariables
> {
  document = PlatformSearch_SearchOfferingsDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const PlatformSearch_CommonOfferingsDocument = gql`
  query PlatformSearch_CommonOfferings {
    searchOfferings(searchCriteria: {category: 12}, limit: 30) {
      ...PlatformSearch_OfferingSearchItem
    }
  }
  ${PlatformSearch_OfferingSearchItemFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class PlatformSearch_CommonOfferingsGql extends Apollo.Query<
  PlatformSearch_CommonOfferingsQuery,
  PlatformSearch_CommonOfferingsQueryVariables
> {
  document = PlatformSearch_CommonOfferingsDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const PlatformSearch_SearchArticlesDocument = gql`
  query PlatformSearch_SearchArticles($searchText: String!) {
    searchArticles(searchCriteria: {searchText: $searchText}, page: {limit: 20}) {
      items {
        ...PlatformSearch_ArticleSearchItem
      }
    }
  }
  ${PlatformSearch_ArticleSearchItemFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class PlatformSearch_SearchArticlesGql extends Apollo.Query<
  PlatformSearch_SearchArticlesQuery,
  PlatformSearch_SearchArticlesQueryVariables
> {
  document = PlatformSearch_SearchArticlesDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const PlatformSearch_AllArticlesDocument = gql`
  query PlatformSearch_AllArticles {
    searchArticles(searchCriteria: {}, page: {limit: 20}) {
      items {
        ...PlatformSearch_ArticleSearchItem
      }
    }
  }
  ${PlatformSearch_ArticleSearchItemFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class PlatformSearch_AllArticlesGql extends Apollo.Query<
  PlatformSearch_AllArticlesQuery,
  PlatformSearch_AllArticlesQueryVariables
> {
  document = PlatformSearch_AllArticlesDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const SignupSection_CreateLeadDocument = gql`
  mutation SignupSection_CreateLead($req: LeadCreationRequest!) {
    createLead(request: $req) {
      success
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class SignupSection_CreateLeadGql extends Apollo.Mutation<
  SignupSection_CreateLeadMutation,
  SignupSection_CreateLeadMutationVariables
> {
  document = SignupSection_CreateLeadDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const ChatRoom_SearchUsersDocument = gql`
  query ChatRoom_SearchUsers($proId: ID!) {
    searchProUsers(proId: $proId, order: FIRST_NAME_ASC) {
      data {
        id
        firstName
        lastName
      }
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class ChatRoom_SearchUsersGql extends Apollo.Query<
  ChatRoom_SearchUsersQuery,
  ChatRoom_SearchUsersQueryVariables
> {
  document = ChatRoom_SearchUsersDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const LocationAutocomplete_SearchPostalCodeDocument = gql`
  query LocationAutocomplete_SearchPostalCode($locationOrZip: String!, $limit: Int) {
    searchPostalCode(locationOrZip: $locationOrZip, limit: $limit) {
      postalDistrict
      zip
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class LocationAutocomplete_SearchPostalCodeGql extends Apollo.Query<
  LocationAutocomplete_SearchPostalCodeQuery,
  LocationAutocomplete_SearchPostalCodeQueryVariables
> {
  document = LocationAutocomplete_SearchPostalCodeDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const ProDirectory_GetIndustriesDocument = gql`
  query ProDirectory_GetIndustries($industryIds: [ID!]!) {
    industriesByIds(ids: $industryIds, orderBy: {alphabetical: ASC}) {
      id
      label
      rank
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class ProDirectory_GetIndustriesGql extends Apollo.Query<
  ProDirectory_GetIndustriesQuery,
  ProDirectory_GetIndustriesQueryVariables
> {
  document = ProDirectory_GetIndustriesDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const ProDirectory_GetOfferingsDocument = gql`
  query ProDirectory_GetOfferings($offeringIds: [ID!]!) {
    offeringByIds(ids: $offeringIds) {
      id
      label
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class ProDirectory_GetOfferingsGql extends Apollo.Query<
  ProDirectory_GetOfferingsQuery,
  ProDirectory_GetOfferingsQueryVariables
> {
  document = ProDirectory_GetOfferingsDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const ProDirectory_SearchDocument = gql`
  query ProDirectory_Search($criteria: SearchProDirectoryCriteria!, $order: SearchProDirectoryOrder, $page: Page) {
    searchProDirectory(searchCriteria: $criteria, order: $order, page: $page) {
      items {
        ...ProDirectory_SearchItem
      }
      total
    }
  }
  ${ProDirectory_SearchItemFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class ProDirectory_SearchGql extends Apollo.Query<ProDirectory_SearchQuery, ProDirectory_SearchQueryVariables> {
  document = ProDirectory_SearchDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const ProDirectory_AutocompleteGeoSearchDocument = gql`
  query ProDirectory_AutocompleteGeoSearch($searchText: String!, $limit: Int) {
    searchGeography(searchText: $searchText, limit: $limit) {
      ... on PostalCode {
        ...PostalCodeFields
      }
      ... on Region {
        ...RegionFields
      }
    }
  }
  ${PostalCodeFieldsFragmentDoc}
  ${RegionFieldsFragmentDoc}
`

@Injectable({
  providedIn: 'root',
})
export class ProDirectory_AutocompleteGeoSearchGql extends Apollo.Query<
  ProDirectory_AutocompleteGeoSearchQuery,
  ProDirectory_AutocompleteGeoSearchQueryVariables
> {
  document = ProDirectory_AutocompleteGeoSearchDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
export const ContactDialog_CreateLeadDocument = gql`
  mutation ContactDialog_CreateLead($req: LeadCreationRequest!) {
    createLead(request: $req) {
      success
    }
  }
`

@Injectable({
  providedIn: 'root',
})
export class ContactDialog_CreateLeadGql extends Apollo.Mutation<
  ContactDialog_CreateLeadMutation,
  ContactDialog_CreateLeadMutationVariables
> {
  document = ContactDialog_CreateLeadDocument

  constructor(apollo: Apollo.Apollo) {
    super(apollo)
  }
}
